
import React, { useEffect, useState } from 'react';
import "../../../CustomPopup.scss";
import "./ApproveDocumentsPopup.scss";
import API from '../../../API'
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import { useNavigate, Link } from 'react-router-dom';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";

const AddressAddPopup = ({ setterFunction, loadData, selectedId }) => {

    const navigate = useNavigate()

    const [data, setData] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSecondScreenShown, showSecondScreen] = useState(false)
    const [formFrrors, setFormErrors] = useState({});
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [idSelected, selectId] = useState(null);




    useEffect(() => {
        loadDocData()
    }, [])


    const loadDocData = () => {
        setIsLoading(true)

        API.get(`/restaurants/documents/?restaurant_id=${selectedId}`)
            .then((response) => {
                setData(response.data)
                setIsLoading(false)
            })
            .catch((error) => {

                setIsLoading(false)

            })

    }

    const validateForm = (data) => {
        const errors = {};

        // Validate each field and add errors if necessary
        if (!data.first_name.trim()) {
            errors.first_name = "First name is required.";
        }
        else if (!/^[a-zA-Z\s]+$/.test(data.first_name)) {
            errors.first_name = "can only contain letters.";
        }

        if (!data.last_name.trim()) {
            errors.last_name = "Last name is required.";
        }
        else if (!/^[a-zA-Z\s]+$/.test(data.last_name)) {
            errors.last_name = "can only contain letters.";
        }
        if (!data.posttown.trim()) {
            errors.posttown = "posttown is required.";
        }
        if (!data.postcode.trim()) {
            errors.postcode = "postcode is required.";
        }
        if (!data.from_date || !data.from_date.trim()) {
            errors.from_date = "required.";
        }
        if (!data.to_date || !data.to_date.trim()) {
            errors.to_date = "required.";
        }
        if (data.from_date && data.to_date) {
            const fromDate = new Date(data.from_date);
            const toDate = new Date(data.to_date);

            if (fromDate >= toDate) {
                errors.to_date = "To date must be greater than from date.";
            }
        }

        return errors;
    };

    const approve = (id) => {

        selectId(id);

        setIsLoading(true)
        let data = { is_verified: "True" }
        API.put(`/restaurants/documents/${id}/`, data)

            .then(response => {
                setIsLoading(false)

                loadDocData()
            })
            .catch(error => {
                setMessage(error.response.data.message);
                setIsErrorModalOpen(true)
                setIsLoading(false)

            });


    }

    return (
        <div className='custom-modal document-approve-modal'>
            <div className='card'>

                <div className='first-screen'>
                    <h1>Verify Documents</h1>

                    <div className='body'>
                        <table className='mb-3'>
                            <tr>
                                <th>Doc. Name</th>
                                <th>Number</th>
                                <th>File</th>
                                <th>Approve</th>
                            </tr>
                            {data && data.results.length > 0 && data.results.map((item, index) => {
                                return (
                                    <tr>
                                        <td>
                                            {item.name}
                                        </td>

                                        <td>
                                            {item.documentno}
                                        </td>

                                        <td>
                                            <Link to={item.file}><button className='doc-view-btn'>View</button></Link>


                                        </td>

                                        <td>

                                            {item.is_verified ? <img style={{ height: "20px", marginLeft: "20px" }} src='/images/admin-panel/document-verify-popup/tick.svg'></img> 
                                            :
                                            isLoading &&idSelected==item.id? <img style={{ height: "40px" }} src='/images/loader.gif'></img>
                                            : <button onClick={() => approve(item.id)} className='approve-btn'>Approve</button>}

                                            {}
                                        </td>


                                    </tr>
                                )
                            })}
                        </table>
                    </div>













                    <div className='footer single-button-footer'>
                        <button type='button' className='cancel-button' onClick={() => { loadData();setterFunction(false) }}>Close</button>

                    </div>
                </div>

            </div>
            <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
            {isLoading && <FixedOverlayLoadingSpinner />}
            {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { setterFunction(false); loadData() }} />}

        </div>
    );
};

export default AddressAddPopup;

