// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mail-add-page .card {
  padding: 30px;
}
.mail-add-page .form-select.to_group {
  text-transform: capitalize;
}
.mail-add-page .form-sec label {
  font-size: calc(13px + 2 * (100vw - 320px) / 1600);
  color: rgb(76, 76, 76);
  font-weight: 500;
  margin-bottom: 5px;
}
.mail-add-page .rich-text-container .editor-class .DraftEditor-root {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/MailListAdd/MailListAdd.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAIQ;EACI,0BAAA;AAFZ;AAMQ;EACI,kDAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AAJZ;AASQ;EACI,eAAA;AAPZ","sourcesContent":[".mail-add-page {\r\n    .card{\r\n        padding: 30px;\r\n    }\r\n\r\n    .form-select {\r\n        &.to_group {\r\n            text-transform: capitalize;\r\n        }\r\n    }\r\n    .form-sec{\r\n        label{\r\n            font-size: calc(13px + 2 * (100vw - 320px) / 1600);\r\n            color: rgb(76, 76, 76);\r\n            font-weight: 500;\r\n            margin-bottom: 5px;\r\n        }\r\n    }\r\n\r\n    .rich-text-container .editor-class{\r\n        .DraftEditor-root {\r\n            font-size: 16px;\r\n          }\r\n        \r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
