// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-email .otp-container {
  display: flex;
  justify-content: center;
}
.change-email .otp-box {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.change-email .otp-box .form-control {
  width: 60px;
  height: 40px;
  text-align: center;
}
.change-email .otp-input {
  flex: 1 1;
  margin: 0 15px;
  text-align: center;
  height: 60px;
  width: 60px;
  font-size: 18px;
  border: 1px solid #E7E7E7 !important;
  padding: 5px;
}
.change-email .password-grp {
  position: relative;
}
.change-email .password-eye {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 24px;
  color: rgba(var(--content-color), 1);
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/ChangeEmailAddress/ChangeEmailAddress.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;AAAJ;AAIE;EACE,2BAAA;EACA,eAAA;AAFJ;AAKE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAHJ;AAME;EACE,SAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,oCAAA;EACA,YAAA;AAJJ;AAME;EACE,kBAAA;AAJJ;AAOE;EACE,eAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;EAEA,2BAAA;EACA,eAAA;EAEA,oCAAA;AANJ","sourcesContent":[".change-email {\r\n  .otp-container {\r\n    display: flex;\r\n    justify-content: center;\r\n\r\n  }\r\n\r\n  .otp-box {\r\n    justify-content: flex-start;\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .otp-box .form-control {\r\n    width: 60px;\r\n    height: 40px;\r\n    text-align: center;\r\n  }\r\n\r\n  .otp-input {\r\n    flex: 1;\r\n    margin: 0 15px;\r\n    text-align: center;\r\n    height: 60px;\r\n    width: 60px;\r\n    font-size: 18px;\r\n    border: 1px solid #E7E7E7 !important;\r\n    padding: 5px;\r\n  }\r\n  .password-grp{\r\n    position: relative;\r\n  }\r\n\r\n  .password-eye {\r\n    cursor: pointer;\r\n    position: absolute;\r\n    top: 50%;\r\n    right: 15px;\r\n    -webkit-transform: translateY(-50%);\r\n    transform: translateY(-50%);\r\n    font-size: 24px;\r\n    // padding-right: 10px;\r\n    color: rgba(var(--content-color), 1);\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
