import React from "react";
import "./NoPage.scss"

const NoPage = () => {


  return (

    <>

      <div className="page-not-found">
      <section className="page-head-section">
        <div className="container page-heading">
            <h2 className="h3 mb-3 text-white text-center">404 Not Found</h2>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
                    <li className="breadcrumb-item">
                        <a href="index.html"><i className="ri-home-line"></i>Home</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">404</li>
                </ol>
            </nav>
        </div>
    </section>
  
    <section className="error-section pt-0">
        <div className="container">
            <div className="banner-content text-center">
                <img className="img-fluid banner-img mx-auto" src="/images/404.png" alt="404"></img>
                <p>
                    The page you are looking for could not be found. The link to this
                    address may be outdated or we may have moved the since you last
                    bookmarked it.
                </p>
                <a href="/home" className="btn theme-outline d-inline-flex mx-auto">Back To Home</a>
            </div>
        </div>
    </section>
      </div>
    </>

  )
};

export default NoPage;
