import React, { useEffect } from 'react'
import './PrivacyPolicy.scss'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    // header scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Check if scroll position is at 100vh
            if (scrollPosition >= 5) {
                $(".header").addClass("shrinked");
            } else {
                $(".header").removeClass("shrinked");

            }
        }
        window.addEventListener('scroll', handleScroll);
        // Clean up event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className=' privacy-policy'>

            <div className="body_wrap">
                <div className="page_wrap">

                    <header className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
                        <div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0ee19b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"> <div className="elementor-background-overlay"></div>
                                <div className="elementor-container elementor-column-gap-extended">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d2ebbc2" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                           
                                          
                                            <div className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d67700c" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f56af19" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1970e32" data-element_type="widget" data-widget_type="trx_sc_layouts_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                                                                        <div className="sc_layouts_title_content">
                                                                            <div className="sc_layouts_title_title">
                                                                                <h1 className="sc_layouts_title_caption">Cookie Policy</h1>
                                                                            </div>
                                                                            <div className="sc_layouts_title_breadcrumbs">
                                                                                <div className="breadcrumbs"><a className="breadcrumbs_item home underline_hover" href="">Home</a><span className="breadcrumbs_delimiter"></span><span className="breadcrumbs_item current">Cookie Policy</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div className="page_content_wrap">
                        <div className="content_wrap_fullscreen">
                            <div className="content">
                                <article id="post-211" className="post_item_single post_type_page post-211 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div data-elementor-type="wp-page" data-elementor-id="211" className="elementor elementor-211">

                                        <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Last updated: Oct 18, 2024 </h5>
                                                                    <p>At Reserve Financial Services, we use cookies and similar tracking technologies to enhance your experience on our website and to improve our services. This Cookie Policy explains what cookies are, how we use them, how third parties we may partner with may use cookies on the Service, your choices regarding cookies, and further information about cookies. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>What are Cookies? </h5>
                                                                    <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyse what works and where it needs improvement. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>How We Use Cookies </h5>
                                                                    <p>When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes: </p>
                                                                    <ul>
  <li><strong>Essential Cookies:</strong> We use cookies to remember information that changes the way the Service behaves or looks, such as a user's language preference on the Service.</li>
  <li><strong>Account-Related Cookies:</strong> We use cookies to manage the signup process and general administration. These cookies will usually be deleted when you log out; however, in some cases, they may remain afterward to remember your site preferences when logged out.</li>
  <li><strong>Analytics Cookies:</strong> We use cookies to help us analyse how our visitors use the website, allowing us to improve our services.</li>
</ul>

  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Third-Party Cookies </h5>
                                                                    <p>In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the Service and deliver advertisements on and through the Service. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Your Choices Regarding Cookies </h5>
                                                                    <p>If you prefer to avoid the use of cookies on the website, you must first disable the use of cookies in your browser and then delete the cookies saved in your browser associated with this website. You may use this option for preventing the use of cookies at any time. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>More Information About Cookies </h5>
                                                                    <p>You can learn more about cookies and the following third-party websites: </p>
                                                                    <ul>
  <li><a href="https://www.aboutcookies.org.uk/">All AboutCookies</a></li>
  <li><a href='https://thenai.org/'>Network Advertising Initiative</a></li>
</ul>
<p>For more information about how we use personal information, please refer to our Privacy Policy. </p>
</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Managing Cookies </h5>
                                                                    <p>If you wish to remove or prevent cookies from being stored on your device in the future, please follow the instructions provided by your web browser. You can also opt out of Google Analytics tracking across all websites by visiting: <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>. For further guidance on how to delete cookies, please visit: <a href='https://www.aboutcookies.org/how-to-delete-cookies/'>https://www.aboutcookies.org/how-to-delete-cookies/</a>. 

You can manage cookie preferences by adjusting the settings on your browser to refuse the placement of certain cookies. However, please note that if you choose to block all cookies, including essential cookies, you may not be able to access certain features or sections of our website. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Changes to This Cookie Policy </h5>
                                                                    <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page. You are advised to review this Cookie Policy periodically for any changes. Changes to this Cookie Policy are effective when they are posted on this page. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                          

                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default PrivacyPolicy