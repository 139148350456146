import React, { useEffect } from 'react'
import './PrivacyPolicy.scss'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    // header scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Check if scroll position is at 100vh
            if (scrollPosition >= 5) {
                $(".header").addClass("shrinked");
            } else {
                $(".header").removeClass("shrinked");

            }
        }
        window.addEventListener('scroll', handleScroll);
        // Clean up event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className=' privacy-policy'>

            <div className="body_wrap">
                <div className="page_wrap">

                    <header className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
                        <div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0ee19b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"> <div className="elementor-background-overlay"></div>
                                <div className="elementor-container elementor-column-gap-extended">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d2ebbc2" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                        
                                            <div className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d67700c" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f56af19" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1970e32" data-element_type="widget" data-widget_type="trx_sc_layouts_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                                                                        <div className="sc_layouts_title_content">
                                                                            <div className="sc_layouts_title_title">
                                                                                <h1 className="sc_layouts_title_caption">Responsible Investment Policy</h1>
                                                                            </div>
                                                                            <div className="sc_layouts_title_breadcrumbs">
                                                                                <div className="breadcrumbs"><a className="breadcrumbs_item home underline_hover" href="https://zurich.axiomthemes.com/">Home</a><span className="breadcrumbs_delimiter"></span><span className="breadcrumbs_item current">Responsible Investment Policy</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div className="page_content_wrap">
                        <div className="content_wrap_fullscreen">
                            <div className="content">
                                <article id="post-211" className="post_item_single post_type_page post-211 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div data-elementor-type="wp-page" data-elementor-id="211" className="elementor elementor-211">

                                        <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <p>At Reserve Financial Services, we are committed to responsible investment practices that align with our values and those of our clients. We recognise the importance of integrating Environmental, Social, and Governance (ESG) factors into our investment and portfolio management strategies. This policy outlines our approach to responsible investing and reflects our dedication to making informed decisions that support sustainable growth, ethical business practices, and long-term value creation. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Our Commitment to ESG Integration </h5>
                                                                    <p>We believe that responsible investing goes beyond financial returns. By incorporating ESG factors into our decision-making processes, we aim to promote ethical business practices while mitigating potential risks. Our investment strategies are designed to achieve sustainable growth by considering the long-term impact of environmental and social factors on the performance of our portfolios. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Environmental Considerations </h5>
                                                                    <p>We evaluate the environmental impact of the companies and assets we invest in. This includes assessing carbon emissions, resource management, and sustainable practices. Our goal is to support investments that contribute positively to environmental sustainability and the transition to a low-carbon economy. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Social Responsibility </h5>
                                                                    <p>We invest in businesses that demonstrate a commitment to social responsibility, including labour rights, community engagement, diversity, and equality. We seek to partner with companies that prioritise the well-being of their employees, customers, and the broader community. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Governance Standards </h5>
                                                                    <p>Strong corporate governance is a critical factor in our investment decisions. We prioritise transparency, accountability, and ethical management practices. We invest in companies with robust governance structures that ensure sound decision-making and uphold the highest ethical standards. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Active Stewardship and Engagement </h5>
                                                                    <p>As part of our commitment to responsible investment, we actively engage with the companies in which we invest. Through dialogue and voting rights, we encourage businesses to adopt sustainable practices, improve governance, and enhance their ESG performance. We believe this engagement can lead to long-term improvements in the companies’ operations and value. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Client-Centered Approach </h5>
                                                                    <p>We recognise that each client has unique values and objectives. Our responsible investment strategies are designed to reflect the personal values of our clients while delivering strong financial performance. We work closely with our clients to understand their ESG preferences and tailor portfolios that align with their goals. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Continuous Improvement </h5>
                                                                    <p>We are committed to continuously reviewing and improving our responsible investment practices. We stay informed of the latest ESG trends and best practices, ensuring that our approach evolves with the changing global landscape. Our investment team receives ongoing training to enhance their understanding of responsible investing and its impact on portfolio management. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Reporting and Transparency </h5>
                                                                    <p>Transparency is central to our responsible investment policy. We provide regular updates on the ESG performance of our portfolios and the progress of our stewardship activities. Our clients receive detailed reports that highlight how we integrate ESG factors into our investment strategies and the outcomes of our engagements with companies. 

 

If you’ve got any questions, we’d love to hear from you. Contact our team to start the conversation. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                           
                                          

                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default PrivacyPolicy