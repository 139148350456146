// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restaurant-list-page .approved-label {
  display: block;
  height: 30px;
  width: 100px;
  color: rgb(43, 181, 16);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid rgb(43, 181, 16);
  display: flex;
  align-items: center;
  justify-content: center;
}
.restaurant-list-page .approve-button {
  display: block;
  height: 30px;
  width: 80px;
  background-color: rgb(216, 154, 37);
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  border-radius: 8px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/RestaurantList/RestaurantList.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kCAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAR;AAEI;EACI,cAAA;EACgB,YAAA;EACA,WAAA;EACA,mCAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;AAAxB","sourcesContent":[".restaurant-list-page{\r\n    .approved-label{\r\n        display: block;\r\n        height: 30px;\r\n        width: 100px;\r\n        color: rgb(43, 181, 16);\r\n        font-weight: 600;\r\n        font-size: 14px;\r\n        line-height: 14px;\r\n        border-radius: 8px;\r\n        text-align:center;\r\n        border: 1px solid rgb(43, 181, 16);\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n    }\r\n    .approve-button{\r\n        display: block;\r\n                        height: 30px;\r\n                        width: 80px;\r\n                        background-color: rgb(216, 154, 37);\r\n                        color: #fff;\r\n                        font-weight: 600;\r\n                        font-size: 12px;\r\n                        border-radius: 8px;\r\n                        text-align:center;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
