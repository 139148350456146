import React, { Component } from 'react'
import { useState, useEffect, useContext } from "react";
import { Routes, Route } from "react-router-dom";
import './Layout.scss';
import LeftNavbar from './LeftNavbar'
import Header from './Header';

import { UserContext } from '../../authentication/pages/UserContext';
import { useNavigate } from 'react-router-dom';

import API from '../../API';
import { Navigate } from 'react-router-dom';

import Profile from '../pages/Profile/Profile'
import InvoiceList from '../pages/InvoiceList/InvoiceList';
import Overview from '../pages/Overview/Overview';

import RestaurantInfo from '../pages/RestaurantInfo/RestaurantInfo';

import '../common-components/css/vendors/font-awesome.css'
import '../common-components/css/vendors/themify.css'
import '../common-components/css/ratio.css'
import '../common-components/css/remixicon.css'
import '../common-components/css/datatables.css'

import '../common-components/css/vendors/scrollbar.css'
import '../common-components/css/vendors/animate.css'
// import '../common-components/css/vendors/bootstrap.css'
import '../common-components/css/vector-map.css'
import '../common-components/css/vendors/dropzone.css'
// import '../common-components/css/vendors/slick.css'

import MenuCategoryList from '../pages/MenuCategoryList/MenuCategoryList';
import MenuCategoryCreate from '../pages/MenuCategoryCreate/MenuCategoryCreate';
import MenuItemList from '../pages/MenuItemList/MenuItemList';
import MenuItemAdd from '../pages/MenuItemAdd/MenuItemAdd';
import RestaurantList from '../pages/RestaurantList/RestaurantList';
import DriverCreate from '../pages/DriverCreate/DriverCreate';
import DriverList from '../pages/DriverList/DriverList';
import CuisineList from '../pages/CuisineList/CuisineList';
import CurrencyList from '../pages/CurrencyList/CurrencyList';
import MenuUnitList from '../pages/MenuUnitList/MenuUnitList';
import DriverAdd from '../pages/DriverAdd/DriverAdd';
import OrdersList from '../pages/OrdersList/OrdersList';
import OrdersDetails from '../pages/OrdersDetails/OrdersDetails';
import OrdersTracking from '../pages/OrdersTracking/OrdersTracking';
import OrderReviewList from '../pages/OrderReviewList/OrderReviewList';
import OrderItemReviewList from '../pages/OrderItemReviewList/OrderItemReviewList';
import MenuItemReview from '../pages/MenuItemReview/MenuItemReview';
import InvoiceDetails from '../pages/InvoiceDetails/InvoiceDetails';
import UsersList from '../pages/UsersList/UsersList';
import UsersAdd from '../pages/UsersAdd/UsersAdd';
import TicketsList from '../pages/TicketsList/TicketsList';
import TicketsAdd from '../pages/TicketsAdd/TicketsAdd';
import TicketsDetails from '../pages/TicketsDetails/TicketsDetails';
import UsersDetails from '../pages/UsersDetails/UsersDetails';
import Reports from '../pages/Reports/Reports';
import UsersPermission from '../pages/UsersPermission/UsersPermission';
import NoPage from '../pages/NoPage';
import UserDeletionList from '../pages/UserDeletionList/UserDeletionList';
import DriverSettings from '../pages/DriverSettings/DriverSettings';
import AdminSettings from '../pages/AdminSettings/AdminSettings';
import MailList from '../pages/MailList/MailList';
import MailListAdd from '../pages/MailListAdd/MailListAdd';
import MenuCombinationList from '../pages/MenuCombinationList/MenuCombinationList';
import MenuCombinationAdd from '../pages/MenuCombinationAdd/MenuCombinationAdd';
import CouponAdd from '../pages/CouponAdd/CouponAdd';
import CouponList from '../pages/CouponList/CouponList';
import BlogList from '../pages/BlogList/BlogList';
import BlogAdd from '../pages/BlogAdd/BlogAdd';
import Dashboard from '../pages/Dashboard/Dashboard';


const Layout = () => {


  let navigate = useNavigate();


  const [userData, setUserData] = useState(null);
  const [notificationData, setNotificationData] = useState(null);

  const [isNotificationSettingsShow, setNotificationSettingsShow] = useState(false)
  const [isSecurityModalShow, setSecurityModalShow] = useState(false)
  const [isLogoutModalShow, setLogoutModalShow] = useState(false)


  const submitNotification = () => {
    setNotificationSettingsShow(false)
  }




  const [navOpen, setnavOpen] = useState(false);

  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const { isLoggedIn, login, logout } = useContext(UserContext);

  const toggleLeftNav = () => {
    setnavOpen(!navOpen)
  }


  const loadUserData = () => {
    API.get(`/user/users/${window.localStorage.getItem('userID')}/`)
      .then(response => {

        setUserData(response.data)
        // console.log("userdata from adminlayout", userData)
      })

      .catch(error => {
        console.error(error);
      });
  }

  const loadNotificationData = () => {
    API.get(`/communication/notifications/`)
      .then(response => {
        setNotificationData(response.data)
      }
      )
      .catch(error => {
        console.error(error);
      })
  }


  useEffect(() => {
    // if (['admin', 'restaurant', 'staff', 'driver'].includes(window.localStorage.getItem("userRole"))) 
    loadUserData();

    loadNotificationData()
  }, [])



  const myFunction = () => {
    document.getElementsByClassName("navbar-left")[0].classList.toggle("open");

  }




  return (
    <div className='admin-layout compact-wrapper'>


      <Header userData={userData} isLoggedIn={isLoggedIn} notificationData={notificationData}></Header>

      <div className='page-body-wrapper'>
        <LeftNavbar></LeftNavbar>

        {isLoggedIn && (['admin', 'restaurant', 'staff', 'driver'].includes(window.localStorage.getItem("userRole"))) ?

          <div className='app-content'>
            <Routes>
              {window.localStorage.getItem("userRole") === 'restaurant' ?
                <Route index element={<Dashboard role={'restaurant'} />} /> :
                <Route index element={<Dashboard role={'admin'} />} />
              }

              <Route path="cuisine/list" element={<CuisineList />} />
              <Route path="currency/list" element={<CurrencyList />} />

              <Route path="restaurant/list" element={<RestaurantList />} />
              <Route path="restaurant/edit" element={<RestaurantInfo mode={"edit"} />} />
              <Route path="restaurant/add" element={<RestaurantInfo mode={"add"} />} />
              <Route path="menu-category/list" element={<MenuCategoryList />} />
              <Route path="menu-category/add" element={<MenuCategoryCreate />} />

              <Route path="menu-item/list" element={<MenuItemList />} />
              <Route path="menu-item/add" element={<MenuItemAdd mode={"add"} />} />
              <Route path="menu-item/edit" element={<MenuItemAdd mode={"edit"} />} />

              <Route path="menu-combination/list" element={<MenuCombinationList />} />
              <Route path="menu-combination/add" element={<MenuCombinationAdd mode={'add'} />} />
              <Route path="menu-combination/edit" element={<MenuCombinationAdd mode={'edit'} />} />

              <Route path="unit/list" element={<MenuUnitList />} />
              <Route path="driver/list" element={<DriverList />} />
              <Route path="driver/add" element={<DriverAdd mode={'add'} />} />
              <Route path="driver/edit" element={<DriverAdd mode={'edit'} />} />

              <Route path="orders/list" element={<OrdersList />} />
              <Route path="orders/details" element={<OrdersDetails />} />
              <Route path="orders/tracking" element={<OrdersTracking />} />

              <Route path="order-review/list" element={<OrderReviewList />} />
              <Route path="order-item-review/list" element={<OrderItemReviewList />} />
              <Route path="menu-item-review" element={<MenuItemReview />} />

              <Route path="invoice/list" element={<InvoiceList />} />
              <Route path="invoice/details" element={<InvoiceDetails />} />

              <Route path="users/list" element={<UsersList />} />
              <Route path="user-deletion/list" element={<UserDeletionList />} />
              <Route path="users/add" element={<UsersAdd />} />
              <Route path="users/details" element={<UsersDetails />} />

              <Route path="tickets/list" element={<TicketsList />} />
              <Route path="tickets/add" element={<TicketsAdd />} />
              <Route path="tickets/details" element={<TicketsDetails />} />

              <Route path="mail/list" element={<MailList />} />
              <Route path="mail/add" element={<MailListAdd mode={"add"} />} />
              <Route path="mail/edit" element={<MailListAdd mode={"edit"} />} />

              <Route path="blog/list" element={<BlogList />} />
              <Route path="blog/add" element={<BlogAdd mode={"add"} />} />
              <Route path="blog/edit" element={<BlogAdd mode={"edit"} />} />

              <Route path="coupon/list" element={<CouponList />} />
              <Route path="coupon/add" element={<CouponAdd mode={"add"} />} />
              <Route path="coupon/edit" element={<CouponAdd mode={"edit"} />} />

              <Route path="reports" element={<Reports />} />
              <Route path="profile" element={<Profile />} />
              <Route path="restaurant-settings" element={<RestaurantInfo />} />
              <Route path="admin-settings" element={<AdminSettings />} />
              <Route path="driver-settings" element={<DriverSettings />} />

              <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
          :
          <div className="auth-mask">
            <p>Please login as Admin to continue</p>
          </div>
        }

      </div>
    </div>


  )

}

export default Layout