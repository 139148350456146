// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-summary .chart {
  height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/SalesSummaryChart/SalesSummaryChart.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR","sourcesContent":[".sales-summary {\r\n    .chart {\r\n        height: 300px;\r\n\r\n        g.recharts-layer.recharts-bar-rectangle {\r\n            path {\r\n                // fill: rgb(var(--theme-color));\r\n            }\r\n        }\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
