// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-details-page .driver-details-item.pickup h5 {
  font-size: calc(9px + 4 * (100vw - 320px) / 1600) !important;
}
.order-details-page .driver-details-box .driver-details-item .driver-image.pickup img {
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/OrdersDetails/OrdersDetails.scss"],"names":[],"mappings":"AAEQ;EACI,4DAAA;AADZ;AAII;EACI,mBAAA;AAFR","sourcesContent":[".order-details-page {\r\n    .driver-details-item.pickup{\r\n        h5{\r\n            font-size: calc(9px + 4 * (100vw - 320px) / 1600) !important;\r\n        }\r\n    }\r\n    .driver-details-box .driver-details-item .driver-image.pickup img{\r\n        border-radius: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
