// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile .form-section {
  width: 70%;
}
.user-profile .image-preview-box {
  margin-bottom: 20px;
}
.user-profile .image-section {
  width: 30%;
}
.user-profile .img-section .img-error-msg {
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
}
.user-profile .img-section .delete-btn {
  background-color: rgba(48, 48, 48, 0.641) !important;
  position: absolute;
  z-index: 3;
  bottom: 0;
  color: #fff;
  border-radius: 0;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}
.user-profile .img-section .delete-btn:hover {
  color: #eee;
}
.user-profile .forgot-text {
  font-size: calc(8px + 4 * (100vw - 320px) / 1600);
  color: #cd811e;
  text-align: center;
}
.user-profile .forgot-text:hover {
  color: #9d5400;
}

@media (max-width: 767px) {
  .user-profile .form-section .w-50 {
    width: 100% !important;
  }
  .user-profile .form-section .button-box {
    justify-content: space-between !important;
  }
}
@media (max-width: 1005px) {
  .user-profile .form-section {
    width: 100%;
  }
  .user-profile .image-section {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/Profile/Profile.scss"],"names":[],"mappings":"AACE;EACE,UAAA;AAAJ;AAGE;EACE,mBAAA;AADJ;AAIE;EACE,UAAA;AAFJ;AAKE;EACE,UAAA;EACA,eAAA;EACA,mBAAA;AAHJ;AAME;EACE,oDAAA;EACA,kBAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AAJJ;AAOE;EACE,WAAA;AALJ;AAOE;EACE,iDAAA;EACA,cAAA;EACA,kBAAA;AALJ;AAMI;EACE,cAAA;AAJN;;AASA;EAEI;IACE,sBAAA;EAPJ;EAUE;IACE,yCAAA;EARJ;AACF;AAaA;EAEI;IACE,WAAA;EAZJ;EAeE;IACE,WAAA;EAbJ;AACF","sourcesContent":[".user-profile {\r\n  .form-section {\r\n    width: 70%;\r\n  }\r\n\r\n  .image-preview-box {\r\n    margin-bottom: 20px;\r\n  }\r\n\r\n  .image-section {\r\n    width: 30%;\r\n  }\r\n\r\n  .img-section .img-error-msg {\r\n    color: red;\r\n    font-size: 10px;\r\n    margin-bottom: 10px;\r\n  }\r\n\r\n  .img-section .delete-btn {\r\n    background-color: rgba(48, 48, 48, 0.641) !important;\r\n    position: absolute;\r\n    z-index: 3;\r\n    bottom: 0;\r\n    color: #fff;\r\n    border-radius: 0;\r\n    font-size: 12px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    padding: 3px;\r\n  }\r\n\r\n  .img-section .delete-btn:hover {\r\n    color: #eee\r\n  }\r\n  .forgot-text{\r\n    font-size: calc(8px + 4 * (100vw - 320px) / 1600);\r\n    color: #cd811e;\r\n    text-align: center;\r\n    &:hover{\r\n      color: #9d5400;\r\n    }\r\n  }\r\n}\r\n\r\n@media (max-width:767px) {\r\n  .user-profile {\r\n    .form-section .w-50 {\r\n      width: 100% !important;\r\n    }\r\n\r\n    .form-section .button-box {\r\n      justify-content: space-between !important;\r\n    }\r\n  }\r\n}\r\n\r\n\r\n@media (max-width: 1005px) {\r\n  .user-profile {\r\n    .form-section {\r\n      width: 100%;\r\n    }\r\n\r\n    .image-section {\r\n      width: 100%;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
