import React, { useState } from 'react';

import "./ServicesSubPages.scss";
import "./sec-2-optimized.scss";
import "./sec-3-optimized.scss";
import "./sec-3-b-optimized.scss";
import "./sec-4-optimized.scss";
import "./sec-5-optimized.scss";


const ServicesSubPage = () => {
	const [tabSelected, selectTab] = useState(null)
	const [sec2TabSelected, selectsec2Tab] = useState(0)
	return (


		<div className='services-subpage real-estate-finance'>
			<div className='banner'>
				<div className="vc_row wpb_row vc_row-fluid vc_custom_1504001004464 vc_row-has-fill sc_layouts_row sc_layouts_row_type_normal sc_layouts_hide_on_frontpage scheme_dark">
					<div className="wpb_column vc_column_container vc_col-sm-12 sc_layouts_column sc_layouts_column_align_left sc_layouts_column_icons_position_left">
						<div className="vc_column-inner">
							<div className="wpb_wrapper">
								<div className="vc_empty_space" style={{ height: "5em" }}><span className="vc_empty_space_inner" />
								</div>
								<div id="sc_content_730126100" className="sc_content color_style_default sc_content_default sc_content_width_1_1 sc_float_left">
									<div className="sc_content_container"><div className="sc_layouts_item">
										<div id="sc_layouts_title_933812428" className="sc_layouts_title with_content">
											<div className="sc_layouts_title_content">
												<div className="sc_layouts_title_title">
													<h1 className="sc_layouts_title_caption">Real Estate Finance </h1>
												</div>
												{/* <div className="sc_layouts_title_breadcrumbs">
													<div className="breadcrumbs"><a className="breadcrumbs_item home" href="https://antverp.axiomthemes.com/">Home</a>
													<span className="breadcrumbs_delimiter" />
													<span className="breadcrumbs_item current">Services</span>
													<span className="breadcrumbs_delimiter" />
													<span className="breadcrumbs_item current">Mortgage Services</span>
													</div>
												</div> */}

												<div className="sc_layouts_title_breadcrumbs">
													<div className="breadcrumbs">

														<span className="breadcrumbs_item current">Expert solutions to support all your real estate ventures.  </span>

													</div>
												</div>
											</div>

										</div></div>
									</div>
								</div>

								<div className="vc_empty_space" style={{ height: "5em" }}><span className="vc_empty_space_inner" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-2'>
				<div data-elementor-type="wp-page" data-elementor-id="4387" className="elementor elementor-4387">
					<div className="elementor-element elementor-element-2ff5bd3 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="2ff5bd3" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-8ec54ef e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="8ec54ef" data-element_type="container">
								<div className="elementor-element elementor-element-f49978a elementor-invisible elementor-widget elementor-widget-rt-title" data-id="f49978a" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Your Reliable Partner in  <br /> Real Estate Financing</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-f91eed0 e-grid e-con-full rt-parallax-bg-no e-con e-child" data-id="f91eed0" data-element_type="container">
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="b259677" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-1.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Residential Mortgage Financing </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>We simplify your home purchase with personalised mortgage options and competitive rates tailored for you. </p>
														</div><div className="rt-shape"> {/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="399a6ce" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-2.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Investment Property Financing</a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Unlock your investment potential with financing designed for rental properties, maximising your returns.</p>
														</div><div className="rt-shape"> {/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="599129a" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-3.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Refinancing Solutions</a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Refinance for better rates and access your home equity, helping you achieve financial freedom effortlessly. </p>
														</div><div className="rt-shape"> {/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="6a44201" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="1000ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1000ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-4.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Development Financing</a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Secure funding for your residential or commercial projects, with guidance for timely and budget-friendly completion.</p>
														</div><div className="rt-shape"> {/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="5d7af6d" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1">
												<div className="info-box wow fadeInUp" data-wow-delay="1200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1200ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-5.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Construction Financing </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Get flexible funding options that support your building project from start to finish, ensuring success.</p>
														</div><div className="rt-shape"> {/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-b259677 elementor-invisible elementor-widget elementor-widget-rt-info-box" data-id="28a9303" data-element_type="widget" data-widget_type="rt-info-box.default">
										<div className="elementor-widget-container">
											<div className="rt-info-box rt-info-layout-1" >
												<div className="info-box wow fadeInUp" data-wow-delay="1400ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "1400ms", animationName: "fadeInUp" }}>
													<div className="info-icon-holder icon-holder">
														<div className="info-icon">
															<img src='/images/services-subpages/real-estate-finance/sec-2-icon-6.svg' aria-hidden="true" className=" icon-rt-tax-service" />
														</div>
														<h3 className="info-title"><a>Bridging Loans </a>
														</h3>
													</div>
													<div className="info-content-holder">
														<div className="content-holder">
															<p>Need fast funds? Our short-term bridging loans help you seize opportunities quickly and effectively. </p>
														</div>
														<div className="rt-shape">
															{/* <svg width="581" height="510" viewBox="0 0 581 510" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M200.849 0.463867C203.899 0.463867 206.568 1.99659 208.093 4.67887L397.968 331.15C399.493 333.832 399.493 337.281 397.968 339.963L299.599 505.497C296.167 511.245 288.161 510.862 285.11 505.497L1.44234 13.1089C-1.98913 7.36114 2.20489 0.463867 8.68655 0.463867H200.849Z" fill="#A8E6FF" />
															<path d="M571.829 0.463867C578.31 0.463867 582.504 7.36114 579.073 13.1089L481.848 182.858C477.654 190.139 466.597 187.457 466.216 179.027L460.878 76.3338C460.878 73.6516 459.353 71.3525 457.065 69.8198L378.141 16.1743C371.278 11.5761 374.71 0.463867 382.717 0.463867H571.829Z" fill="#A8E6FF" />
														</svg> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>

			<div className='sec-3'>
				<div className='elementor elementor-43'>
					<div className="elementor-element elementor-element-5328b33 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="5328b33" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f8a9612 e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="f8a9612" data-element_type="container">
								<div className="elementor-element elementor-element-0a59994 elementor-invisible elementor-widget elementor-widget-rt-title" data-id="0a59994" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Maximise Your Real Estate Financing Potential </h2>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-fd95ffc elementor-invisible elementor-widget elementor-widget-rt-tab" data-id="fd95ffc" data-element_type="widget" data-widget_type="rt-tab.default">
									<div className="elementor-widget-container">
										<div className="rt-tab-block rt-tab-layout-2">
											<div className="tab-block wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
												<ul className="tab-block-tabs">
													<li className={`tab-block-tab ${sec2TabSelected === 0 ? "is-active" : ""}`} onClick={() => selectsec2Tab(0)} >
														{/* <img src='/images/services-subpages/real-estate-finance/sec-3-icon-1.svg' className=" icon-rt-secure-data-service" /> */}
														Flexible<br/> Mortgage<br/> Options 
													</li>
													<li className={`tab-block-tab ${sec2TabSelected === 1 ? "is-active" : ""}`} onClick={() => selectsec2Tab(1)}>
													{/* <img src='/images/services-subpages/real-estate-finance/sec-3-icon-2.svg' className=" icon-rt-dollar-1" /> */}
													Investment<br/> Property<br/> Financing 
													</li>
													<li className={`tab-block-tab ${sec2TabSelected === 2 ? "is-active" : ""}`} onClick={() => selectsec2Tab(2)}>
													{/* <img src='/images/services-subpages/real-estate-finance/sec-3-icon-3.svg' className=" icon-rt-data-service" /> */}
													Quick<br/> Refinancing<br/> Solutions 
													</li>
												</ul>
												<div className="tab-block-content">

													<div className="tab-block-pane" style={sec2TabSelected === 0 ? { display: 'block' } : { display: 'none' }}>
														<p>Explore a range of mortgage solutions tailored to your financial situation, ensuring a smooth home-buying process. </p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>    Competitive rates  </li>
																<li>    Custom repayment terms  </li>
																<li>    Fast approval process </li>
															</ul>
															{/* <div className="chart-info">
																<img decoding="async" data-savepage-src="/images/services-subpages/real-estate-finance/sec-3-graph.png" src="/images/services-subpages/real-estate-finance/sec-3-graph.png"></img>
															</div> */}
														</div>
													</div>
													<div className="tab-block-pane" style={sec2TabSelected === 1 ? { display: 'block' } : { display: 'none' }}>
														<p>Grow your real estate portfolio with flexible financing options designed for both seasoned and new investors.</p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>    Maximise returns  </li>
																<li>    Tailored for rental properties </li>
																<li>    Expert support  </li>
															</ul>
															{/* <div className="chart-info">
																<img decoding="async" data-savepage-src="/images/services-subpages/real-estate-finance/sec-3-graph.png" src="/images/services-subpages/real-estate-finance/sec-3-graph.png"></img>
															</div> */}
														</div>
													</div>
													<div className="tab-block-pane" style={sec2TabSelected === 2 ? { display: 'block' } : { display: 'none' }}>
														<p>Take control of your finances with our refinancing options, designed to lower payments and unlock home equity.  </p>
														<div className="feature-wrap">
															<ul className="feature-list">
																<li>    Reduce monthly costs </li>
																<li>    Access your equity </li>
																<li>    Flexible terms </li>
															</ul>
															{/* <div className="chart-info">
																<img decoding="async" data-savepage-src="" src="" alt="chart" width="203" height="100" data-savepage-loading="lazy"></img>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-e24efa9 e-con-full e-flex rt-parallax-bg-no e-con e-child" data-id="e24efa9" data-element_type="container">
								<div className="elementor-element elementor-element-1f160cf elementor-widget elementor-widget-rt-image" data-id="1f160cf" data-element_type="widget" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-1 ">
											<div className="rt-image wow bounceInUp" data-wow-delay="200ms" data-wow-duration="1200ms" style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "bounceInUp" }}>
												<div style={{ transform: "translate3d(25.365px, 0px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1)" }}>
													<img decoding="async" width="696" height="490" data-savepage-src="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/about-09.jpg" src="/images/services-subpages/real-estate-finance/sec-3.jpg"></img>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-4'>
				<div data-elementor-type="wp-page" data-elementor-id="6074" className="elementor elementor-6074">
					<div
						className="elementor-element elementor-element-3f1bdee e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded"
						data-id="3f1bdee" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-e75def9 e-con-full e-flex rt-parallax-bg-no e-con e-child"
								data-id="e75def9" data-element_type="container">
								<div
									className="elementor-element elementor-element-d0b6c21 elementor-invisible elementor-widget elementor-widget-rt-image"
									data-id="d0b6c21" data-element_type="widget" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-2 ">
											<div className="rt-image wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"
												style={{ position: "relative", zIndex: 1 }}>
												<span className="rt-img default" data-parallax="{&quot;y&quot; : , &quot;x&quot; : }"
													style={{ animationDuration: "15s" }}> <img decoding="async" width="819" height="819"
														data-savepage-src="/images/serviceses-subpages/real-estate-finance/sec-4.png"
														src="/images/services-subpages/real-estate-finance/sec-4.png"
														className="attachment-full size-full" alt=""
														data-savepage-srcset="/images/serviceses-subpages/real-estate-finance/sec-4.png 819w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-300x300.jpg 300w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-150x150.jpg 150w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-768x768.jpg 768w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/shape-bg-1-450x450.jpg 450w"
														srcset="" sizes="(max-width: 819px) 100vw, 819px" data-savepage-loading="lazy" />
												</span>
											</div>
										</div>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-6aa1a2b elementor-absolute elementor-widget elementor-widget-rt-image"
									data-id="6aa1a2b" data-element_type="widget"
									data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="rt-image.default">
									<div className="elementor-widget-container">
										<div className="rt-image-layout rt-image-layout-1 center">
											<div className="rt-image wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"
												style={{ visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp" }}>
												<div data-parallax="{&quot;y&quot; : 100, &quot;x&quot; : 0}"
													style={{ transform: "translate3d(0px, 67.58px, 0px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1))" }}>
													<img decoding="async" width="758" height="862"
														data-savepage-src="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support.png"
														src=""
														className="attachment-full size-full" alt=""
														data-savepage-srcset="https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support.png 758w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support-264x300.png 264w, https://www.radiustheme.com/demo/wordpress/themes/finwave/wp-content/uploads/2024/07/support-600x682.png 600w"
														srcset="" sizes="(max-width: 758px) 100vw, 758px" data-savepage-loading="lazy" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-c455ecf e-con-full e-flex rt-parallax-bg-no e-con e-child"
								data-id="c455ecf" data-element_type="container">
								<div
									className="elementor-element elementor-element-9b10626 elementor-invisible elementor-widget elementor-widget-rt-title"
									data-id="9b10626" data-element_type="widget" data-widget_type="rt-title.default">
									<div className="elementor-widget-container">
										<div className="section-title-wrapper ">
											<div className="title-inner-wrapper ah-headline">



												<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms">
													<h2 className="main-title title-gradient-animation no no middle ">Let’s Get to Know You Better</h2>
												</div>

												<div className="description wow-off fadeInUp" data-wow-delay="600ms" data-wow-duration="1200ms">
													<p>We’re here to collaborate with you on creating a personalised financial strategy that aligns with your unique goals. Let’s chat to see how we can support your journey! </p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									className="elementor-element elementor-element-8b6e03a elementor-invisible elementor-widget elementor-widget-rt-button"
									data-id="8b6e03a" data-element_type="widget" data-widget_type="rt-button.default">
									<div className="elementor-widget-container">
										<div className="rt-button wow-off fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms"> <a
											className="btn button-2 " href="/contact-us" target="_blank" rel="nofollow" aria-label="button link"> Talk to an Expert 
											{/*<i className="icon-rt-right-arrow">
												<svg width="25" height="15" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M16.1123 14.1123L16.7472 14.7472L24.0942 7.40016L16.7472 0.0531006L16.1123 0.688032L22.3709 6.94664H0.783203V7.85368H22.3709L16.1123 14.1123Z" fill="#000010" />
												</svg>
											</i>*/} </a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='sec-5'>
				<div data-elementor-type="wp-page" data-elementor-id="94" className="elementor elementor-94">
					<div className="elementor-element elementor-element-1bb2f45 e-flex e-con-boxed rt-parallax-bg-no e-con e-parent e-lazyloaded" data-id="1bb2f45" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-07292ce e-flex e-con-boxed rt-parallax-bg-no e-con e-child" data-id="07292ce" data-element_type="container">
								<div className="e-con-inner">
									<div className="elementor-element elementor-element-4cccb55 elementor-invisible elementor-widget elementor-widget-rt-title" data-id="4cccb55" data-element_type="widget" data-widget_type="rt-title.default">
										<div className="elementor-widget-container">
											<div className="section-title-wrapper ">
												<div className="title-inner-wrapper ah-headline">



													<div className="wow-off fadeInUp" data-wow-delay="400ms" data-wow-duration="1200ms"><h2 className="main-title middle">Real Estate Financing FAQs </h2>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="elementor-element elementor-element-d486b67 faq-style-1 elementor-widget elementor-widget-accordion" data-id="d486b67" data-element_type="widget" data-widget_type="accordion.default">
										<div className="elementor-widget-container">
											<div className="elementor-accordion">
												<div className="elementor-accordion-item" onClick={() => selectTab(0)}>
													<div id="elementor-tab-title-2221" className={`elementor-tab-title ${tabSelected === 0 ? 'elementor-active' : ''}`} data-tab="1" role="button" aria-controls="elementor-tab-content-2221" aria-expanded="true" tabindex="0" aria-selected="true">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true"> <span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
														</svg>
														</span>
															<span className="elementor-accordion-icon-opened">
																<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
																</svg>


															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">What types of financing options do you offer for investment properties?</a>
													</div>
													<div id="elementor-tab-content-2221" className={`elementor-tab-content elementor-clearfix ${tabSelected === 0 ? 'elementor-active' : ''}`} data-tab="1" role="region" aria-labelledby="elementor-tab-title-2221" >
														<p>We offer various financing options, including traditional loans, hard money loans, and portfolio loans, to help you grow your investment portfolio effectively.</p>
													</div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(1)}>
													<div id="elementor-tab-title-2222" className={`elementor-tab-title ${tabSelected === 1 ? 'elementor-active' : ''}`} data-tab="2" role="button" aria-controls="elementor-tab-content-2222" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed">
																<svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
																</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
															</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">Can I refinance my current mortgage with your services?</a>
													</div>
													<div id="elementor-tab-content-2222" className={`elementor-tab-content elementor-clearfix ${tabSelected === 1 ? 'elementor-active' : ''}`} data-tab="2" role="region" aria-labelledby="elementor-tab-title-2222" >
														<p>Absolutely! Our refinancing solutions are designed to help you lower your payments and tap into your home’s equity for better financial flexibility. </p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(2)}>
													<div id="elementor-tab-title-2223" className={`elementor-tab-title ${tabSelected === 2 ? 'elementor-active' : ''}`} data-tab="3" role="button" aria-controls="elementor-tab-content-2223" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
															</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">What factors should I consider when financing a new construction home?</a>
													</div><div id="elementor-tab-content-2223" className={`elementor-tab-content elementor-clearfix ${tabSelected === 2 ? 'elementor-active' : ''}`} data-tab="3" role="region" aria-labelledby="elementor-tab-title-2223" >
														<p>Key factors include the total project cost, builder reputation, loan options, and timelines. We’ll help you navigate these to find the best fit. </p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(3)}>
													<div id="elementor-tab-title-2224" className={`elementor-tab-title ${tabSelected === 3 ? 'elementor-active' : ''}`} data-tab="4" role="button" aria-controls="elementor-tab-content-2224" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
															</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">Do you provide financial advice for commercial real estate financing?</a>
													</div><div id="elementor-tab-content-2224" className={`elementor-tab-content elementor-clearfix ${tabSelected === 3 ? 'elementor-active' : ''}`} data-tab="4" role="region" aria-labelledby="elementor-tab-title-2224" >
														<p>Yes, we offer tailored advice and financing solutions for commercial properties, ensuring you have the right funding for your business needs.</p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(4)}>
													<div id="elementor-tab-title-2225" className={`elementor-tab-title ${tabSelected === 4 ? 'elementor-active' : ''}`} data-tab="5" role="button" aria-controls="elementor-tab-content-2225" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
															</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">Are there any hidden fees I should be aware of in real estate financing?</a>
													</div><div id="elementor-tab-content-2225" className={`elementor-tab-content elementor-clearfix ${tabSelected === 4 ? 'elementor-active' : ''}`} data-tab="5" role="region" aria-labelledby="elementor-tab-title-2225" >
														<p>Transparency is key for us! We’ll provide a detailed breakdown of all potential fees upfront so you can make informed decisions without surprises.</p></div>
												</div>
												<div className="elementor-accordion-item" onClick={() => selectTab(5)}>
													<div id="elementor-tab-title-2226" className={`elementor-tab-title ${tabSelected === 5 ? 'elementor-active' : ''}`} data-tab="6" role="button" aria-controls="elementor-tab-content-2226" aria-expanded="false">
														<span className="elementor-accordion-icon elementor-accordion-icon-right" aria-hidden="true">
															<span className="elementor-accordion-icon-closed"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.9805 0.16333C32.0258 0.16333 40.9805 9.118 40.9805 20.1633C40.9805 31.2087 32.0258 40.1633 20.9805 40.1633C9.93514 40.1633 0.980469 31.2087 0.980469 20.1633C0.980469 9.118 9.93514 0.16333 20.9805 0.16333ZM19.6471 12.3856V18.83H13.2027C11.4475 18.83 11.4475 21.4967 13.2027 21.4967H19.6471V27.9411C19.6471 29.6963 22.3138 29.6963 22.3138 27.9411V21.4967H28.7582C30.5134 21.4967 30.5134 18.83 28.7582 18.83H22.3138V12.3856C22.3138 10.6304 19.6471 10.6304 19.6471 12.3856ZM33.2374 7.90644C26.4685 1.13755 15.4925 1.13755 8.72536 7.90644C1.95647 14.6753 1.95647 25.6513 8.72536 32.4184C15.4942 39.1873 26.4702 39.1873 33.2374 32.4184C40.0062 25.6509 40.0062 14.6753 33.2374 7.90644Z" fill="#000010" />
															</svg>

															</span> <span className="elementor-accordion-icon-opened"><svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path fill-rule="evenodd" clip-rule="evenodd" d="M20.019 0.0429688C31.0644 0.0429688 40.019 8.99764 40.019 20.043C40.019 31.0883 31.0644 40.043 20.019 40.043C8.97371 40.043 0.019043 31.0883 0.019043 20.043C0.019043 8.99764 8.97371 0.0429688 20.019 0.0429688ZM12.2413 21.3763H27.7968C29.552 21.3763 29.552 18.7096 27.7968 18.7096H12.2413C10.4861 18.7096 10.4861 21.3763 12.2413 21.3763ZM32.2759 7.78608C25.507 1.01719 14.531 1.01719 7.76215 7.78608C0.993266 14.555 0.993266 25.531 7.76215 32.2981C14.531 39.067 25.507 39.067 32.2759 32.2981C39.0448 25.5305 39.0448 14.555 32.2759 7.78608Z" fill="#CFDD28" />
															</svg>

															</span>
														</span> <a className="elementor-accordion-title" tabindex="0">How do I know what type of financing is right for me?</a>
													</div><div id="elementor-tab-content-2226" className={`elementor-tab-content elementor-clearfix ${tabSelected === 5 ? 'elementor-active' : ''}`} data-tab="6" role="region" aria-labelledby="elementor-tab-title-2226" >
														<p>We’ll assess your financial goals, credit history, and property type to recommend the best financing options tailored to your situation.</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


		</div>

	);
}

export default ServicesSubPage