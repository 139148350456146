// constants.js

export const UK_COUNTIES = [
    { id: 0, county_name: 'Aberdeenshire' },
    { id: 1, county_name: 'Angus' },
    { id: 2, county_name: 'Antrim' },
    { id: 3, county_name: 'Argyll and Bute' },
    { id: 4, county_name: 'Armagh' },
    { id: 5, county_name: 'Bedfordshire' },
    { id: 6, county_name: 'Berkshire' },
    { id: 7, county_name: 'Blaenau Gwent' },
    { id: 8, county_name: 'Bridgend' },
    { id: 9, county_name: 'Buckinghamshire' },
    { id: 10, county_name: 'Caerphilly' },
    { id: 11, county_name: 'Cambridgeshire' },
    { id: 12, county_name: 'Carmarthenshire' },
    { id: 13, county_name: 'Ceredigion' },
    { id: 14, county_name: 'Cheshire' },
    { id: 15, county_name: 'Clackmannanshire' },
    { id: 16, county_name: 'Conwy' },
    { id: 17, county_name: 'Cornwall' },
    { id: 18, county_name: 'Cumbria' },
    { id: 19, county_name: 'Denbighshire' },
    { id: 20, county_name: 'Derbyshire' },
    { id: 21, county_name: 'Derry' },
    { id: 22, county_name: 'Devon' },
    { id: 23, county_name: 'Dorset' },
    { id: 24, county_name: 'Down' },
    { id: 25, county_name: 'Dumfries and Galloway' },
    { id: 26, county_name: 'Dundee' },
    { id: 27, county_name: 'Durham' },
    { id: 28, county_name: 'East Ayrshire' },
    { id: 29, county_name: 'East Dunbartonshire' },
    { id: 30, county_name: 'East Lothian' },
    { id: 31, county_name: 'East Renfrewshire' },
    { id: 32, county_name: 'East Riding of Yorkshire' },
    { id: 33, county_name: 'East Sussex' },
    { id: 34, county_name: 'Edinburgh' },
    { id: 35, county_name: 'Essex' },
    { id: 36, county_name: 'Falkirk' },
    { id: 37, county_name: 'Fermanagh' },
    { id: 38, county_name: 'Fife' },
    { id: 39, county_name: 'Flintshire' },
    { id: 40, county_name: 'Glasgow' },
    { id: 41, county_name: 'Gloucestershire' },
    { id: 42, county_name: 'Greater London' },
    { id: 43, county_name: 'Greater Manchester' },
    { id: 44, county_name: 'Gwynedd' },
    { id: 45, county_name: 'Hampshire' },
    { id: 46, county_name: 'Herefordshire' },
    { id: 47, county_name: 'Hertfordshire' },
    { id: 48, county_name: 'Highlands' },
    { id: 49, county_name: 'Inverclyde' },
    { id: 50, county_name: 'Isle of Anglesey' },
    { id: 51, county_name: 'Isle of Wight' },
    { id: 52, county_name: 'Kent' },
    { id: 53, county_name: 'Lanarkshire' },
    { id: 54, county_name: 'Lancashire' },
    { id: 55, county_name: 'Leicestershire' },
    { id: 56, county_name: 'Lincolnshire' },
    { id: 57, county_name: 'Lisburn' },
    { id: 58, county_name: 'Merseyside' },
    { id: 59, county_name: 'Merthyr Tydfil' },
    { id: 60, county_name: 'Midlothian' },
    { id: 61, county_name: 'Monmouthshire' },
    { id: 62, county_name: 'Moray' },
    { id: 63, county_name: 'Neath Port Talbot' },
    { id: 64, county_name: 'Newport' },
    { id: 65, county_name: 'Norfolk' },
    { id: 66, county_name: 'North Ayrshire' },
    { id: 67, county_name: 'North Lanarkshire' },
    { id: 68, county_name: 'North Somerset' },
    { id: 69, county_name: 'North Yorkshire' },
    { id: 70, county_name: 'Northamptonshire' },
    { id: 71, county_name: 'Northumberland' },
    { id: 72, county_name: 'Nottinghamshire' },
    { id: 73, county_name: 'Orkney Islands' },
    { id: 74, county_name: 'Oxfordshire' },
    { id: 75, county_name: 'Pembrokeshire' },
    { id: 76, county_name: 'Perth and Kinross' },
    { id: 77, county_name: 'Powys' },
    { id: 78, county_name: 'Renfrewshire' },
    { id: 79, county_name: 'Rhondda Cynon Taff' },
    { id: 80, county_name: 'Rutland' },
    { id: 81, county_name: 'Scottish Borders' },
    { id: 82, county_name: 'Shetland Islands' },
    { id: 83, county_name: 'Shropshire' },
    { id: 84, county_name: 'Somerset' },
    { id: 85, county_name: 'South Ayrshire' },
    { id: 86, county_name: 'South Gloucestershire' },
    { id: 87, county_name: 'South Lanarkshire' },
    { id: 88, county_name: 'South Yorkshire' },
    { id: 89, county_name: 'Staffordshire' },
    { id: 90, county_name: 'Stirling' },
    { id: 91, county_name: 'Suffolk' },
    { id: 92, county_name: 'Surrey' },
    { id: 93, county_name: 'Swansea' },
    { id: 94, county_name: 'Torfaen' },
    { id: 95, county_name: 'Tyne and Wear' },
    { id: 96, county_name: 'Tyrone' },
    { id: 97, county_name: 'Vale of Glamorgan' },
    { id: 98, county_name: 'Warwickshire' },
    { id: 99, county_name: 'West Dunbartonshire' },
    { id: 100, county_name: 'West Lothian' },
    { id: 101, county_name: 'West Midlands' },
    { id: 102, county_name: 'West Sussex' },
    { id: 103, county_name: 'West Yorkshire' },
    { id: 104, county_name: 'Western Isles' },
    { id: 105, county_name: 'Wiltshire' },
    { id: 106, county_name: 'Worcestershire' },
    { id: 107, county_name: 'Wrexham' },
    { id: 108, county_name: 'Yorkshire' },
]

export const UK_COUNTRIES = [
    { id: 0, country_name: 'England' },
    { id: 1, country_name: 'Scotland' },
    { id: 2, country_name: 'Wales' },
    { id: 3, country_name: 'Northern Ireland' }
];

export const DELIVERY_STATUSES = [
    'cancelled',
    'pending',
    'confirmed',
    'preparing',
    'ready_for_pickup',
    'on_the_way',
    'failed_delivery_attempt',
    'delivered',
    'returned',
    'refunded',
]
export  const TICKET_TYPE_LIST = [
    'missing_item', 'refund_request', 'late_delivery', 'general_inquiries', 'payment_issues', 'other_delivery_issues', 'other_item_issues', 'none_of_the_above'
]

export  const MAIL_LIST_SUBJECTS = [
    'Marketing Tips',
    'Marketing Campaign Suggestions',
    'Food Waste Management Tips',
    'Crisis Management Support',
    'Industry Best Practices',
    'Equipment Upgrade Recommendations',
    'Food Safety Regulations',
    'Supplier Change Recommendations',
    'Sustainability Practices Recommendations',
    'Seasonal Trends',
    'Weather Impact Analysis Report',
    'Tips for Customer Service Training',
    'Updates on Health Inspections',
    'Employee Training Tips',
    'Cost Reduction Recommendations',
    'Marketing Strategy Tips',
    'Alert for Supplier Issues',
    'Current Food Trends',
    'Local Event Updates',
    'Recommendations on Local Suppliers',
    'New Equipment Purchase Recommendations',
    'Tips for Staff Motivation',
    'Recommendations on Local Regulations'    
]