import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API from '../../../API';
import axios from 'axios';
import '../../common-component/ImageUploader.css'
import './Profile.scss'
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import LogoutPopup from '../../../authentication/LogoutPopup';
import PhoneInputField from '../../../authentication/pages/CustomPhone/CustomPhoneInput';
import { isValidPhoneNumber } from 'libphonenumber-js';
import ChangeEmailAddress from '../../common-component/ChangeEmailAddress/ChangeEmailAddress';

const Profile = ({ loadUserDataForHeader }) => {

  const navigate = useNavigate()
  const [userData, setUserData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [isLogoutModalShow, setLogoutModalShow] = useState(false)
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [imageError, setImageError] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isEmailEditPopup, setIsEmailEditPopup] = useState(false);


  const [phoneData, setPhoneData] = useState({ phone_number: "", country_code: "" })
  useEffect(() => {
    setUserData(prevData => ({
      ...prevData,
      phone_number: phoneData.phone_number,
      country_code: phoneData.country_code
    }));
  }, [phoneData]);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  useEffect(() => {
    loadData();
  }, []);

  const validatePhoneNumber = (phoneNumber, countryCode) => {
    try {
      const parsedPhoneNumber = isValidPhoneNumber(`+${phoneNumber}`, countryCode);
      return parsedPhoneNumber ? null : 'Invalid phone number';
    } catch (error) {
      return 'Invalid phone number';
    }
  };

  const loadData = () => {
    API.get(`/user/users/${window.localStorage.getItem('userID')}`)
      .then(response => {
        setUserData(response.data);
        setPhoneData({ phone_number: response.data.phone_number, country_code: response.data.country_code })
        if (response.data.profile_image) {
          setPreviewImage(`${response.data.profile_image}`);
        }
      })
      .catch(error => {
        console.error(error);
      });


  }



  const userID = localStorage.getItem('userID'); // Fetch the user ID from local storage

  const handleImageSelect = (event) => {
    const file = event.target.files[0];
    // Check image dimensions
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const validAspectRatio = aspectRatio >= 0.9 && aspectRatio <= 1.1;

      if (img.width <= 250 && img.height <= 250 && validAspectRatio) {
        if (file.size <= 300 * 1024) { // Max size in bytes (300 KB)
          setImageError(null)
          setIsImgLoading(true);
          setPreviewImage(URL.createObjectURL(file));

          const formData = new FormData();
          formData.append('profile_image', file);
          API.put(`/user/users/${userID}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then(response => {
              setMessage("Profile image updated successfuly!")
              setIsMessageModalOpen(true)
              setIsImgLoading(false);
              loadData();
              loadUserDataForHeader();
            })
            .catch(error => {
              setIsImgLoading(false);
              setMessage(error.response?.data?.message || error.message)
              setIsErrorModalOpen(true)
            });

        }
        else {
          setImageError('Image file size is too large.');
        }
      } else {
        setImageError('Image dimensions are not within the specified limits.');
      }
    };
  };


  const handleImageDelete = () => {
    API.put(`/user/users/${userID}/`, { profile_image: null })
      .then(response => {
        setPreviewImage(null);
        loadData();
        loadUserDataForHeader();
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  };


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const updateData = (field) => {
    if (field === 'phone_number') {
      if (validatePhoneNumber(userData.phone_number, userData.country_code)) {
        setMessage('Invalid Phone Number')
        setIsErrorModalOpen(true);
        loadData();
        // setErrors({phone_number:'Invalid Phone Number'})
        return
      }
    }
    setIsLoading(true)
    const formData = userData
    delete formData['profile_image']
    API.put(`/user/users/${userID}/`, formData)
      .then(response => {
        setIsLoading(false)
        loadData();
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }



  return (
    <div className='user-profile'>

      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Profile</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <a href="index.html"><i className="ri-home-line"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Profile</li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="profile-section section-b-space">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3">
              <div className="profile-sidebar sticky-top">
                <div className="profile-cover">
                  <img className="img-fluid profile-pic" src={`${userData && userData.profile_image ? userData.profile_image : '/images/no-profile-image.png'}`} alt="profile" />
                </div>
                <div className="profile-name">
                  <h5 className="user-name">
                    {userData && userData.first_name} &nbsp;
                    {userData && userData.last_name}
                  </h5>
                  <h6>{userData && userData.email}</h6>
                </div>
                <ul className="profile-list">
                  <li className="active">
                    <i className="ri-user-3-line"></i>
                    <a onClick={() => navigate('/profile')}>Change Profile</a>
                  </li>
                  <li>
                    <i className="ri-shopping-bag-3-line"></i>
                    <a onClick={() => navigate('/orders')}>My Order</a>
                  </li>
                  <li>
                    <i className="ri-map-pin-line"></i>
                    <a onClick={() => navigate('/saved-address')}>Saved Address</a>
                  </li>
                  <li>
                    <i className="ri-bank-card-line"></i>
                    <a onClick={() => navigate('/saved-payment-methods')}>Payment Methods</a>
                  </li>
                  <li>
                    <i className="ri-medicine-bottle-line"></i>
                    <a onClick={() => navigate('/allergies')}>Allergens</a>
                  </li>
                  <li>
                    <i className="ri-question-line"></i>
                    <a onClick={() => navigate('/faq')}>Help</a>
                  </li>
                  <li>
                    <i className="ri-settings-3-line"></i>
                    <a onClick={() => navigate('/profile-settings')}>Settings</a>
                  </li>
                  <li>
                    <i className="ri-logout-box-r-line"></i>
                    <a onClick={() => setLogoutModalShow(true)}>Log Out</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              {!isEmailEditPopup ?
                <div className="change-profile-content">
                  <div className="title">
                    <div className="loader-line"></div>
                    <h3>Change Profile</h3>
                  </div>
                  <ul className="profile-details-list">
                    {/* profile image */}
                    <div className='img-section bg-white box-shadow radius-11 mb-3 mb-lg-0 pt-4 pb-2'>
                      <div className="profile-image-uploader d-flex flex-column justify-content-center align-items-center" style={{ position: "static", zIndex: 1, backgroundColor: "transparent", height: "fit-content" }}>
                        <div className="image-preview-box">
                          {previewImage ?
                            <img src={previewImage} alt="" style={isImgLoading ? { opacity: '40%' } : { opacity: '100%' }} className="preview-image" />
                            :
                            <img src="/images/profile/avatar-no-profile-image.png"></img>
                          }
                          {previewImage &&
                            <button className="btn btn-small-danger d-flex justify-content-center  w-100 delete-btn" onClick={handleImageDelete}> <svg className="me-2" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M21.9878 6.41309H3.98779V9.41309C5.09236 9.41309 5.98779 10.3085 5.98779 11.4131V15.4131C5.98779 18.2415 5.98779 19.6557 6.86647 20.5344C7.74515 21.4131 9.15937 21.4131 11.9878 21.4131H13.9878C16.8162 21.4131 18.2304 21.4131 19.1091 20.5344C19.9878 19.6557 19.9878 18.2415 19.9878 15.4131V11.4131C19.9878 10.3085 20.8832 9.41309 21.9878 9.41309V6.41309ZM11.4878 11.4131C11.4878 10.8608 11.0401 10.4131 10.4878 10.4131C9.93551 10.4131 9.48779 10.8608 9.48779 11.4131V16.4131C9.48779 16.9654 9.93551 17.4131 10.4878 17.4131C11.0401 17.4131 11.4878 16.9654 11.4878 16.4131V11.4131ZM16.4878 11.4131C16.4878 10.8608 16.0401 10.4131 15.4878 10.4131C14.9355 10.4131 14.4878 10.8608 14.4878 11.4131V16.4131C14.4878 16.9654 14.9355 17.4131 15.4878 17.4131C16.0401 17.4131 16.4878 16.9654 16.4878 16.4131V11.4131Z" fill="#fff" fill-opacity="0.85" />
                              <path d="M11.0559 3.78368C11.1699 3.67736 11.421 3.58341 11.7703 3.51641C12.1196 3.4494 12.5475 3.41309 12.9878 3.41309C13.4281 3.41309 13.856 3.4494 14.2053 3.51641C14.5546 3.58341 14.8057 3.67736 14.9196 3.78368" stroke="#fff" strokeOpacity="0.85" strokeWidth="2" stroke-linecap="round" />
                            </svg>Remove Image</button>}
                          {isImgLoading &&
                            <div
                              style={{

                                position: "absolute",
                                zIndex: "201",
                                top: "200px"
                              }}
                            >
                              <div className="spinner-border" role="status">
                                <span className="sr-only "></span>
                              </div>
                            </div>}

                        </div>

                        <div className=" mb-2 d-flex justify-content-center">
                          <input className="btn btn-secondary image-input-button w-100" type="file" accept="image/*" onChange={handleImageSelect} />

                        </div>


                        {imageError && <p className="img-error-msg">{imageError}</p>}
                        <p className="image-instruction text-center"> File size should be less than 300kB<br></br>
                          File resolution Max height: 250px and Max width:250px, in a square aspect ratio. </p>

                      </div>
                    </div>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                          <i className="ri-user-3-fill"></i>
                          <span>Name :</span>
                        </div>
                        <h6>{userData.first_name} {userData.last_name}</h6>
                      </div>
                      <a href="#name" className="btn theme-outline" data-bs-toggle="modal">Edit</a>
                    </li>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                          <i className="ri-mail-fill"></i>
                          <span>Email :</span>
                        </div>
                        <h6>{userData.email}</h6>
                      </div>
                      <a className="btn theme-outline" onClick={() => setIsEmailEditPopup(true)}>Change</a>
                    </li>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center justify-content-center gap-sm-2 gap-1">
                          <i className="ri-mail-fill"></i>
                          <span>Gender :</span>
                          <h6 className='mt-0 ps-2'>{userData.gender}</h6>
                        </div>
                      </div>
                      <a href="#gender" className="btn theme-outline" data-bs-toggle="modal">
                        {userData.gender ? "Change" : "Add"}
                      </a>
                    </li>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center justify-content-center gap-sm-2 gap-1">
                          <i className="ri-mail-fill"></i>
                          <span>Date of Birth :</span>
                          <h6 className='mt-0 ps-2'>{userData.dob}</h6>
                        </div>
                      </div>
                      <a href="#dob" className="btn theme-outline" data-bs-toggle="modal">
                        {userData.dob ? "Change" : "Add"}
                      </a>
                    </li>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                          <i className="ri-phone-fill"></i>
                          <span>Phone Number :</span>
                        </div>
                        <h6>+{userData.phone_number}</h6>
                      </div>
                      <a href="#number" className="btn theme-outline mt-0" data-bs-toggle="modal">
                        {userData.phone_number ? "Change" : "Add"}
                      </a>
                    </li>
                    <li>
                      <div className="profile-content">
                        <div className="d-flex align-items-center gap-sm-2 gap-1">
                          <i className="ri-lock-2-fill"></i>
                          <span>Password :</span>
                        </div>
                        <h6>********</h6>
                      </div>
                      <div className='d-flex flex-column'>
                        {/* <a href="#password" className="btn theme-outline mt-0" data-bs-toggle="modal">Change</a> */}
                        <a onClick={() => navigate('/forgot-password')} className="btn theme-outline mt-0" >Change</a>
                        {/* <a onClick={() => navigate('/forgot-password')} className="mt-2 forgot-text" >Forgot Password</a> */}
                      </div>
                    </li>
                  </ul>
                </div>
                :
                <ChangeEmailAddress userData={userData} setterFunction={setIsEmailEditPopup} />
              }
            </div>
          </div>
        </div>
      </section>

      {/* Edit Modals */}
      <div className="modal profile-modal fade" id="name" aria-hidden="true" aria-labelledby="exampleModalToggleName"
        tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalToggleName">Change Name</h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label for="inputFirstName" className="form-label">First Name</label>
                <input type="text" className="form-control" id="inputFirstName" name='first_name' value={userData.first_name}
                  onChange={handleInputChange} placeholder="Enter your name" />
              </div>
              <div className="form-group">
                <label for="inputLastName" className="form-label">Last Name</label>
                <input type="text" className="form-control" id="inputLastName" name='last_name' value={userData.last_name}
                  onChange={handleInputChange} placeholder="Enter your name" />
              </div>
            </div>
            <div className="modal-footer">
              <a onClick={updateData} className="btn theme-btn" data-bs-dismiss="modal">Save</a>
            </div>
          </div>
        </div>
      </div>






      {/* Gender, DoB, PhoneNumber */}
      <div className="modal profile-modal fade" id="gender" aria-hidden="true" aria-labelledby="exampleModalToggleGender"
        tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalToggleEmail">Select Gender</h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label for="inputGender" className="form-label">Gender</label>
                <select className="form-select" id="gender" name="gender" value={userData.gender}
                  onChange={handleInputChange}>
                  <option value={'Male'}>Male</option>
                  <option value={'Female'}>Female</option>
                  <option value={'LGBTQ'}>LGBTQ</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <a onClick={updateData} className="btn theme-btn" data-bs-dismiss="modal">Save</a>
            </div>
          </div>
        </div>
      </div>
      <div className="modal profile-modal fade" id="dob" aria-hidden="true" aria-labelledby="exampleModalToggleGender"
        tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalToggleEmail">Date of Birth</h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                {/* <label for="inputDob" className="form-label"></label> */}
                <input className='form-control' type="date" value={userData.dob} id="dob" name="dob" onChange={handleInputChange}></input>
              </div>
            </div>
            <div className="modal-footer">
              <a onClick={updateData} className="btn theme-btn" data-bs-dismiss="modal">Save</a>
            </div>
          </div>
        </div>
      </div>

      <div className="modal profile-modal fade" id="number" aria-hidden="true" aria-labelledby="exampleModalToggleCall"
        tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalToggleCall">
                Phone Number
              </h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                {/* <label for="inputNumber" className="form-label">Phone Number</label> */}
                <PhoneInputField formData={phoneData} setFormData={setPhoneData} />
              </div>
            </div>
            <div className="modal-footer">
              <a onClick={() => updateData('phone_number')} className="btn theme-btn" data-bs-dismiss="modal">Save</a>
            </div>
          </div>
        </div>
      </div>

      <div className="modal profile-modal fade" id="password" aria-hidden="true" aria-labelledby="exampleModalTogglePass"
        tabindex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title" id="exampleModalTogglePass">Password</h1>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label for="inputcurrentPassword" className="form-label">
                  Current Password</label>
                <input type="password" className="form-control" id="inputcurrentPassword"
                  placeholder="Enter your current password" />
              </div>
              <div className="form-group mt-2">
                <label for="inputnewPassword" className="form-label">
                  New Password</label>
                <input type="password" className="form-control" id="inputnewPassword"
                  placeholder="Enter your new password" />
              </div>
              <div className="form-group mt-2">
                <label for="inputconfirmPassword" className="form-label">Confirm Password</label>
                <input type="password" className="form-control" id="inputconfirmPassword"
                  placeholder="Enter your confirm password" />
              </div>
            </div>
            <div className="modal-footer">
              <a href="profile.html" className="btn theme-btn" data-bs-dismiss="modal">Save</a>
            </div>
          </div>
        </div>
      </div>




      {isLogoutModalShow && <LogoutPopup setterFunction={setLogoutModalShow}></LogoutPopup>}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadData} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadData} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default Profile