import React, { useEffect, useState, useRef } from 'react'


import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';





const Userhome = ({ userData, loadUserData }) => {
  const navigate = useNavigate();


  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const position = { lat: 61.2176, lng: -149.8997 };

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const accordionData = [
    {
      title: "How do I place an order?",
      content: "Visit our website, browse the menu and select the items you want to order. Choose your delivery address and preferred payment method. Review your order and confirm to place it.",
    },
    {
      title: "What are the delivery options?",
      content: "We offer standard delivery, which typically takes 30-45 minutes. You can also choose express delivery for a faster delivery time, subject to availability. Some restaurants may offer self-pickup as an alternative to delivery.",
    },
    {
      title: "Do you deliver to my area? ",
      content: "We deliver to a wide range of locations. Enter your address on our website to check if we service your area. If your location is not currently covered, we are continuously expanding our delivery zones, so please check back regularly. ",
    },
    {
      title: "Can I order ahead of time? ",
      content: "Yes, you can schedule your order for a future delivery time, subject to restaurant and delivery availability. You can place an order up to 24hours in advance through our website. ",
    },
    {
      title: "What payment methods do you accept? ",
      content: "We accept a variety of payment methods, including credit/debit cards, mobile wallets, and online payment services. You can securely store your preferred payment method in your account for future orders. ",
    },
    {
      title: "Can I make changes or cancel my order? ",
      content: "You can make changes to your order, such as modifying items or delivery instructions, as long as the order has not been processed by the restaurant. Orders can be cancelled before they are accepted by the restaurant, subject to our cancellation policy. ",
    },
    {
      title: "Do you offer any promotions or discounts? ",
      content: "We regularly offer promotional discounts, coupons, and special offers to our customers. Check our website for the latest deals and discounts. You can also sign up for our newsletter to stay updated on our promotions. ",
    },
    {
      title: "How do I track my order? ",
      content: "Once your order is placed, you can track its status through our website. You'll receive real-time updates on the order preparation and delivery progress. You can also contact our customer support if you have any questions about your order. ",
    },
    {
      title: "What if I have a dietary restriction or allergy? ",
      content: "Many of our partner restaurants offer a variety of dietary-friendly options, such as vegetarian, vegan, or gluten-free meals. You can use our filters to search for specific dietary requirements or allergens. If you have any special requests, please include them in the order instructions, and we'll do our best to accommodate them. ",
    },
    {
      title: "How do I provide feedback or contact customer support? ",
      content: "You can reach out to our customer support team through our website, mobile app, or by email. We value your feedback and strive to continuously improve our service. Please feel free to share your comments, suggestions, or concerns. ",
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const getAccordionStyle = (index) => {
    const isActive = activeIndex === index;
    const scrollHeight = contentRefs.current[index]?.scrollHeight;
    return {
      maxHeight: isActive ? `${scrollHeight}px` : '0px',
      overflow: 'hidden',
      transition: 'max-height 0.35s ease',
    };
  };




  return (
    <div className='faq-page'>

      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">FAQ</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <a href="index.html"><i className="ri-home-line"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">FAQ</li>
            </ol>
          </nav>
        </div>
      </section>


      <section className="section-b-space">
        <div className="container">
          <div className="faq-title">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="row g-4">
            <div className="col-xl-4">
              <div className="side-img">
                <img className="img-fluid img" src="/images/faq.svg" alt="faq"></img>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="accordion help-accordion" id="accordionExample">
                {accordionData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                        type="button"
                        onClick={() => toggleAccordion(index)}
                      >
                        {item.title}
                      </button>
                    </h2>
                    <div
                      ref={(el) => (contentRefs.current[index] = el)}
                      className="accordion-collapse"
                      style={getAccordionStyle(index)}
                    >
                      <div className="accordion-body">
                        {item.content}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoading && <FixedOverlayLoadingSpinner />}


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div >

  );


}

export default Userhome