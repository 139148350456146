import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import "./forzurichsection.scss";
import "./forfingconsection.scss";
import "./forzurichaboutsection.scss";
import "./Services.scss";

const TesterPage = () => {
	const [tabSelected, selectTab] = useState(null)
	const navigate = useNavigate();
	return (
		<div className='services-page'>


			<div data-elementor-type="wp-page" data-elementor-id="2" className="elementor elementor-2">

				<section className="elementor-section elementor-top-section elementor-element elementor-element-10d1e59 elementor-section-items-stretch elementor-section-height-min-height elementor-reverse-mobile elementor-section-boxed elementor-section-height-default" data-id="10d1e59" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
					<div className="elementor-container elementor-column-gap-no">
						<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-79628975" data-id="79628975" data-element_type="column">
							<div className="elementor-widget-wrap elementor-element-populated">
								<section className="elementor-section elementor-inner-section elementor-element elementor-element-172229de elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="172229de" data-element_type="section">
									<div className="elementor-container elementor-column-gap-no">
										<div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-66f5d7f9" data-id="66f5d7f9" data-element_type="column">
											<div className="elementor-widget-wrap elementor-element-populated">
												<div className="elementor-element elementor-element-3d650be5 elementor-widget elementor-widget-heading" data-id="3d650be5" data-element_type="widget" data-widget_type="heading.default">
													<div className="elementor-widget-container">
														<link rel="stylesheet" href="css/style30.css" />
														<h1 className="elementor-heading-title elementor-size-default">Expert Services

															<br />That Support<br /> Your <span style={{ backgroundColor: "#CFDD28" }}>Financial Goals</span></h1>
													</div>
												</div>
												<div className="elementor-element elementor-element-52de05d7 elementor-widget elementor-widget-heading" data-id="52de05d7" data-element_type="widget" data-widget_type="heading.default">
													<div className="elementor-widget-container">
														<div className="elementor-heading-title elementor-size-default">As your reliable Financial Partner, we’re here to guide you every step of the way—whether you're investing, planning for retirement, or buying your first home. </div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>


							</div>
						</div>
						<div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-768b900d" data-id="768b900d" data-element_type="column">

							{/* <img src='/images/home/sec-1.png'></img> */}
						</div>
					</div>
				</section>
			</div>

			<div className="body_wrap">



				<div className="content_wrap_fullscreen">
					<div className="content"> <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#" />
						<article id="post-5059"
							className="post_item_single post_type_page post-5059 page type-page status-publish hentry">
							<div className="post_content entry-content">
								<div data-elementor-type="wp-page" data-elementor-id="5059" className="elementor elementor-5059">




									<section
										className="elementor-section elementor-top-section elementor-element elementor-element-1345c3e elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
										data-id="1345c3e" data-element_type="section">
										<div className="elementor-container elementor-column-gap-no">
											<div
												className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c261bb0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="c261bb0" data-element_type="column">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div
														className="elementor-element elementor-element-9e54fd2 sc_fly_static elementor-widget elementor-widget-trx_sc_services trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="9e54fd2" data-element_type="widget" data-widget_type="trx_sc_services.default">
														<div className="elementor-widget-container">
															<div className="sc_services sc_services_qw-stylish sc_services_featured_top">
																<div
																	className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_4 trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4935"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4935 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content " style={{ backgroundImage: `url("/images/services/slider-sec-1.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a
																										href="#/">Invesments & Savings </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="#"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Grow wealth steadily through smart investments. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="services/investing-and-saving" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4936"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4936 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content "
																				style={{ backgroundImage: `url("/images/services/slider-sec-2.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/mortgage-services">Mortgage Services </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/mortgage-services"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Find the right mortgage that fits your dream home plans. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="services/mortgage-services" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4934"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4934 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content "
																				style={{ backgroundImage: `url("/images/services/slider-sec-3.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/home/services/agency-evolution/">Pension & Retirement </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/pension-and-retirement"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Secure your financial future with retirement planning. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/pension-and-retirement" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4080"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4080 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content" style={{ backgroundImage: `url("/images/services/slider-sec-4.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/home/services/maximizing-returns/">Insurance Services </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/insurance-services"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Protect your family with comprehensive insurance. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/insurance-services" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section
										className="elementor-section elementor-top-section elementor-element elementor-element-1345c3e elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
										data-id="1345c3e" data-element_type="section">
										<div className="elementor-container elementor-column-gap-no">
											<div
												className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c261bb0 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
												data-id="c261bb0" data-element_type="column">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div
														className="elementor-element elementor-element-9e54fd2 sc_fly_static elementor-widget elementor-widget-trx_sc_services trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
														data-id="9e54fd2" data-element_type="widget" data-widget_type="trx_sc_services.default">
														<div className="elementor-widget-container">
															<div className="sc_services sc_services_qw-stylish sc_services_featured_top">
																<div
																	className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_4 trx_addons_columns_wrap columns_padding_bottom columns_in_single_row">
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4935"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4935 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content " style={{ backgroundImage: `url("/images/services/slider-sec-5.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a
																										href="/services/wealth-management">Wealth Management</a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/wealth-management"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Grow wealth steadily through smart investments. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/wealth-management" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4936"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4936 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content "
																				style={{ backgroundImage: `url("/images/services/slider-sec-6.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/services/real-estate-finance">Real Estate Finance </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/real-estate-finance"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Find the right mortgage that fits your dream home plans. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/real-estate-finance" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4934"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4934 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content "
																				style={{ backgroundImage: `url("/images/services/slider-sec-7.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/home/services/agency-evolution/">Personal Financial Planning </a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/personal-financial-planning"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Secure your financial future with retirement planning. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/personal-financial-planning" />
																			</div>
																		</div>
																	</div>
																	<div className="trx_addons_column-1_4 trx_addons_column-1_2-tablet ">
																		<div data-post-id="4080"
																			className="sc_services_item sc_item_container post_container with_more with_content with_image sc_services_item_featured_none post-4080 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-what-we-do">
																			<div className="sc_services_item_content" style={{ backgroundImage: `url("/images/services/slider-sec-8.jpg")` }}>
																				<div className="sc_services_item_content_inner">
																					<div className="sc_services_item_content_inner_bottom">
																						<div className="sc_services_item_info_wrap">
																							<div className="sc_services_item_title_wrap">

																								<h4 className="sc_services_item_title">
																									<a href="/home/services/maximizing-returns/"> Financial Risk Advisory</a>
																								</h4>
																							</div>
																							<div className="sc_services_item_button sc_item_button"> <a
																								href="/services/financial-risk-advisory"
																								className="sc_services_item_more_link"> <span className="link_text">Read
																									more</span><span className="link_icon" /> </a>
																							</div>
																						</div>
																						<div className="sc_services_item_text">
																							<p>Protect your family with comprehensive insurance. </p>
																						</div>
																					</div>
																				</div><a className="sc_services_item_link"
																					href="/services/financial-risk-advisory" />
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</section>


								</div>
							</div>

						</article>
					</div>
				</div>


			</div>


			<div id="page" className="hfeed site">


				<div data-elementor-type="wp-post" data-elementor-id="199" className="elementor elementor-199">

					<section className="elementor-section elementor-top-section elementor-element elementor-element-96fe4f3 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="96fe4f3" data-element_type="section">
						<div className="elementor-container elementor-column-gap-no">
							<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ad85143" data-id="ad85143" data-element_type="column">
								<div className="elementor-widget-wrap elementor-element-populated">
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-3c2a120 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="3c2a120" data-element_type="section">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5417f78" data-id="5417f78" data-element_type="column">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-element elementor-element-e9c2610 elementor-widget elementor-widget-heading" data-id="e9c2610" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h2 className="elementor-heading-title elementor-size-default">We Design a Future of Financial Success</h2>		</div>
													</div>
												</div>
											</div>
										</div>
									</section>
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-632dba9 animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="632dba9" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-77a581b" data-id="77a581b" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-57d96e3 elementor-widget elementor-widget-heading" data-id="57d96e3" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Trusted Partner for<br/> Investment Success</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-f392c11 elementor-widget elementor-widget-text-editor" data-id="f392c11" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style32.css" />				<p>We guide you through strategic investments, ensuring your portfolio grows with confidence and expertise. With a focus on maximising returns and minimising risks, we help you make smart choices that set you up for long-term financial success.</p>						</div>
													</div>
													<div className="elementor-element elementor-element-79a0e0c elementor-widget__width-initial elementor-absolute elementor-widget elementor-widget-image" data-id="79a0e0c" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
														<div className="elementor-widget-container">
															{/* <img decoding="async" width="420" height="420" src="/images/services/sec-3-1.png" className="attachment-full size-full wp-image-19378" alt="" data-savepage-srcset="/images/services/sec-3-1.png 420w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/businessman-2021-08-29-13-40-35-utc_isolated-e1685509384462-300x300.png 300w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/businessman-2021-08-29-13-40-35-utc_isolated-e1685509384462-150x150.png 150w" srcset="" sizes="(max-width: 420px) 100vw, 420px" />		*/}
														</div>
													</div>
												</div>
											</div>
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bd3b614" data-id="bd3b614" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />


													<div className="elementor-element elementor-element-f225c8c elementor-widget elementor-widget-spacer" data-id="f225c8c" data-element_type="widget" data-widget_type="spacer.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style33.css" />		<div className="elementor-spacer">
																<div className="elementor-spacer-inner" />
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>
									</section>
									<section className="elementor-section elementor-inner-section elementor-element elementor-element-49dd512 animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="49dd512" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-649e362" data-id="649e362" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-50e1460 elementor-widget elementor-widget-heading" data-id="50e1460" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Learn from Expert Financial Advisors</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-fa6fca5 elementor-widget elementor-widget-text-editor" data-id="fa6fca5" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<p>Gain valuable insights from seasoned professionals who understand the market and help you make informed financial decisions.  </p>						</div>
													</div>

												</div>
											</div>
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-487ea56" data-id="487ea56" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-98ae5f1 elementor-widget elementor-widget-heading" data-id="98ae5f1" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Maximising Value Together with You</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-5dab090 elementor-widget__width-initial elementor-widget elementor-widget-text-editor" data-id="5dab090" data-element_type="widget" data-widget_type="text-editor.default" style={{ width: "100%" }}>
														<div className="elementor-widget-container">
															<p>We focus on delivering unmatched value, helping you reach your financial goals with personalised solutions.</p></div>
													</div>
													<div className="elementor-element elementor-element-3855820 elementor-widget elementor-widget-progress" data-id="3855820" data-element_type="widget" data-widget_type="progress.default">
														<div className="elementor-widget-container">
															<link rel="stylesheet" href="css/style34.css" />
															<div className="elementor-progress-wrapper" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" aria-valuetext="100% (Success metric)">
																<div className="elementor-progress-bar" data-max="100" style={{ width: "100%" }}>
																	<span className="elementor-progress-text">Success Rate</span>
																	<span className="elementor-progress-percentage">100%</span>
																</div>
															</div>
														</div>
													</div>
													<div className="elementor-element elementor-element-ccab2ed elementor-widget elementor-widget-progress" data-id="ccab2ed" data-element_type="widget" data-widget_type="progress.default" style={{ marginTop: "0" }}>
														<div className="elementor-widget-container">

															<div className="elementor-progress-wrapper" role="progressbar" aria-valuemin="0" aria-valuemax="100" aria-valuenow="90" aria-valuetext="90% (Settlement metric)">
																<div className="elementor-progress-bar" data-max="90" style={{ width: "98%" }}>
																	<span className="elementor-progress-text">Client Satisfaction</span>
																	<span className="elementor-progress-percentage">98%</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</section>


									<section className="elementor-section elementor-inner-section elementor-element elementor-element-49dd512 animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp duplicated-zog" data-id="49dd512" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
										<div className="elementor-container elementor-column-gap-no">
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-487ea56 dup-box-1" data-id="487ea56" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />



												</div>
											</div>
											<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-649e362 dup-box-2" data-id="649e362" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-widget-wrap elementor-element-populated">
													<div className="elementor-background-overlay" />
													<div className="elementor-element elementor-element-50e1460 elementor-widget elementor-widget-heading" data-id="50e1460" data-element_type="widget" data-widget_type="heading.default">
														<div className="elementor-widget-container">
															<h4 className="elementor-heading-title elementor-size-default">Simplifying Your Journey to Your Dream Home</h4>		</div>
													</div>
													<div className="elementor-element elementor-element-fa6fca5 elementor-widget elementor-widget-text-editor" data-id="fa6fca5" data-element_type="widget" data-widget_type="text-editor.default">
														<div className="elementor-widget-container">
															<p>
															We make the home-buying process effortless and enjoyable, guiding you every step of the way toward achieving your dream home.</p>						</div>
													</div>

												</div>
											</div>
										</div>
									</section>
								</div>
							</div>
						</div>
					</section>

				</div>



			</div>




			<div className="body_wrap">
				<div className="page_wrap">



					<div className="content_wrap_fullscreen">
						<div className="content"> <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#" />
							<article id="post-5059"
								className="post_item_single post_type_page post-5059 page type-page status-publish hentry">
								<div className="post_content entry-content">
									<div data-elementor-type="wp-page" data-elementor-id="5059" className="elementor elementor-5059">




										<section
											className="elementor-section elementor-top-section elementor-element elementor-element-0522bb3 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
											data-id="0522bb3" data-element_type="section">
											<div className="elementor-container elementor-column-gap-extended">
												<div
													className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-05cd153 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="05cd153" data-element_type="column">
													<div className="elementor-widget-wrap elementor-element-populated">
														<div
															className="elementor-element elementor-element-03bd0a0 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="03bd0a0" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner" />
																</div>
															</div>
														</div>
														<div
															className="elementor-element elementor-element-4879d09 sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="4879d09" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<section
											className="elementor-section elementor-top-section elementor-element elementor-element-6d782fe elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
											data-id="6d782fe" data-element_type="section"
											data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
											<div className="elementor-container elementor-column-gap-extended">
												<div
													className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3ebe655 sc_column_fixed sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="3ebe655" data-element_type="column">
													<div className="elementor-widget-wrap elementor-element-populated">
														<div
															className="elementor-element elementor-element-0e8084b sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="0e8084b" data-element_type="widget" data-widget_type="trx_sc_title.default">
															<div className="elementor-widget-container">
																<div className="sc_title sc_title_default">

																	<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag">
																		<span className="sc_item_title_text">Ready to take the next step? </span>
																	</h1>
																	<div className="sc_item_descr sc_title_descr">
																		<p>Reach out to our team of financial experts today. Whether you need personalised advice or have specific questions, we’re here to guide you towards your financial goals. </p>
																	</div>
																	<div
																		className="sc_item_button sc_button_wrap sc_item_button sc_item_button_default sc_item_button_size_normal sc_title_button">
																		<a href="/contact-us/"
																			className="sc_button sc_button_default sc_button_size_normal sc_button_icon_left">
																			<span className="sc_button_text"><span className="sc_button_title">Talk to an Advisor </span>
																			</span></a></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div
													className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-5ec6dcd sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="5ec6dcd" data-element_type="column">
													<div className="elementor-widget-wrap elementor-element-populated">
														<div
															className="elementor-element elementor-element-aa523df elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="aa523df" data-element_type="widget" data-widget_type="divider.default">
															<div className="elementor-widget-container">
																<div className="elementor-divider">
																	<span className="elementor-divider-separator"> </span>
																</div>
															</div>
														</div>
														<div
															className="elementor-element elementor-element-eaa6e63 sc_fly_static elementor-widget elementor-widget-toggle trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="eaa6e63" data-element_type="widget" data-widget_type="toggle.default">
															<div className="elementor-widget-container">
																<div className="elementor-toggle">
																	<div className="elementor-toggle-item" onClick={() => selectTab(0)}>
																		<h5 id="elementor-tab-title-2461" className="elementor-tab-title" data-tab="1"
																			role="button" aria-controls="elementor-tab-content-2461" aria-expanded="false">
																			<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																				<span className="elementor-toggle-icon-closed"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					xmlnsXlink="http://www.w3.org/1999/xlink"
																					x="0px"
																					y="0px"
																					viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																					/>
																				</svg>

																				</span> <span className="elementor-toggle-icon-opened"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px" viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																				</svg></span>
																			</span>
																			<a className="elementor-toggle-title" tabindex="0">What financial services do you offer? </a>
																		</h5>
																		<div id="elementor-tab-content-2461" className="elementor-tab-content elementor-clearfix"
																			data-tab="1" role="region" aria-labelledby="elementor-tab-title-2461" style={tabSelected === 0 ? { display: "block" } : {}}>We offer a range of financial services, including investment management, mortgage advisory, pension planning, retirement advice, and comprehensive insurance solutions. </div>
																	</div>
																	<div className="elementor-toggle-item" onClick={() => selectTab(1)}>
																		<h5 id="elementor-tab-title-2462" className="elementor-tab-title" data-tab="2"
																			role="button" aria-controls="elementor-tab-content-2462" aria-expanded="false">
																			<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																				<span className="elementor-toggle-icon-closed"><svg xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px"
																					viewBox="0 0 14.6 14.5" style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z" />
																				</svg>
																				</span> <span className="elementor-toggle-icon-opened"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px" viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																				</svg></span>
																			</span>
																			<a className="elementor-toggle-title" tabindex="0">How do I get started with your financial services? </a>
																		</h5>
																		<div id="elementor-tab-content-2462" className="elementor-tab-content elementor-clearfix"
																			data-tab="2" role="region" aria-labelledby="elementor-tab-title-2462" style={tabSelected === 1 ? { display: "block" } : {}}>Getting started is easy! Contact us through our website or give us a call, and we’ll set up a consultation to understand your needs and tailor a financial plan for you. </div>
																	</div>
																	<div className="elementor-toggle-item" onClick={() => selectTab(2)}>
																		<h5 id="elementor-tab-title-2463" className="elementor-tab-title" data-tab="3"
																			role="button" aria-controls="elementor-tab-content-2463" aria-expanded="false">
																			<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																				<span className="elementor-toggle-icon-closed"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					xmlnsXlink="http://www.w3.org/1999/xlink"
																					x="0px"
																					y="0px"
																					viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																					/>
																				</svg>

																				</span> <span className="elementor-toggle-icon-opened"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px" viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																				</svg></span>
																			</span>
																			<a className="elementor-toggle-title" tabindex="0">Can I customise the financial services to fit my needs? </a>
																		</h5>
																		<div id="elementor-tab-content-2463" className="elementor-tab-content elementor-clearfix"
																			data-tab="3" role="region" aria-labelledby="elementor-tab-title-2463" style={tabSelected === 2 ? { display: "block" } : {}}>Yes, all our services are fully customisable. We work closely with you to create a personalised plan that aligns with your financial goals and future aspirations. </div>
																	</div>
																	<div className="elementor-toggle-item" onClick={() => selectTab(3)}>
																		<h5 id="elementor-tab-title-2464" className="elementor-tab-title" data-tab="4"
																			role="button" aria-controls="elementor-tab-content-2464" aria-expanded="false">
																			<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																				<span className="elementor-toggle-icon-closed"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					xmlnsXlink="http://www.w3.org/1999/xlink"
																					x="0px"
																					y="0px"
																					viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																					/>
																				</svg>

																				</span> <span className="elementor-toggle-icon-opened"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px" viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																				</svg></span>
																			</span>
																			<a className="elementor-toggle-title" tabindex="0">What makes your financial services unique? </a>
																		</h5>
																		<div id="elementor-tab-content-2464" className="elementor-tab-content elementor-clearfix"
																			data-tab="4" role="region" aria-labelledby="elementor-tab-title-2464" style={tabSelected === 3 ? { display: "block" } : {}}>We offer expert advice backed by years of experience, focusing on personalising financial strategies for each client. Our team prioritises long-term growth, stability, and customer satisfaction. </div>
																	</div>
																	<div className="elementor-toggle-item" onClick={() => selectTab(4)}>
																		<h5 id="elementor-tab-title-2465" className="elementor-tab-title" data-tab="5"
																			role="button" aria-controls="elementor-tab-content-2465" aria-expanded="false">
																			<span className="elementor-toggle-icon elementor-toggle-icon-right" aria-hidden="true">
																				<span className="elementor-toggle-icon-closed"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					xmlnsXlink="http://www.w3.org/1999/xlink"
																					x="0px"
																					y="0px"
																					viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M12.2,7.3l-7.1,7.1c-0.3,0.3-0.8,0.3-1.1,0c0,0,0,0,0,0c-0.3-0.3-0.3-0.8,0-1.1 c0,0,0,0,0,0l6-6l-6-6C3.8,0.9,3.8,0.5,4,0.2c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0L12.2,7.3z"
																					/>
																				</svg>

																				</span> <span className="elementor-toggle-icon-opened"><svg
																					xmlns="http://www.w3.org/2000/svg"
																					x="0px" y="0px" viewBox="0 0 14.6 14.5"
																					style={{ enableBackground: "new 0 0 14.6 14.5", xmlSpace: "preserve" }}
																				>
																					<path
																						d="M7.3,11.6L0.2,4.5c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.3-0.3,0.8-0.3,1.1,0 c0,0,0,0,0,0l6,6l6-6c0.3-0.3,0.8-0.3,1.1,0c0,0,0,0,0,0c0.3,0.3,0.3,0.8,0,1.1c0,0,0,0,0,0L7.3,11.6z" />
																				</svg></span>
																			</span>
																			<a className="elementor-toggle-title" tabindex="0">How secure is my financial information with your company? </a>
																		</h5>
																		<div id="elementor-tab-content-2465" className="elementor-tab-content elementor-clearfix"
																			data-tab="5" role="region" aria-labelledby="elementor-tab-title-2465" style={tabSelected === 4 ? { display: "block" } : {}}>We take data privacy very seriously. All your financial information is protected through advanced security protocols, ensuring your details remain confidential and secure at all times. </div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>
										<section
											className="elementor-section elementor-top-section elementor-element elementor-element-9ef1fd0 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
											data-id="9ef1fd0" data-element_type="section">
											<div className="elementor-container elementor-column-gap-extended">
												<div
													className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c654246 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
													data-id="c654246" data-element_type="column">
													<div className="elementor-widget-wrap elementor-element-populated">
														<div
															className="elementor-element elementor-element-459e023 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="459e023" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner" />
																</div>
															</div>
														</div>
														<div
															className="elementor-element elementor-element-24b3134 sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_layouts_hide_on_notebook sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited"
															data-id="24b3134" data-element_type="widget" data-widget_type="spacer.default">
															<div className="elementor-widget-container">
																<div className="elementor-spacer">
																	<div className="elementor-spacer-inner" />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</section>



									</div>
								</div>

							</article>
						</div>
					</div>

				</div>
			</div>







		</div>

	);
}

export default TesterPage