import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import API from '../../API';
import ErrorModal from "../../ErrorModal";
import PositiveModal from "../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"
import $ from 'jquery';
import ReCAPTCHA from 'react-google-recaptcha'

import './Authentication.scss'
import AuthBanner from "./AuthBanner";
import MessagePopup from "./MessagePopup";
import PhoneInputField from "./CustomPhone/CustomPhoneInput";
<script src="https://accounts.google.com/gsi/client" async defer></script>

const View = () => {

  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    country_code: "",
    email: window.localStorage.getItem("emailForSignup") ? window.localStorage.getItem("emailForSignup") : "",
    username: "",
    password: "",
    confirmPassword: "",
    country: ""
  });

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [passwordShown1, showPassword1] = useState(false);
  const [passwordShown2, showPassword2] = useState(false);


  const [errors, setErrors] = useState({});

  const [tnc, setTNC] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const handlePhoneChange = (value, country) => {
    setFormData({
      ...formData,
      phone_number: value,
      country_code: country.dialCode
    });
  };

  const countries = ['United Kingdom', 'United States', 'India']
  const handleCountryChange = (value) => {
    setFormData({
      ...formData,
      country: value,
    });
  };



  const validatePhoneNumber = (phoneNumber, countryCode) => {
    try {
      const parsedPhoneNumber = isValidPhoneNumber(`+${phoneNumber}`, countryCode);
      return parsedPhoneNumber ? null : 'Invalid phone number';
    } catch (error) {
      return 'Invalid phone number';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form fields
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);
    console.log(formData);
    if (Object.keys(validationErrors).length === 0) {
      // Perform the API call
      setIsLoading(true)
      const updatedFormData = { ...formData, phone_number: formData.phone_number, country_code: `${formData.country_code}` };
      // console.log(updatedFormData);

      try {
        // Replace the following code with your actual API logic
        const response = await API.post("/user/register/", updatedFormData);
        // Handle the API response as needed
        setIsLoading(false)
        setMessage("Complete the registration process by verifying your email address.");
        navigate("/confirm-mail")
        // setIsMessageModalOpen(true)

        window.localStorage.removeItem("emailForSignup")
      } catch (error) {
        // Handle API errors
        setIsLoading(false)
        console.log(error)
        let errorMessage = "An error occurred";
        if (error.response && error.response.data && error.response.data.length > 0) {
          errorMessage = error.response.data[0];
        } else if (error.message) {
          errorMessage = error.message;
        }

        setMessage(errorMessage);
        setIsErrorModalOpen(true)
      }
    }
  };

  const validateForm = (data) => {
    const errors = {};

    // Validate each field and add errors if necessary
    if (!data.first_name.trim()) {
      errors.first_name = "First name is required.";
    }

    if (!data.last_name.trim()) {
      errors.last_name = "Last name is required.";
    }
    // if (!data.country.trim()) {
    //   errors.country = "Select country.";
    // }

    if (!data.phone_number.trim()) {
      errors.phone_number = "Phone number is required.";
    }
    // else if (!/^\d{10}$/.test(data.phone_number)) {
    //   errors.phone_number = "Phone number must be 10 digits.";
    // }
    else if (validatePhoneNumber(data.phone_number, data.country_code)) {
      errors.phone_number = 'Invalid phone number'
    }

    if (!data.email.trim()) {
      errors.email = "Email is required.";
    }
    else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Invalid email address.";
    }



    if (!data.password.trim()) {
      errors.password = "Password is required.";
    }
    else if (data.password.length < 12) {
      errors.password = "Password must be at least 12 characters.";
    }
    else if (data.password.length > 127) {
      errors.password = "Maximum allowed Password length is 127 characters.";
    }
    else {
      // Password must contain at least one letter, one number, and one special character
      if (!/[A-Z]/.test(data.password)) {
        errors.password = "Password must contain at least one uppercase letter.";
      } else if (!/\d/.test(data.password)) {
        errors.password = "Password must contain at least one number.";
      } else if (!/[!@#$%^&*]/.test(data.password)) {
        errors.password = "Password must contain at least one special character (!@#$%^&*).";
      }
    }


    if (!data.confirmPassword.trim()) {
      errors.password = "Confirm password is required.";
    } else if (data.password !== data.confirmPassword) {
      errors.password = "Passwords do not match.";
    }
    // if(!tnc){
    //   errors.tnc = "Please accept our Terms & Conditions to proceed.";
    // }
    return errors;
  };


  const onGoogleLoginSuccess = (response) => {
    console.log(response);
  };
  const onGoogleLoginError = (error) => {
    console.log(error);
  };

  // Country
  const [countryListOpened, toggleCountryList] = useState(false)
  useEffect(() => {
    const $OptionDropList = $("#country .option-list");
    if (countryListOpened) {
      const height = $OptionDropList[0].scrollHeight;
      $OptionDropList.animate({ height: height + "px" }, 400);
      $OptionDropList.css("opacity", "1");
    } else {
      $OptionDropList.animate({ height: "0px" }, 400);
      $OptionDropList.css("opacity", "0");
    }
  }, [countryListOpened]);

  const handleSocialSignIn = (provider) => {
    setIsLoading(true);
    API.get(`/social_auth/${provider}/redirect`)
      .then(response => {
        setIsLoading(false);
        // window.open(response.data.redirect_url, "authPopup", 'width=600,height=600');
        window.location.href = response.data.redirect_url;
      })
      .catch(error => {
        setIsLoading(false);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  };

  const onCaptchaChange = (token) => {
    setRecaptchaToken(token);
    API.post(`/user/verify_recaptcha/`, { recaptcha_token: token })
      .then(response => {
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
      });
  }


  return (
    <div>

      <section className="login-hero-section section-b-space  d-flex justify-content-center align-items-center ">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-10 m-auto">
              <div className="login-data">
                <form className="auth-form" onSubmit={handleSubmit} noValidate>
                  <h2>Sign up</h2>
                  <h5>
                    or
                    <span onClick={() => navigate('/login')} style={{ cursor: "pointer" }}><span className="theme-color"> Login to your account</span></span>
                  </h5>
                  <div className="d-lg-flex gap-2">
                    <div className="form-input">
                      <input type="text" name="first_name" className="form-control" placeholder="First Name" value={formData.first_name} onChange={handleChange} />
                      <i className="ri-user-3-line"></i>
                    </div>
                    <div className="form-input">
                      <input type="text" className="form-control" placeholder="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} />
                      <i className="ri-user-3-line"></i>
                    </div>
                  </div>
                  {errors.first_name && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.first_name}</div>}
                  {/* {errors.last_name && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.last_name}</div>} */}
                  <div className="form-input">
                    <input type="text" className="form-control" placeholder="Email" name="email" value={formData.email} onChange={handleChange} />
                    <i className="ri-mail-line"></i>
                  </div>
                  {errors.email && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.email}</div>}

                  <div className="form-input">
                    <PhoneInputField formData={formData} setFormData={setFormData} />
                  </div>

                  {errors.phone_number && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.phone_number}</div>}

                  {/* <div className="form-input">
                    <input type="tel" className="form-control" placeholder="Phone Number" name="phone_number" value={formData.phone_number} onChange={handleChange} />
                    <i className="ri-phone-line"></i>
                  </div>
                  {errors.phone_number && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.phone_number}</div>} */}

                  <div className="form-input">
                    <input type={passwordShown1 ? "text" : "password"} className="form-control" placeholder="Password"
                      name="password" value={formData.password} onChange={handleChange} />
                    <i className="ri-lock-password-line"></i>
                    <span className="password-eye" onClick={() => showPassword1(!passwordShown1)}>
                      {passwordShown1 ?

                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        :


                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1777_2198)">
                            <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1777_2198">
                              <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                            </clipPath>
                          </defs>
                        </svg>
                      }

                    </span>
                  </div>
                  <div className="form-input">
                    <input type={passwordShown2 ? "text" : "password"} className="form-control" placeholder="Confirm Password"
                      name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
                    <i className="ri-lock-password-line"></i>
                    <span className="password-eye" onClick={() => showPassword2(!passwordShown2)}>
                      {passwordShown2 ?

                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        :


                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1777_2198)">
                            <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1777_2198">
                              <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                            </clipPath>
                          </defs>
                        </svg>
                      }

                    </span>
                  </div>
                  {errors.password && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.password}</div>}
                  {/* <a href="otp.html" className="btn theme-btn submit-btn w-100 rounded-2">CONTINUE</a> */}

                  {/* ReCaptcha */}
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onCaptchaChange}
                  />

                  <button type="submit" disabled={!recaptchaToken} className="btn theme-btn submit-btn w-100 rounded-2">REGISTER</button>
                  <p className="fw-normal content-color">
                    By creating an account, I accept the
                    <span className="fw-semibold">
                      &nbsp;Terms & Conditions & Privacy Policy</span>
                  </p>
                </form>
              </div>
              <div className='social-login'>
                <div className="other-log-in">
                  <h6>or</h6>
                </div>
                <div className="log-in-button">
                  <ul>
                    <li>
                      <button className="btn google-button w-100" type="button" onClick={() => handleSocialSignIn('google')}>
                        <img src="/images/google.png" className="blur-up lazyload" alt="" /> Log In with Google
                      </button>
                    </li>
                    <li>
                      <button href="https://www.facebook.com/" className="btn google-button w-100" type="button" onClick={() => handleSocialSignIn('facebook')}>
                        <img src="/images/facebook.png" className="blur-up lazyload" alt="" /> Log In with Facebook
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
      {isMessageModalOpen && <PositiveModal title={'Success'} message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => navigate('/login')} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>

  )

}

export default View

