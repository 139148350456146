// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-list-page .filters .switch-xsm .slider::before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/UsersList/UsersList.scss"],"names":[],"mappings":"AAEM;EACG,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;AADT","sourcesContent":[".user-list-page {\r\n   .filters{\r\n      .switch-xsm .slider::before {\r\n         position: absolute;\r\n         content: \"\";\r\n         height: 19px;\r\n         width: 19px;\r\n         left: 4px;\r\n         bottom: 4px;\r\n         background-color: #fff;\r\n         transition: 0.4s;\r\n      }\r\n   }\r\n\r\n   table {\r\n      // .user-name{\r\n      //     text-transform: none;\r\n      // }\r\n   }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
