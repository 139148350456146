// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-list-page .filters .switch-xsm .slider::before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}
.user-list-page table tbody tr td ul {
  gap: 5px !important;
}
.user-list-page table .status-btn {
  padding: 4px 6px;
  margin-right: 7px;
  background-color: rgb(255, 206, 206);
  color: #ab0000;
  border: 1px rgb(255, 178, 178) solid;
  border-radius: 15px;
  font-size: calc(8px + 4 * (100vw - 320px) / 1600);
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/UserDeletionList/UserDeletionList.scss"],"names":[],"mappings":"AAEM;EACG,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,gBAAA;AADT;AAMM;EACG,mBAAA;AAJT;AAMM;EACG,gBAAA;EACA,iBAAA;EACA,oCAAA;EACA,cAAA;EACA,oCAAA;EACA,mBAAA;EACA,iDAAA;AAJT","sourcesContent":[".user-list-page {\r\n   .filters{\r\n      .switch-xsm .slider::before {\r\n         position: absolute;\r\n         content: \"\";\r\n         height: 19px;\r\n         width: 19px;\r\n         left: 4px;\r\n         bottom: 4px;\r\n         background-color: #fff;\r\n         transition: 0.4s;\r\n      }\r\n   }\r\n\r\n   table {\r\n      tbody tr td ul{\r\n         gap: 5px !important;\r\n      }\r\n      .status-btn{\r\n         padding: 4px 6px;\r\n         margin-right: 7px;\r\n         background-color: rgb(255, 206, 206);\r\n         color: #ab0000;\r\n         border: 1px rgb(255, 178, 178) solid;\r\n         border-radius: 15px;\r\n         font-size: calc(8px + 4 * (100vw - 320px) / 1600);\r\n      }\r\n   }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
