import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDateTimeToMonthYear } from '../../../GeneralFunctions'
import API from '../../../API';
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import './BlogDetail.scss'
import { getFontSizeFactor, renderDescription } from '../../../admin-panel/common-functions/commonFunctions.js';
import $ from 'jquery';


const BlogDetail = ({ blogId }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [postId, setPostId] = useState(null);

  useEffect(() => {    
    // Get the postId from localStorage
    const selectedPostId = localStorage.getItem('itemSelectedId');
    if (selectedPostId) {
      setPostId(selectedPostId); // Set the postId state
    } else {
      // If no post is selected, navigate back to the blog list page
      navigate('/blogs');
    }
    $(function () {
      $(window).scrollTop(0);
    });

  }, [blogId]);

  const loadData = () => {
    if (postId) {
      let apiUrl = `/communication/blogs/${postId}`;
      setIsLoading(true)
      API.get(apiUrl)
        .then(response => {
          setData(response.data)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
        });
    }
  }
  useEffect(() => {
    loadData();
  }, [postId]);

  return (
    <div className='blog-detail-section'>
      <div className="blog-wrap">

        <div className="blog-box blog-detail ratio_40">
          <div className="img-box">
            <img className="bg-img w-100" src={data.image} alt="blog"></img>
          </div>
          <div className="content-box">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <h5 className="blog-title">
                {data.title}
              </h5>
              <span className="content-color">{data.published_on && formatDateTimeToMonthYear(data.published_on)}</span>
            </div>
            {data.description &&
              <p className='content' dangerouslySetInnerHTML={{ __html: renderDescription(data.description, getFontSizeFactor()) }}></p>
            }
            <div className="share-box">
              <div className="sender-box">
                <div className="avatar-img">
                  <img className="img-fluid img" src={"/images/no-profile-image.png"} alt="post"></img>
                </div>
                <div className="sender-details">
                  <h5>by {data.author}</h5>
                </div>
              </div>
              <div className="action-box d-flex align-items-center gap-sm-3 gap-1 d-none">
                <span className="like d-flex gap-1">
                  <i className="ri-thumb-up-line like-btn"></i>125
                </span>
                <span className="like d-flex gap-1">
                  <i className="ri-thumb-down-line like-btn"></i>525
                </span>
                <a href="#" className="btn btn-sm theme-btn">Reply</a>
              </div>
            </div>
          </div>
        </div>


      </div>

      {isLoading && <FixedOverlayLoadingSpinner />}


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />

    </div>
  )
}

export default BlogDetail