import React, { useEffect } from 'react'
import './PrivacyPolicy.scss'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    // header scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Check if scroll position is at 100vh
            if (scrollPosition >= 5) {
                $(".header").addClass("shrinked");
            } else {
                $(".header").removeClass("shrinked");

            }
        }
        window.addEventListener('scroll', handleScroll);
        // Clean up event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className=' privacy-policy'>

            <div className="body_wrap">
                <div className="page_wrap">

                    <header className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
                        <div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0ee19b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"> <div className="elementor-background-overlay"></div>
                                <div className="elementor-container elementor-column-gap-extended">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d2ebbc2" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">

                                           
                                            <div className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d67700c" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f56af19" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1970e32" data-element_type="widget" data-widget_type="trx_sc_layouts_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                                                                        <div className="sc_layouts_title_content">
                                                                            <div className="sc_layouts_title_title">
                                                                                <h1 className="sc_layouts_title_caption">Privacy Policy</h1>
                                                                            </div>
                                                                            <div className="sc_layouts_title_breadcrumbs">
                                                                                <div className="breadcrumbs"><a className="breadcrumbs_item home underline_hover" href="https://zurich.axiomthemes.com/">Home</a><span className="breadcrumbs_delimiter"></span><span className="breadcrumbs_item current">Privacy Policy</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div className="page_content_wrap">
                        <div className="content_wrap_fullscreen">
                            <div className="content">
                                <article id="post-211" className="post_item_single post_type_page post-211 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div data-elementor-type="wp-page" data-elementor-id="211" className="elementor elementor-211">

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <p>At Reserve Financial Services, your privacy is of paramount importance to us. We are committed to safeguarding the personal information you share with us and ensuring it is handled in compliance with UK data protection laws, including the General Data Protection Regulation (GDPR). This Privacy Policy explains how we collect, use, and protect your personal data when you use our services. We only gather and use personal information as described here, in full compliance with our legal obligations and your rights.
                                                                        <br />
                                                                        <br />
                                                                        Please take the time to read this Privacy Policy thoroughly. By continuing to use our website (www.reservefinancialservices.com), you agree to the terms set out here. If you do not agree with this policy, kindly stop using our site immediately. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>1. Who We Are</h5>
                                                                    <p>This site is owned and operated by Reserve Financial Services Ltd registered in England and Wales No. 06582775.

                                                                        Registered Office: Suite V3, 4 Woodland Road, DL3 7PJ, Darlington, Co Durham

                                                                        We are authorised and regulated by Financial Conduct Authority under number 618272.

                                                                        We provide a range of financial services, including: </p>
                                                                    <ul>
                                                                        <li>Investment & Savings</li>
                                                                        <li>Mortgage Services</li>
                                                                        <li>Pension & Retirement</li>
                                                                        <li>Insurance Services</li>
                                                                        <li>Wealth Management</li>
                                                                        <li>Real Estate Finance</li>
                                                                        <li>Personal Financial Planning</li>
                                                                        <li>Financial Risk Advisory</li>
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>2. What Does This Policy Cover? </h5>
                                                                    <p>This Privacy Policy applies solely to your use of our website and the services we provide through it. It does not extend to any external websites that may be accessible via links on our website, including social media platforms or third-party sites. 

Please note, once you leave our website by clicking on external links, we no longer have control over how your data is collected, stored, or used by other websites. We strongly recommend reviewing the privacy policies of any such sites before sharing any personal data with them. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>3. What Information Do We Collect? </h5>
                                                                    <p>Depending on how you interact with our website, we may collect various types of personal and non-personal data, including but not limited to the following (please refer to our cookie policy for details on our use of cookies and similar technologies): </p>

                                                                    <ul>
  <li>Name</li>
  <li>Email address</li>
  <li>Phone number</li>
  <li>IP address</li>
  <li>Type and version of web browser</li>
  <li>Operating system</li>
</ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>4. How Do We Use Your Information? </h5>
                                                                    <p>We use your personal data to: </p>

                                                                    <ul>
  <li>Provide our financial services and manage your relationship with us</li>
  <li>Assess your financial needs and recommend appropriate services</li>
  <li>Ensure compliance with legal and regulatory obligations</li>
  <li>Improve our services and customer experience</li>
  <li>Contact you about relevant products, services, and updates (with your consent)</li>
</ul>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>5. Lawful Basis for Processing </h5>
                                                                    <p>We process your data based on one or more of the following legal grounds: </p>
                                                                    <ul>
  <li><strong>Contractual necessity:</strong> To provide our services to you and fulfil our contract.</li>
  <li><strong>Legitimate interest:</strong> To operate our business effectively, improve our services, and manage our relationship with you.</li>
  <li><strong>Consent:</strong> Where required, we will seek your explicit consent before processing your data (e.g., for marketing purposes).</li>
  <li><strong>Legal obligation:</strong> To comply with applicable laws and regulations.</li>
</ul>
 
                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                   <h5>6. Who Do We Share Your Data With?</h5>
                                                                    <p>We do not share your personal data with third parties for marketing or promotional purposes. 

If you engage with our services, we may share your personal data with: </p>
<ul>
  <li>Product providers</li>
  <li>
    Third parties who assist us in addressing your enquiry or application, or who support your needs, such as compliance advisers and service providers. These will only be shared based on your specific circumstances.
  </li>
</ul>

                                                                    <p>Your data will be shared solely for the purposes outlined in this Privacy Policy to ensure we can deliver our services to you. 

Please note that sharing your personal data with these third parties does not grant them permission to send you marketing or promotional material. It is shared strictly to fulfil our obligations to you as outlined in our Privacy Notice. 

We do not anticipate transferring your data outside the European Economic Area (EEA). However, should this occur, we will ensure your data is protected to the same standard as within the UK and EEA. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>7. How Long Do We Retain Your Data? </h5>
                                                                    <p>We will retain your personal data for as long as necessary to fulfil the purposes outlined in this policy or as required by law. This includes retaining information to comply with legal, regulatory, tax, accounting, or reporting requirements. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>8. Your Data Protection Rights </h5>
                                                                 
                                                                    <p>Under GDPR, you have the following rights regarding your personal data: </p>
                                                                    <ul>
  <li><strong>Access:</strong> You can request a copy of the personal data we hold about you.</li>
  <li><strong>Rectification:</strong> You have the right to request corrections to any inaccurate or incomplete data.</li>
  <li><strong>Erasure:</strong> You may request that we delete your data, subject to certain legal exceptions.</li>
  <li><strong>Restriction:</strong> You can request that we limit the processing of your data under specific circumstances.</li>
  <li><strong>Portability:</strong> You can request a copy of your data in a structured, machine-readable format.</li>
  <li><strong>Objection:</strong> You have the right to object to the processing of your data in certain situations, including for direct marketing purposes.</li>
</ul>

<p>To exercise any of these rights, please contact us at <a>support@reservefinancialservices.com. </a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>9. Data Security </h5>
                                                                    <p>We take the security of your personal data seriously. We have implemented appropriate technical and organisational measures to protect your data from unauthorised access, alteration, disclosure, or destruction. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>10. Marketing Preferences </h5>
                                                                    <p>If you have opted to receive marketing communications from us, you can change your preferences or opt-out at any time by contacting us at support@reservefinancialservices.com or using the unsubscribe link in our emails. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>10. Cookies and Website Usage </h5>
                                                                    <p>We use cookies and similar tracking technologies to enhance your experience on our website. You can control cookies through your browser settings. For more information, please refer to our Cookie Policy. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>11. Changes to This Privacy Policy </h5>
                                                                    <p>We may update this Privacy Policy from time to time. We encourage you to review this page regularly for any updates. The latest version will always be available on our website. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>12. Contact Us </h5>
                                                                    <p>If you have any questions, concerns, or complaints about this Privacy Policy or how we handle your personal data, please contact us at: 
                                                                
                                                                    <br/>                                   
                                                                    <br/>                                   
                                                                    Reserve Financial Services 
                                                                    <br/>                                   
                                                                    Suite V3, 4 Woodland Road, DL3 7PJ, Darlington, Co Durham 
                                                                    <br/>                                   
                                                                    02045424350 
                                                                    <br/>                                   
                                                                    queries@reservefinancialservices.com 

                                                                    <br/>                                   
                                                                    <br/>                                   
                             If you are not satisfied with our response, or believe we are processing your personal data unlawfully, you have the right to lodge a complaint with the UK’s data protection regulator, the Information Commissioner’s Office (ICO). For more information, visit their website at www.ico.org.uk.</p>
 </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>



                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default PrivacyPolicy