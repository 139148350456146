import React, { Component, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Authentication.scss';
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import $ from 'jquery';
// import { GoogleLogin } from '@react-oauth/google';
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"

import API from '../../API';

import { useContext } from 'react';
import { UserContext } from './UserContext';

import ErrorModal from "../../ErrorModal";
import AuthBanner from './AuthBanner';
import ReCAPTCHA from 'react-google-recaptcha'

<script src="https://accounts.google.com/gsi/client" async defer></script>




const Login = () => {
  const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const navigate = useNavigate();
  const { login, isOTPInputShown, showOTPInput, otpTimer, otpTimeout, setRedirectUrl } = useContext(UserContext);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

 
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  const [formData, setFormData] = useState({
    username_or_email: "",
    password: "",
  });

  const [passwordShown, showPassword] = useState(false);


  const [errors, setErrors] = useState({});

  const [OTP, setOTP] = useState([null, null, null, null, null, null])


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isOTPInputShown) {  // for login button
      // Validate the form fields
      const validationErrors = validateForm(formData);
      setErrors(validationErrors);
      // console.log(formData);
      // return;
      if (Object.keys(validationErrors).length === 0) {

        login(formData); // Call the login function from UserContext

      }
    }
    else {   //for OTP SUBMIT BUTTON.
      if (OTP[0] && OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5]) {
        setErrors({})
        login(

          {
            "user_id": window.localStorage.getItem("userID"),
            "otp": `${OTP[0]}${OTP[1]}${OTP[2]}${OTP[3]}${OTP[4]}${OTP[5]}`
          }
        )
      }
      else {
        setErrors({ otp: "Please enter 6 digits" })
      }
    }

  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.username_or_email.trim()) {
      errors.username_or_email = "username or email is required.";
    }
    else if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(data.username)) {
      errors.general = "Invalid username or password" //Username must start with a letter and can only contain letters and numbers. Error details are not showing to user because security reason
    }
    else if (/^\d+$/.test(data.username_or_email)) {
      errors.general = "Invalid username or password" //username or email cannot be full of numbers.  Error details are not showing to user because security reason
    }

    if (!data.password.trim()) {
      errors.password = "Password is required.";
    }

    return errors;
  };






  const handleOTPInputChange = (e, currentIndex) => {
    console.log("e", e)
    if (currentIndex < 6) {
      // Handle backspace
      if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {

        let temp = [...OTP];
        temp[currentIndex] = null;
        setOTP(temp);

        // Move to the previous input
        if (currentIndex > 0) {
          document.getElementById(`otp${currentIndex - 1}`).focus();
        }
      } else if (/[0-9]/.test(e.target.value)) {
        // Update the current input value
        const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
        let temp = [...OTP];
        temp[currentIndex] = inputValue;
        setOTP(temp);

        // Move to the next input
        if (currentIndex < 5) {
          document.getElementById(`otp${currentIndex + 1}`).focus();
        }
      }
    }
  };




  const onGoogleLoginSuccess = (response) => {
    console.log(response.credential);

    API.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.credential}`, {
      headers: {
        Authorization: `Bearer ${response.credential}`,
        Accept: 'application/json'
      }
    })
      .then((res) => {
        console.log("google user basic details", res.data);
      })
      .catch((err) => console.log("error ongoogle user basic details", err));

  };
  const onGoogleLoginError = (error) => {
    console.log(error);
  };

  const handleSocialSignIn = (provider) => {
    setIsLoading(true);
    API.get(`/social_auth/${provider}/redirect`)
      .then(response => {
        setIsLoading(false);
        // window.open(response.data.redirect_url, "authPopup", 'width=600,height=600');
        window.location.href = response.data.redirect_url;
        
      })
      .catch(error => {
        setIsLoading(false);
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  };


  const onCaptchaChange = (token) => {
    setRecaptchaToken(token);
    API.post(`/user/verify_recaptcha/`, { recaptcha_token: token })
      .then(response => {
      })
      .catch(error => {
        setMessage(error.response ? error.response.data.message : error.message);
        setIsErrorModalOpen(true);
      });
  }


  return (
    <div >

      <section className="login-hero-section section-b-space d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-md-10 m-auto">
              <div className="login-data">
                <form className="auth-form " onSubmit={handleSubmit} noValidate>
                  <h2>Sign in</h2>
                  <h5>
                    or
                    <span onClick={() => navigate('/sign-up')} style={{ cursor: "pointer" }}><span className="theme-color"> Create an account</span></span>
                  </h5>
                  <div className="form-input">
                    <input type="tel" className="form-control" placeholder="Username or Email" name="username_or_email"
                      value={formData.username_or_email} onChange={handleChange} disabled={isOTPInputShown} />
                    <i className="ri-user-3-line"></i>
                  </div>
                  {errors.username_or_email && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.username_or_email}</div>}
                  <div className="form-input">
                    <input type={passwordShown ? "text" : "password"} className="form-control" placeholder="Password" name='password'
                      value={formData.password} onChange={handleChange} disabled={isOTPInputShown} />
                    <i className="ri-lock-password-line"></i>
                    <span className="password-eye" onClick={() => showPassword(!passwordShown)}>
                      {passwordShown ?

                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        :


                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_1777_2198)">
                            <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </g>
                          <defs>
                            <clipPath id="clip0_1777_2198">
                              <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                            </clipPath>
                          </defs>
                        </svg>
                      }

                    </span>

                  </div>
                  {errors.password && <div className="invalid-feedback m-0 mb-1 position-relative">{errors.password}</div>}
                  {/* <p className="fw-normal content-color">
                    By creating an account, I accept the
                    <span className="fw-semibold">
                    Terms & Conditions & Privacy Policy</span>
                    </p> */}
                  {/* ReCaptcha */}
                  <ReCAPTCHA
                    sitekey={RECAPTCHA_SITE_KEY}
                    onChange={onCaptchaChange}
                  />

                  <Link to="/forgot-password" className='theme-color'>Forgot your password?</Link>

                  {!isOTPInputShown &&
                    <button type="submit" disabled={!recaptchaToken} className="btn theme-btn submit-btn w-100 rounded-2">LOGIN</button>
                  }
                  {isOTPInputShown && (
                    <div className='otp-form'>
                      <label htmlFor="otp" className="form-label mb-0">
                        OTP
                      </label>

                      <div className="otp-box d-flex gap-sm-3 gap-2">
                        {[0, 1, 2, 3, 4, 5].map((index) => (
                          <div className="form-input mb-0" key={index}>
                            <input
                              type="number"
                              className={`form-control ${index === 0 ? 'active' : ''}`}
                              id={`otp${index}`}
                              name={`otp${index}`}
                              value={OTP[index] || ''}
                              onChange={(e) => handleOTPInputChange(e, index)}

                            />
                          </div>
                        ))}
                      </div>
                      {errors.otp && <div className="text-danger">{errors.otp}</div>}
                      {otpTimeout ? <p className="otp-timer mt-2 expired">OTP expired! <span className="resend" onClick={() => showOTPInput(false)}>Resend OTP</span></p> : <div className="otp-timer mt-2">OTP will expire within {Math.floor(otpTimer / 60)}:{(otpTimer % 60) < 10 && "0"}{(otpTimer % 60)} min.</div>}
                      <button className="btn theme-btn submit-btn w-100 rounded-2">SUBMIT OTP</button>
                    </div>
                  )}
                </form>
              </div>

              <div className='social-login'>
                <div className="other-log-in">
                  <h6>or</h6>
                </div>
                <div className="log-in-button">
                  <ul>
                    <li>
                      <button className="btn google-button w-100" type="button" onClick={() => handleSocialSignIn('google')}>
                        <img src="/images/google.png" className="blur-up lazyload" alt="" /> Log In with Google
                      </button>
                    </li>
                    <li>
                      <button href="https://www.facebook.com/" className="btn google-button w-100" type="button" onClick={() => handleSocialSignIn('facebook')}>
                        <img src="/images/facebook.png" className="blur-up lazyload" alt="" /> Log In with Facebook
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <ErrorModal message={message} state={isErrorModalOpen} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { window.location.reload() }} />
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default Login