import React, { useEffect } from 'react'
import './PrivacyPolicy.scss'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    // header scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Check if scroll position is at 100vh
            if (scrollPosition >= 5) {
                $(".header").addClass("shrinked");
            } else {
                $(".header").removeClass("shrinked");

            }
        }
        window.addEventListener('scroll', handleScroll);
        // Clean up event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className=' privacy-policy'>

            <div className="body_wrap">
                <div className="page_wrap">

                    <header className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
                        <div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0ee19b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"> <div className="elementor-background-overlay"></div>
                                <div className="elementor-container elementor-column-gap-extended">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d2ebbc2" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">

                                            <div className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d67700c" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f56af19" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1970e32" data-element_type="widget" data-widget_type="trx_sc_layouts_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                                                                        <div className="sc_layouts_title_content">
                                                                            <div className="sc_layouts_title_title">
                                                                                <h1 className="sc_layouts_title_caption">Risk Disclosure Policy</h1>
                                                                            </div>
                                                                            <div className="sc_layouts_title_breadcrumbs">
                                                                                <div className="breadcrumbs"><a className="breadcrumbs_item home underline_hover" href="https://zurich.axiomthemes.com/">Home</a><span className="breadcrumbs_delimiter"></span><span className="breadcrumbs_item current">Risk Disclosure Policy</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div className="page_content_wrap">
                        <div className="content_wrap_fullscreen">
                            <div className="content">
                                <article id="post-211" className="post_item_single post_type_page post-211 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div data-elementor-type="wp-page" data-elementor-id="211" className="elementor elementor-211">

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">

                                                                    <p>At Reserve Financial Services we are committed to providing transparent and comprehensive information about the risks associated with investment and portfolio management. It is important for our clients to understand that all types of investments carry inherent risks. The value of investments may fluctuate, and past performance is not indicative of future results. Below are key risk factors you should consider before making any investment decisions: </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>1. Market Risk </h5>
                                                                    <p>The value of investments can be affected by movements in the financial markets due to various factors such as economic conditions, political events, and market sentiment. These fluctuations can result in both gains and losses. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>2. Liquidity Risk </h5>
                                                                    <p>Some investments may be harder to sell or convert into cash without a significant loss in value. Illiquid markets may affect your ability to quickly exit certain investments, especially in volatile conditions. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>3. Interest Rate Risk </h5>
                                                                    <p>Interest rate changes can have a direct impact on the value of fixed-income investments, such as bonds. Rising interest rates may lead to a decrease in bond prices and negatively affect your portfolio. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>4. Credit Risk </h5>
                                                                    <p>Credit risk arises when the issuer of a debt security (e.g., a bond) may be unable to meet its financial obligations, leading to default and possible loss of capital. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>5. Inflation Risk </h5>
                                                                    <p>Inflation reduces the purchasing power of your money over time. If your investments do not outpace inflation, the real value of your returns will decrease. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>6. Foreign Exchange Risk </h5>
                                                                    <p>For investments that involve foreign currencies, fluctuations in exchange rates can impact the value of the investment. Currency risk is particularly relevant to international investments. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>7. Diversification Risk </h5>
                                                                    <p>While diversification is designed to reduce risk, it cannot eliminate all market risk. Even well-diversified portfolios can experience losses during broader market downturns. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>8. Regulatory Risk </h5>
                                                                    <p>Changes in laws, regulations, or tax rules can affect investment returns. Investors should be aware that political or regulatory shifts could have implications on portfolio performance. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>9. Leverage Risk  </h5>
                                                                    <p>Using leverage in an investment strategy can amplify both gains and losses. Leveraged investments can carry significant risk, and their potential volatility may exceed that of traditional investment strategies. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>10. No Guarantee of Profit </h5>
                                                                    <p>Investment management involves a degree of uncertainty. While we aim to help you achieve your financial goals, there is no guarantee that you will earn a profit or avoid losses. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Client Acknowledgement </h5>
                                                                    <p>By proceeding with Reserve Financial Services’ investment and portfolio management services, you acknowledge that you fully understand the risks involved and agree to take responsibility for any potential losses. We encourage you to consult with our team or seek independent financial advice to ensure that the investments align with your financial goals and risk tolerance.

                                                                        If you have any questions or require further information regarding the risks associated with our services, please do not hesitate to contact us. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>



                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default PrivacyPolicy