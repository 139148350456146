import React, { Component, useState, useEffect } from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import ProfileMenu from './ProfileMenu';

import LogoutPopup from "../../authentication/LogoutPopup";
import NotificationPopup from './NotificationPopup/NotificationPopup';


const Header = ({ userData, isLoggedIn, notificationData }) => {

  const [isSecurityModalShow, setSecurityModalShow] = useState(false)
  const [isNotificationSettingsShow, setNotificationSettingsShow] = useState(false)

  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const [notificationMenuOpen, setNotificationMenuOpen] = useState(false)

  const [isLogoutModalShow, setLogoutModalShow] = useState(false)
  let navigate = useNavigate();


  const myFunction = () => {
    var x = document.getElementById("profile-logout-popup");
    if (x.style.display === "none") {
      x.style.display = "block";
    }
    else {
      x.style.display = "none";
    }

  }

  // websocket
  const [data, setData] = useState(null);

  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket('ws://localhost:8000/ws/notifications/');

    // Handle incoming messages
    socket.onmessage = (event) => {
      const parsedMessage = JSON.parse(event.data);
      console.log('socket data:', parsedMessage);

      setData(parsedMessage);
    };

    // Handle WebSocket errors
    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);



  return (
    <div className="admin-header">

      <div className="left" >
        <img className="app-logo" src="/images/admin-panel/header/app-logo.svg"></img>
      </div>
      {/* <div>
        <p>Notification: {data && 'data'}</p>
      </div> */}




      <div className="right" >

        <div className="btn-box">
          <button className="btn btn-borderless" onClick={() => { setNotificationMenuOpen(!notificationMenuOpen) }}>
            <img src='/images/admin-panel/header/notification.svg'></img>
            {notificationData && notificationData.results && notificationData.results.length > 0 &&
              <span className='notification-badge rounded-pill badge-theme'>{notificationData.results ? notificationData.results.length : 0}</span>
            }
          </button>

        </div>
        {notificationMenuOpen && notificationData && notificationData.results && <NotificationPopup notificationData={notificationData.results} />}



        {isLoggedIn && (['admin', 'restaurant', 'staff', 'driver'].includes(window.localStorage.getItem("userRole"))) ?
          <div className="user-box" tabIndex="0" onClick={() => setProfileMenuOpen(!profileMenuOpen)} >
            <div className="img-box">
              <img src={userData && userData.profile_image ? `${userData.profile_image}` : "/images/admin-panel/header/avatar-no-profile-image.png"} className="rounded"></img>
            </div>
            <img src="/images/admin-panel/header/down-arrow.svg"></img>
            {profileMenuOpen && <ProfileMenu userData={userData} setNotificationSettingsShow={setNotificationSettingsShow} setSecurityModalShow={setSecurityModalShow} setLogoutModalShow={setLogoutModalShow}></ProfileMenu>}
          </div>
          :
          <div className="login-btn-box">

            <button className="btn btn-primary login-btn " onClick={() => { navigate('/admin-login') }}>Log In</button>
            <button className="btn btn-primary signup-btn">Sign Up</button>
          </div>}

      </div>










      {isLogoutModalShow && <LogoutPopup setterFunction={setLogoutModalShow}></LogoutPopup>}
    </div>
  )

}

export default Header