import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API from '../../../API';
import '../../common-component/ImageUploader.css'
import './ChangeEmailAddress.scss'
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import LogoutPopup from '../../../authentication/LogoutPopup';

const ChangeEmailAddress = ({ userData, setterFunction }) => {
  const navigate = useNavigate()
  const [isLogoutModalShow, setLogoutModalShow] = useState(false)
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [isEmailEditPopup, setIsEmailEditPopup] = useState(false);

  // Email Change
  const [emailFormData, setEmailFormData] = useState({ password: '', email: '' });
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [passwordShown, showPassword] = useState(false);
  const [isOTPInputShown, setIsOTPInputShown] = useState(false);
  const [OTP, setOTP] = useState([null, null, null, null, null, null])

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const verifyPassword = () => {
    // validate email
    const validationErrors = {}
    if (!emailFormData.password.trim()) {
      validationErrors.password = "Password is required.";
    }
    setErrors(validationErrors);
    if (validationErrors.password) {
      return;
    }

    setIsLoading(true)
    const temp = { password: emailFormData.password }
    API.post(`/user/change-email/`, temp)
      .then(response => {
        setIsLoading(false);
        setPasswordVerified(true)
      })
      .catch(error => {
        setIsLoading(false)
        // Check if the error response exists and contains authentication information
        if (error.response?.data?.authentication === false) {
          setMessage('Incorrect Password');
        } else {
          setMessage(error.response?.data?.message || error.message);
        }
        setIsErrorModalOpen(true)
      });
  }

  const HandleEmailChangeSubmit = () => {
    // validate email
    const validationErrors = {}
    if (!emailFormData.email.trim()) {
      validationErrors.email = "Email is required.";
    }
    else if (!/\S+@\S+\.\S+/.test(emailFormData.email)) {
      validationErrors.email = "Invalid email address.";
    }
    setErrors(validationErrors);
    if (validationErrors.email) {
      return;
    }

    setIsLoading(true)
    const temp = { email: emailFormData.email }
    API.post(`/user/new-email/`, temp)
      .then(response => {
        setIsLoading(false)
        setIsOTPInputShown(true)
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }

  const handleOTPInputChange = (e, currentIndex) => {
    if (currentIndex < 6) {
      // Handle backspace
      if (e.nativeEvent.inputType === 'deleteContentBackward' || e.nativeEvent.inputType === 'deleteContentForward') {

        let temp = [...OTP];
        temp[currentIndex] = null;
        setOTP(temp);

        // Move to the previous input
        if (currentIndex > 0) {
          document.getElementById(`otp${currentIndex - 1}`).focus();
        }
      } else if (/[0-9]/.test(e.target.value)) {
        // Update the current input value
        const inputValue = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
        let temp = [...OTP];
        temp[currentIndex] = inputValue;
        setOTP(temp);

        // Move to the next input
        if (currentIndex < 5) {
          document.getElementById(`otp${currentIndex + 1}`).focus();
        }
      }
    }
  };

  const handleOTPSubmit = () => {

    if (OTP[0] && OTP[1] && OTP[2] && OTP[3] && OTP[4] && OTP[5]) {
      setErrors({})
      const temp = {
        "email": emailFormData.email,
        "otp": `${OTP[0]}${OTP[1]}${OTP[2]}${OTP[3]}${OTP[4]}${OTP[5]}`
      }
      API.post(`/user/confirm-new-email/`, temp)
        .then(response => {
          setIsLoading(false);
          setMessage('Email updated successfully!')
          setIsMessageModalOpen(true)
        })
        .catch(error => {
          setIsLoading(false)
          setMessage(error.response?.data?.message || error.message)
          setIsErrorModalOpen(true)
        });

    }
    else {
      setErrors({ otp: "Please enter 6 digits" })
    }


  };


  return (
    <div className='change-email'>

      <div className="change-profile-content">
        <div className="title mb-lg-5">
          <div className="loader-line"></div>
          <h3>Change Email Address</h3>
        </div>
        {!passwordVerified ?
          <>
            <ul className="profile-details-list mt-4">
              <li>
                <div className='row w-100'>
                  <div className='col-md-6'>
                    <div className="form-group  w-100">
                      <label className="form-label">Current Email</label>
                      <input type="email" className="form-control" value={userData.email}
                        name='email' placeholder="Enter your email" disabled />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className="form-group  w-100">
                      <label className="form-label">Current Password</label>
                      <div className='password-grp'>

                        <input type={passwordShown ? "text" : "password"} className="form-control" value={emailFormData.password} autocomplete="off"
                          name='password' placeholder="Enter Current Password" onChange={handleInputChange} />

                        <span className="password-eye" onClick={() => showPassword(!passwordShown)}>
                          {passwordShown ?

                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1 12.668C1 12.668 5 4.66797 12 4.66797C19 4.66797 23 12.668 23 12.668C23 12.668 19 20.668 12 20.668C5 20.668 1 12.668 1 12.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M12 15.668C13.6569 15.668 15 14.3248 15 12.668C15 11.0111 13.6569 9.66797 12 9.66797C10.3431 9.66797 9 11.0111 9 12.668C9 14.3248 10.3431 15.668 12 15.668Z" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            :


                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1777_2198)">
                                <path d="M17.94 18.608C16.2306 19.911 14.1491 20.6329 12 20.668C5 20.668 1 12.668 1 12.668C2.24389 10.3499 3.96914 8.3246 6.06 6.72799M9.9 4.90799C10.5883 4.74687 11.2931 4.66633 12 4.66799C19 4.66799 23 12.668 23 12.668C22.393 13.8036 21.6691 14.8727 20.84 15.858M14.12 14.788C13.8454 15.0827 13.5141 15.3191 13.1462 15.4831C12.7782 15.6471 12.3809 15.7353 11.9781 15.7424C11.5753 15.7495 11.1752 15.6754 10.8016 15.5245C10.4281 15.3736 10.0887 15.149 9.80385 14.8641C9.51897 14.5793 9.29439 14.2399 9.14351 13.8664C8.99262 13.4928 8.91853 13.0927 8.92563 12.6899C8.93274 12.2871 9.02091 11.8898 9.18488 11.5218C9.34884 11.1538 9.58525 10.8226 9.88 10.548" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1 1.66797L23 23.668" stroke="#8F8F8F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_1777_2198">
                                  <rect width="24" height="24" fill="white" transform="translate(0 0.667969)" />
                                </clipPath>
                              </defs>
                            </svg>
                          }

                        </span>
                      </div>

                      {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                    </div>
                  </div>
                </div>

              </li>
            </ul>
            <div className='d-flex w-100 align-items-center justify-content-center mt-5'>
              <button type='button' className='btn theme-outline submit-btnrounded-2' onClick={() => setterFunction(false)}>
                Cancel
              </button>
              <button type='button' className='btn theme-btn submit-btnrounded-2 ms-4' onClick={verifyPassword}>
                Continue
              </button>
            </div>
          </>
          :
          <>
            <ul className="profile-details-list mt-4">
              <li>
                <div className='row w-100'>
                  <div className='col-md-6'>
                    <div className="form-group  w-100">
                      <label className="form-label">Current Email</label>
                      <input type="email" className="form-control" value={userData.email}
                        name='email' placeholder="Enter your email" disabled />
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className="form-group  w-100">
                      <label className="form-label">New Email </label>
                      <input type="email" className="form-control" value={emailFormData.email}
                        name='email' placeholder="Enter New Email Address" onChange={handleInputChange} />
                      {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                    </div>
                  </div>

                  {isOTPInputShown &&
                    <div className='col-12 mt-4'>
                      <div className="form-group  w-100">
                        <label className="form-label">
                          OTP &nbsp;
                          <span className="text-secondary">(An OTP has been sent to your new email address.)</span>
                        </label>
                        <div className="otp-box d-flex gap-sm-3 gap-2">
                          {[0, 1, 2, 3, 4, 5].map((index) => (
                            <div className="form-input mb-0" key={index}>
                              <input
                                type="number"
                                className={`form-control ${index === 0 ? 'active' : ''}`}
                                id={`otp${index}`}
                                name={`otp${index}`}
                                value={OTP[index] || ''}
                                onChange={(e) => handleOTPInputChange(e, index)}

                              />
                            </div>
                          ))}
                        </div>
                        {errors.otp && <div className="invalid-feedback">{errors.otp}</div>}
                      </div>
                    </div>
                  }
                </div>

              </li>
            </ul>
            <div className='d-flex w-100 align-items-center justify-content-center mt-5'>
              <button type='button' className='btn theme-outline submit-btnrounded-2' onClick={() => setterFunction(false)}>
                Cancel
              </button>
              {isOTPInputShown ?
                <button type='button' className='btn theme-btn submit-btnrounded-2 ms-4' onClick={handleOTPSubmit}>
                  SUBMIT
                </button>
                :
                <button type='button' className='btn theme-btn submit-btnrounded-2 ms-4' onClick={HandleEmailChangeSubmit}>
                  SUBMIT
                </button>
              }
            </div>
          </>
        }
      </div>

      {isLogoutModalShow && <LogoutPopup setterFunction={setLogoutModalShow}></LogoutPopup>}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { window.location.reload() }} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default ChangeEmailAddress