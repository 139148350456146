// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders-list-page .form-switch .form-check-input {
  background-color: rgb(var(--theme-color)) !important;
  border-style: solid;
  border-color: rgb(var(--theme-color));
}
.orders-list-page .form-select.delivery-status {
  min-width: 195px;
  width: -moz-fit-content;
  width: fit-content;
}
.orders-list-page .dataTables_wrapper {
  min-height: 390px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/OrdersList/OrdersList.scss"],"names":[],"mappings":"AACI;EACI,oDAAA;EACA,mBAAA;EACA,qCAAA;AAAR;AAGQ;EACI,gBAAA;EACA,uBAAA;EAAA,kBAAA;AADZ;AAKI;EACI,iBAAA;AAHR","sourcesContent":[".orders-list-page {\r\n    .form-switch .form-check-input {\r\n        background-color: rgb(var(--theme-color)) !important;\r\n        border-style: solid;\r\n        border-color: rgb(var(--theme-color));\r\n    }\r\n    .form-select{\r\n        &.delivery-status{\r\n            min-width: 195px;\r\n            width: fit-content;\r\n        }\r\n    }\r\n\r\n    .dataTables_wrapper{\r\n        min-height: 390px;\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
