// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ratio_40 .bg-size:before{padding-top:40%;content:"";display:block}.ratio_45 .bg-size:before{padding-top:45%;content:"";display:block}.ratio2_1 .bg-size:before{padding-top:50%;content:"";display:block}.ratio_55 .bg-size:before{padding-top:55%;content:"";display:block}.ratio_59 .bg-size:before{padding-top:59%;content:"";display:block}.ratio2_3 .bg-size:before{padding-top:60%;content:"";display:block}.ratio3_2 .bg-size:before{padding-top:66.66%;content:"";display:block}.ratio_90 .bg-size:before{padding-top:93%;content:"";display:block}.ratio_landscape .bg-size:before{padding-top:75%;content:"";display:block}.ratio_square .bg-size:before{padding-top:100%;content:"";display:block}.ratio_asos .bg-size:before{padding-top:127.7777778%;content:"";display:block}.ratio_portrait .bg-size:before{padding-top:150%;content:"";display:block}.ratio1_2 .bg-size:before{padding-top:200%;content:"";display:block}.b_size_content{background-size:contain !important;background-repeat:no-repeat}
/*# sourceMappingURL=ratio.css.map */
`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/css/ratio.css"],"names":[],"mappings":"AAAA,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,kBAAkB,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,iCAAiC,eAAe,CAAC,UAAU,CAAC,aAAa,CAAC,8BAA8B,gBAAgB,CAAC,UAAU,CAAC,aAAa,CAAC,4BAA4B,wBAAwB,CAAC,UAAU,CAAC,aAAa,CAAC,gCAAgC,gBAAgB,CAAC,UAAU,CAAC,aAAa,CAAC,0BAA0B,gBAAgB,CAAC,UAAU,CAAC,aAAa,CAAC,gBAAgB,kCAAkC,CAAC,2BAA2B;AACv9B,oCAAoC","sourcesContent":[".ratio_40 .bg-size:before{padding-top:40%;content:\"\";display:block}.ratio_45 .bg-size:before{padding-top:45%;content:\"\";display:block}.ratio2_1 .bg-size:before{padding-top:50%;content:\"\";display:block}.ratio_55 .bg-size:before{padding-top:55%;content:\"\";display:block}.ratio_59 .bg-size:before{padding-top:59%;content:\"\";display:block}.ratio2_3 .bg-size:before{padding-top:60%;content:\"\";display:block}.ratio3_2 .bg-size:before{padding-top:66.66%;content:\"\";display:block}.ratio_90 .bg-size:before{padding-top:93%;content:\"\";display:block}.ratio_landscape .bg-size:before{padding-top:75%;content:\"\";display:block}.ratio_square .bg-size:before{padding-top:100%;content:\"\";display:block}.ratio_asos .bg-size:before{padding-top:127.7777778%;content:\"\";display:block}.ratio_portrait .bg-size:before{padding-top:150%;content:\"\";display:block}.ratio1_2 .bg-size:before{padding-top:200%;content:\"\";display:block}.b_size_content{background-size:contain !important;background-repeat:no-repeat}\r\n/*# sourceMappingURL=ratio.css.map */\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
