// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-add-page .card {
  padding: 30px;
}
.blog-add-page .form-select.to_group {
  text-transform: capitalize;
}
.blog-add-page .form-sec label {
  font-size: calc(13px + 2 * (100vw - 320px) / 1600);
  color: rgb(76, 76, 76);
  font-weight: 500;
  margin-bottom: 5px;
}
.blog-add-page .rich-text-container .editor-class .DraftEditor-root {
  font-size: 16px;
}
.blog-add-page .add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}
.blog-add-page .add-button label {
  margin: 0;
  cursor: pointer;
}
.blog-add-page .add-button svg path {
  fill: rgb(var(--theme-color));
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/BlogAdd/BlogAdd.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAIQ;EACI,0BAAA;AAFZ;AAMQ;EACI,kDAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AAJZ;AASQ;EACI,eAAA;AAPZ;AAWI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AATR;AAUQ;EACI,SAAA;EACA,eAAA;AARZ;AAWY;EACI,6BAAA;AAThB","sourcesContent":[".blog-add-page {\r\n    .card{\r\n        padding: 30px;\r\n    }\r\n\r\n    .form-select {\r\n        &.to_group {\r\n            text-transform: capitalize;\r\n        }\r\n    }\r\n    .form-sec{\r\n        label{\r\n            font-size: calc(13px + 2 * (100vw - 320px) / 1600);\r\n            color: rgb(76, 76, 76);\r\n            font-weight: 500;\r\n            margin-bottom: 5px;\r\n        }\r\n    }\r\n\r\n    .rich-text-container .editor-class{\r\n        .DraftEditor-root {\r\n            font-size: 16px;\r\n          }\r\n        \r\n    }\r\n    .add-button {\r\n        display: flex;\r\n        justify-content: center;\r\n        align-items: center;\r\n        gap: 2px;\r\n        cursor: pointer;\r\n        label{\r\n            margin: 0;\r\n            cursor: pointer;\r\n        }\r\n        svg{\r\n            path{\r\n                fill: rgb(var(--theme-color));\r\n            }\r\n        }\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
