// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-detail-section .blog-box .img-box img {
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.blog-detail-section .content-box .blog-title {
  margin-bottom: 10px;
  font-weight: 550;
  cursor: text;
}
.blog-detail-section .content-box p {
  margin-top: 0;
}
.blog-detail-section .content-box .share-box {
  margin-top: calc(20px + 5 * (100vw - 320px) / 1600);
}
.blog-detail-section .content strong {
  color: #474747;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/BlogDetail/BlogDetail.scss"],"names":[],"mappings":"AAEQ;EACI,iBAAA;EACA,iBAAA;EACA,mBAAA;AADZ;AAKQ;EACI,mBAAA;EACA,gBAAA;EACA,YAAA;AAHZ;AAKQ;EACI,aAAA;AAHZ;AAKQ;EACI,mDAAA;AAHZ;AAOQ;EACI,cAAA;AALZ","sourcesContent":[".blog-detail-section {\r\n    .blog-box .img-box {\r\n        img {\r\n            max-height: 400px;\r\n            object-fit: cover;\r\n            border-radius: 10px;\r\n        }\r\n    }\r\n    .content-box{\r\n        .blog-title{\r\n            margin-bottom: 10px;\r\n            font-weight: 550;\r\n            cursor: text;\r\n        }\r\n        p{\r\n            margin-top: 0;\r\n        }\r\n        .share-box{\r\n            margin-top: calc(20px + 5 * (100vw - 320px) / 1600);\r\n        }\r\n    }\r\n    .content{\r\n        strong{\r\n            color: #474747;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
