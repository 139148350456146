import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
};

// Default center (London)
const defaultCenter = { lat: 51.509865, lng: -0.118092 };

const GOOGLEMAP_APIKEY = process.env.REACT_APP_GOOGLEMAP_APIKEY;

const LiveTrackingMapComponent = ({ restaurantLocation, driverLocation, customerLocation }) => {
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    // Center the map based on the restaurant's location by default
    if (restaurantLocation) {
      setCenter(restaurantLocation);
    }
  }, [restaurantLocation]);

  return (
    <>
      {!restaurantLocation ? (
        <div className='d-flex w-100 h-100 justify-content-center align-items-center ' style={{ color: 'red', fontSize: "12px" }}>
          <i className='ri-map-pin-line me-1'></i>
          Locations could not be found.
        </div>
      ) :
        <LoadScript googleMapsApiKey={GOOGLEMAP_APIKEY}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            {restaurantLocation && (
              <Marker
                position={restaurantLocation}
                icon={'/images/svg/placed.svg'}
              />
            )}
            {driverLocation && (
              <Marker
                position={driverLocation}
                icon={'/images/svg/driver.svg'}
              />
            )}
            {customerLocation && (
              <Marker
                position={customerLocation}
                icon={'/images/svg/user-map.svg'}
              />
            )}
          </GoogleMap>
        </LoadScript>
      }
    </>
  );
};

export default LiveTrackingMapComponent;
