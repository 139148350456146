// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-list-section .blog-box {
  cursor: pointer;
}
.blog-list-section .blog-box .blog-image .img {
  max-height: 220px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/BlogList/BlogList.scss"],"names":[],"mappings":"AACI;EACI,eAAA;AAAR;AACQ;EACI,iBAAA;EACA,iBAAA;AACZ","sourcesContent":[".blog-list-section{\r\n    .blog-box{\r\n        cursor: pointer;\r\n        .blog-image .img{\r\n            max-height: 220px;\r\n            object-fit: cover;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
