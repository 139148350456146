import React, { useEffect, useState, useRef } from 'react'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import "./forfingconsection.scss";
import "./forzurichsection.scss";
import "./__custom.css";
import $ from 'jquery';
import './responsive.css'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { isValidPhoneNumber } from 'libphonenumber-js';

const ContactUs = () => {
	useEffect(() => {
		$(function () {
			$(window).scrollTop(0);
		});
	}, [])


	// Create a ref for the target div
	const targetDivRef = useRef(null);

	// Create a function that scrolls to the target div
	const scrollToDiv = () => {
		if (targetDivRef.current) {
			targetDivRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};




	const [agree1, setAgree1] = useState(false);
	const [agree2, setAgree2] = useState(false);
	const [optionListOpened, toggleOptionList] = useState(false)


	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		"full_name": "",
		//	"last_name": "",
		"email": "",
		"country_code": "",
		"phone": "",
		"company_name": "",
		"subject": "",
		"message": ""
	});
	const [message, setMessage] = useState(null);
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
	const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);



	const [errors, setErrors] = useState({});
	const handleChange = (e) => {


		setFormData({
			...formData,
			[e.target.name]: e.target.value
		});


	};
	const handlePhoneChange = (value, country) => {
		setFormData({
			...formData,
			phone: value,
			country_code: country.dialCode
		});
	};

	const selectCategory = (value) => {

		setFormData({
			...formData,
			"category": value
		});
	}

	const setAgree = (index, e) => {
		console.log("e.target.checked", e.target.checked)
		if (index === 1) {
			if (e.target.checked) {
				setAgree1(true)
			}
			else {
				setAgree1(false)
			}

		}
		else if (index === 2) {
			if (e.target.checked) {
				setAgree2(true)
			}
			else {
				setAgree2(false)
			}
		}
	}

	const validatePhoneNumber = (phoneNumber, countryCode) => {
		console.log("phoneNumber, countryCode",phoneNumber,"," ,countryCode)
		try {
			const parsedPhoneNumber = isValidPhoneNumber(`+${phoneNumber}`, countryCode);
			return parsedPhoneNumber ? null : 'Invalid phone number';
		} catch (error) {
			return 'Invalid phone number';
		}
	};

	const validateForm = (data) => {
		const errors = {};

		// Validate each field and add errors if necessary
		if (!data.full_name.trim()) {
			errors.full_name = "Full name is required.";
		}

		// if (!data.last_name.trim()) {
		// 	errors.last_name = "Last name is required.";
		// }

		if (!data.phone.trim()) {
			errors.phone = "Phone number is required.";
		}
		// else if (!/^\d{10}$/.test(data.phone)) {
		//   errors.phone = "Phone number must be 10 digits.";
		// }
		else if (validatePhoneNumber(data.phone, data.country_code)) {
			errors.phone = 'Invalid phone number'
		}


		if (!data.email.trim()) {
			errors.email = "Email is required.";
		} else if (!/\S+@\S+\.\S+/.test(data.email)) {
			errors.email = "Invalid email address.";
		}

		// if (!data.company_name.trim()) {
		//   errors.company_name = "Company name is required.";
		// }

		if (data.subject === "") {
			errors.subject = "Enter an Enquiry Subject.";
		}
		if (!data.message.trim()) {
			errors.message = "Enter your message with atleast 100 Character";
		}

		if (!agree1) {
			errors.agree1 = "Please agree the above terms and condition to proceed."
		}
		// if (!agree2) {
		// 	errors.agree2 = "Please agree the above terms and condition to proceed."
		// }
		return errors;
	};



	const handleSubmit = async (e) => {
		console.log("entered into handle submit")
		e.preventDefault();

		// Validate the form fields
		const validationErrors = validateForm(formData);
		console.log("validation errors", validationErrors)
		console.log("formData", formData)
		setErrors(validationErrors);

		if (Object.keys(validationErrors).length === 0) {
			const phoneNumberWithoutCountryCode = formData.phone.substring(formData.country_code.length);
			const updatedFormData = { ...formData, phone: phoneNumberWithoutCountryCode, country_code: `+${formData.country_code}` };

			// Perform the API call
			setIsLoading(true)
			API.post("/contact/", updatedFormData).then((response) => {
				// Handle the API response as needed
				setIsLoading(false)
				setMessage("Your Message is sent Succesfully! Our team will contact you shortly. Thank you for showing interest in ZOG Global.");
				setIsMessageModalOpen(true)
				window.localStorage.removeItem("emailForSignup")

			})
				.catch(error => {
					// Handle API errors
					setIsLoading(false)

					setMessage(error.message);
					setIsErrorModalOpen(true)
				})
		}
	};


	useEffect(() => {
		//window.scrollTo(0, 0);

		// Get the textarea element
		var textarea = document.getElementById("message");

		// Add an event listener for input
		textarea.addEventListener("input", function () {
			// Set the textarea height to auto to get its full height
			this.style.height = "auto";
			// Then set the height to the scrollHeight, which will adjust the height based on content
			this.style.height = (this.scrollHeight) + "px";

		}, []);

	})
	useEffect(() => {
		if (optionListOpened == true) {
			if (window.innerWidth > 992) {
				$(".option-list").animate({ height: "217px" }, 400)
			}
			else if (window.innerWidth > 767) {
				$(".option-list").animate({ height: "217px" }, 400)

			}
			else {
				$(".option-list").animate({ height: "10rem" }, 400)
			}
			$(".option-list").css("opacity", "1")
		}
		else {
			$(".option-list").animate({ height: "0px" }, 400)
			$(".option-list").css("opacity", "0")
		}
	}, [optionListOpened])
	return (
		<>



			<div className='zurich-section'>
				<div className="body_wrap">
					<div className="page_wrap" style={{ minHeight: "unset" }}>


						<div className="content_wrap_fullscreen">
							<div className="content">
								<a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#"></a>
								<article id="post-217" className="post_item_single post_type_page post-217 page type-page status-publish hentry">
									<div className="post_content entry-content">
										<div data-elementor-type="wp-page" data-elementor-id="217" className="elementor elementor-217">
											<section className="elementor-section elementor-top-section elementor-element elementor-element-b12ae64 elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="b12ae64" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
												<div className="elementor-container elementor-column-gap-no">
													<div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-ee74945 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="ee74945" data-element_type="column">
														<div className="elementor-widget-wrap elementor-element-populated">

															<div className="elementor-element elementor-element-656b320 sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="656b320" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div>
															<section className="elementor-section elementor-inner-section elementor-element elementor-element-340fdbb elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="340fdbb" data-element_type="section">
																<div className="elementor-container elementor-column-gap-extended">
																	<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-34a5f2c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="34a5f2c" data-element_type="column">
																		<div className="elementor-widget-wrap elementor-element-populated">
																			<div className="elementor-element elementor-element-292d3e5 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="292d3e5" data-element_type="widget" data-widget_type="trx_sc_title.default">
																				<div className="elementor-widget-container">
																					<div className="sc_title sc_title_default">

																						<h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Reach Out to us for   <br /><span className='highlight'>Expert Help!</span></span>
																						</h1>
																						<div className="sc_item_descr sc_title_descr">
																							<p>Please leave your details, and we’ll connect you with one of our expert financial advisers soon. </p>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="elementor-element elementor-element-5df0148 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5df0148" data-element_type="widget" data-widget_type="spacer.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-spacer">
																						<div className="elementor-spacer-inner"></div>
																					</div>
																				</div>
																			</div>
																			{/* <div className="elementor-element elementor-element-f953791 sc_fly_static elementor-widget elementor-widget-trx_sc_icons trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f953791" data-element_type="widget" data-widget_type="trx_sc_icons.default">
																					<div className="elementor-widget-container">
																						<div className="sc_icons sc_icons_simple sc_icons_size_small sc_align_none">
																							<div className="sc_icons_item with_more">
																								<img src='/images/contact-us/location.svg' className="sc_icons_icon sc_icon_type_ icon-marker"></img>
																								<div className="sc_icons_item_details">
																									<div className="sc_icons_item_description">
																										<span>Suite V3, 4 Woodland Road, DL3 7PJ </span>
																									</div>
																								</div>
																							</div>
																							<div className="sc_icons_item sc_icons_item_linked with_more">
																								<img src='/images/contact-us/phone.svg' className="sc_icons_icon sc_icon_type_ icon-iphone"></img>

																								<div className="sc_icons_item_details"><a href="tel:18408412569" className="sc_icons_item_more_link"> <span className="link_text">02045424350</span><span className="link_icon"></span> </a><a href="tel:18408412569" className="sc_icons_item_link"> </a>
																								</div>
																							</div>
																							<div className="sc_icons_item with_more">
																								<img src='/images/contact-us/mail.svg' className="sc_icons_icon sc_icon_type_ icon-envelop-2"></img>

																								<div className="sc_icons_item_details">
																									<div className="sc_icons_item_description">
																										<span><a href="mailto:info@email.com" className="underline_hover">connect@reservefinancialservices.com </a>
																										</span>
																									</div>
																								</div>
																							</div>
																						</div>

																					</div>
																				</div> */}
																		</div>
																	</div>
																	<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f86ad2b sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f86ad2b" data-element_type="column" style={{ backgroundColor: "#fff", border: "0.5px solid #BEC1F2", boxShadow: "0px 4px 24px 0px #0000000D" }}>
																		<div className="elementor-widget-wrap elementor-element-populated" >
																			<div className="elementor-element elementor-element-dcd675b sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="dcd675b" data-element_type="widget" data-widget_type="spacer.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-spacer">
																						<div className="elementor-spacer-inner"></div>
																					</div>
																				</div>
																			</div>
																			<div className="elementor-element elementor-element-55ec12e sc_fly_static elementor-widget elementor-widget-trx_sc_contact_form_7 trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="55ec12e" data-element_type="widget" data-widget_type="trx_sc_contact_form_7.default">
																				<div className="elementor-widget-container">
																					<div className="wpcf7 js alert_inited" id="wpcf7-f547-p217-o1" lang="en-US" dir="ltr"> <div className="screen-reader-response">
																						<p role="status" aria-live="polite" aria-atomic="true"></p>
																						<ul></ul></div>
																						<form onSubmit={handleSubmit} noValidate className="wpcf7-form init" aria-label="Contact form" novalidate="novalidate" data-status="init" data-inited-validation="1">
																							<div style={{ display: "none" }}>
																								<input type="hidden" name="_wpcf7" value="547" />
																								<input type="hidden" name="_wpcf7_version" value="5.9.5" />
																								<input type="hidden" name="_wpcf7_locale" value="en_US" />
																								<input type="hidden" name="_wpcf7_unit_tag" value="wpcf7-f547-p217-o1" />
																								<input type="hidden" name="_wpcf7_container_post" value="217" />
																								<input type="hidden" name="_wpcf7_posted_data_hash" value="" />
																							</div>
																							<div className="form-style-3">
																								<div className="columns_wrap">
																									<div className="column-1_2">
																										<p>
																											<span className="style-line icon-name">
																												<span className="wpcf7-form-control-wrap" data-name="your-name">
																													<input size="40"
																														className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited"
																														type="text"

																														//className={`form-control form-control-lg ${errors.first_name ? "is-invalid" : ""}`}
																														value={formData.full_name}
																														onChange={handleChange}
																														placeholder="Full Name"
																														name="full_name"
																														id='full_name' />
																													<span className="line">
																													</span>

																													{errors.full_name && <div className="wpcf7-not-valid-tip">{errors.full_name}</div>}

																												</span>
																											</span>
																										</p>
																									</div>

																									<div className="column-1_2">
																										<p>
																											<span className="style-line icon-email">
																												<span className="wpcf7-form-control-wrap" data-name="your-email">
																													<input
																														size="40"
																														className="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email fill_inited"

																														placeholder="Email Address"
																														type="email"
																														name="email"
																														id='email'
																														value={formData.email}
																														onChange={handleChange} />
																													<span className="line">
																													</span>
																													{errors.email && <div className="wpcf7-not-valid-tip">{errors.email}</div>}

																												</span>
																											</span>
																										</p> </div>
																								</div>
																								<div className="columns_wrap"> <div className="column-1_2">
																									<p>
																										<span className="style-line icon-phone">
																											<span className="wpcf7-form-control-wrap" data-name="phone">
																												{/* <input size="40" className="wpcf7-form-control wpcf7-tel wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-tel fill_inited" aria-required="true" aria-invalid="false" placeholder="Phone" value="" type="tel" name="phone" id='phone' /> */}
																												<PhoneInput
																													inputProps={{
																														name: 'phone',
																														id: 'phone',
																														className: 'form-control form-control-lg',
																														placeholder: 'Phone Number',
																														style: { paddingLeft: '52px' }
																													}}
																													country={'gb'}
																													value={formData.phone}
																													onChange={(value, country) => handlePhoneChange(value, country)}
																												/>
																												<span className="line"></span>
																												{errors.phone && <div className="wpcf7-not-valid-tip">{errors.phone}</div>}

																											</span>
																										</span>
																									</p>
																								</div> <div className="column-1_2">
																										<p>
																											<span className="style-line icon-subject">
																												<span className="wpcf7-form-control-wrap" data-name="subject">
																													<input size="40"
																														className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required fill_inited"
																														placeholder="Service Type"
																														type="text"
																														name="subject"
																														id='subject'
																														value={formData.subject}
																														onChange={handleChange} />
																													<span className="line">
																													</span>
																													{errors.subject && <div className="wpcf7-not-valid-tip">{errors.subject}</div>}



																												</span>
																											</span>
																										</p>
																									</div>
																								</div>
																								<div className="columns_wrap margin-bottom"> <div className="column-1_1">
																									<p>
																										<span className="style-line icon-textarea">
																											<span className="wpcf7-form-control-wrap" data-name="your-message">
																												<textarea cols="40"
																													
																													className="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required fill_inited"
																													placeholder="Query Details"
																													name="message"
																													id='message'
																													value={formData.message}
																													onChange={handleChange}></textarea>
																												<span className="line">
																												</span>
																												{errors.message && <div className="wpcf7-not-valid-tip">{errors.message}</div>}

																											</span>
																										</span>
																									</p>
																								</div>
																								</div>
																								<p>
																									<span className="wpcf7-submit-style">
																										<input className="wpcf7-form-control wpcf7-submit has-spinner" type="submit" value="Get an Advisor" />
																										<span className="submit-style-in">
																											<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
																												<g clip-path="url(#clip0_77_2606)">
																													<path d="M12.5845 19.9998C12.4081 19.9998 12.2455 19.8999 12.1699 19.7386L8.30782 11.5978L0.267502 7.93169C0.100281 7.85446 -0.00509021 7.68638 0.00178186 7.50239C0.00865392 7.3184 0.125479 7.15713 0.299571 7.09353L19.381 0.0293641C19.5482 -0.0319646 19.7361 0.00664976 19.8643 0.131579C19.9926 0.256508 20.0339 0.442766 19.972 0.60858L13.0152 19.7C12.9533 19.8726 12.7907 19.9907 12.6051 19.9998C12.5983 19.9998 12.5914 19.9998 12.5845 19.9998ZM1.65108 7.56372L8.84384 10.8414C8.94234 10.8868 9.02022 10.9641 9.06604 11.0595L12.5296 18.3599L18.774 1.22414L1.65108 7.56372Z" fill="#222639" />
																													<path d="M8.65182 11.7093C8.53499 11.7093 8.41817 11.6662 8.32883 11.5753C8.15016 11.3982 8.15016 11.1097 8.32883 10.9325L19.2188 0.1341C19.3974 -0.0430723 19.6884 -0.0430723 19.867 0.1341C20.0457 0.311272 20.0457 0.599744 19.867 0.776916L8.97709 11.5776C8.88776 11.6662 8.77093 11.7116 8.65411 11.7116L8.65182 11.7093Z" fill="#222639" />
																												</g>
																												<defs>
																													<clipPath id="clip0_77_2606">
																														<rect width="20" height="20" fill="white" />
																													</clipPath>
																												</defs>
																											</svg>

																										</span>
																									</span>
																									<span className="wpcf7-spinner"></span>
																									<span className="wpcf7-form-control-wrap" data-name="acceptance">
																										<span className="wpcf7-form-control wpcf7-acceptance">
																											<span className="wpcf7-list-item">
																												<label>
																													<input type="checkbox"
																														value=""
																														id="agree_checkbox_1"
																														name="agree_checkbox_1"
																														onChange={(e) => setAgree(1, e)}
																														checked={agree1}
																													/>
																													<span className="wpcf7-list-item-label">I agree that my data is <a data-savepage-href="/privacy-policy/" href="#">collected</a>.</span>
																												</label>
																												{errors.agree1 && <div className="wpcf7-not-valid-tip">{errors.agree1}</div>}
																											</span>
																										</span>
																									</span>
																								</p>
																							</div><div className="wpcf7-response-output" aria-hidden="true"></div>
																						</form>
																					</div>
																				</div>
																			</div>
																			<div className="elementor-element elementor-element-dcd675b sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="dcd675b" data-element_type="widget" data-widget_type="spacer.default">
																				<div className="elementor-widget-container">
																					<div className="elementor-spacer">
																						<div className="elementor-spacer-inner"></div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</section> <div className="elementor-element elementor-element-f4172cf sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f4172cf" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div> </div>
															{/* <div className="elementor-element elementor-element-5a22d5f sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5a22d5f" data-element_type="widget" data-widget_type="spacer.default">
																<div className="elementor-widget-container">
																	<div className="elementor-spacer">
																		<div className="elementor-spacer-inner"></div>
																	</div>
																</div>
															</div> */}
														</div>
													</div>
												</div>
											</section>





										</div>
									</div>
								</article>


							</div>
						</div>


					</div>
				</div >
			</div>

			<div className='fingcon-section'>
				<div data-elementor-type="wp-post" data-elementor-id="2305" className="elementor elementor-2305">

					<div className="elementor-element elementor-element-f8da56c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="f8da56c" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f1c2c9d elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="f1c2c9d" data-element_type="widget" data-widget_type="divider.default">
								<div className="elementor-widget-container">
									<link rel="stylesheet" href="css/style29.css" />
									<div className="elementor-divider">
										<span className="elementor-divider-separator">
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="elementor-element elementor-element-ddd9b17 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="ddd9b17" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-4b2958b e-con-full e-flex e-con e-child" data-id="4b2958b" data-element_type="container">
								<div className="elementor-element elementor-element-4319bfa elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="4319bfa" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/phone.svg'></img>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-fc12afe elementor-widget elementor-widget-heading" data-id="fc12afe" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">02045424350 </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-633275b elementor-widget elementor-widget-heading" data-id="633275b" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">02033769868 </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-64269bf elementor-widget elementor-widget-text-editor" data-id="64269bf" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<link rel="stylesheet" href="css/style30.css" />				<p>We’re always here to help—reach out to us for any questions, support, or assistance you may need. </p>						</div>
								</div>
								<div className="elementor-element elementor-element-432025c elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="432025c" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="#" target="_self">
											<span className="qodef-m-text">TALK TO AN ADVISOR </span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M7.60276 4.23734L7.10566 3.11885C6.78063 2.38753 6.61811 2.02185 6.37505 1.74201C6.07044 1.39131 5.67341 1.13329 5.22921 0.997373C4.87478 0.888916 4.47463 0.888916 3.67433 0.888916C2.50359 0.888916 1.91821 0.888916 1.42682 1.11396C0.847986 1.37906 0.325222 1.95469 0.116951 2.55632C-0.0598631 3.06705 -0.00922121 3.59191 0.0920748 4.64162C1.17026 15.8151 7.29605 21.9408 18.4695 23.019C19.5192 23.1203 20.044 23.171 20.5548 22.9942C21.1564 22.7859 21.732 22.2631 21.9971 21.6843C22.2222 21.1928 22.2222 20.6075 22.2222 19.4368C22.2222 18.6364 22.2222 18.2363 22.1138 17.8818C21.9778 17.4376 21.7198 17.0406 21.3691 16.736C21.0892 16.493 20.7235 16.3305 19.9922 16.0054L18.8738 15.5083C18.0817 15.1563 17.6858 14.9804 17.2833 14.9421C16.8982 14.9054 16.51 14.9595 16.1495 15.0999C15.7728 15.2465 15.4398 15.5239 14.774 16.0788C14.1112 16.6311 13.7798 16.9073 13.3749 17.0552C13.016 17.1863 12.5415 17.2349 12.1633 17.1791C11.7369 17.1163 11.4102 16.9418 10.7571 16.5927C8.72492 15.5068 7.60434 14.3862 6.51833 12.354C6.16929 11.7009 5.99478 11.3742 5.93193 10.9478C5.87621 10.5696 5.92478 10.0951 6.05591 9.73618C6.20384 9.33122 6.48 8.99984 7.03232 8.33705C7.58717 7.67122 7.8646 7.33831 8.01128 6.96163C8.15165 6.60117 8.20567 6.21285 8.16904 5.82777C8.13077 5.42535 7.95477 5.02935 7.60276 4.23734Z" fill="#222639" />
													</svg>


												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-dc1e3ef e-con-full elementor-hidden-mobile e-flex e-con e-child" data-id="dc1e3ef" data-element_type="container">
								<div className="elementor-element elementor-element-371ca45 elementor-widget__width-auto elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="371ca45" data-element_type="widget" data-widget_type="divider.default">
									<div className="elementor-widget-container">
										<div className="elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-bf1bd2a e-con-full e-flex e-con e-child" data-id="bf1bd2a" data-element_type="container">
								<div className="elementor-element elementor-element-cff3e88 elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="cff3e88" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/mail.svg'></img>
										</div>
									</div>
								</div>

								<div className="elementor-element elementor-element-9315caf elementor-widget elementor-widget-heading" data-id="9315caf" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">connect@reservefs.com </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-ee710ab elementor-widget elementor-widget-text-editor" data-id="ee710ab" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<p>Feel free to contact us via email for any questions or support. We’re always happy to assist you. </p>						</div>
								</div>
								<div className="elementor-element elementor-element-3206301 elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="3206301" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="#" target="_self">
											<span className="qodef-m-text">EMAIL US</span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M1.019 12.7781C1.09778 16.4723 1.13717 18.3192 2.50023 19.6876C3.86328 21.0558 5.76031 21.1034 9.55439 21.1987C11.8927 21.2575 14.2081 21.2575 16.5465 21.1987C20.3406 21.1034 22.2375 21.0558 23.6007 19.6876C24.9637 18.3192 25.0031 16.4723 25.0818 12.7781C25.1072 11.5903 25.1072 10.4096 25.0818 9.22178C25.0031 5.52767 24.9637 3.68062 23.6007 2.31238C22.2375 0.94414 20.3406 0.896481 16.5465 0.80115C14.2081 0.742392 11.8927 0.742392 9.55437 0.801138C5.76031 0.896457 3.86328 0.944116 2.50022 2.31237C1.13716 3.68061 1.09778 5.52766 1.01899 9.22178C0.993661 10.4096 0.993674 11.5903 1.019 12.7781Z" fill="#222639" />
														<path d="M1 3.76978L9.33048 8.48989C12.4016 10.23 13.6992 10.23 16.7704 8.48989L25.1008 3.76978" fill="#222639" />
														<path d="M1 3.76978L9.33048 8.48989C12.4016 10.23 13.6992 10.23 16.7704 8.48989L25.1008 3.76978" stroke="#CFDD28" stroke-width="3" stroke-linejoin="round" />
													</svg>


												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-02cd146 e-con-full elementor-hidden-mobile e-flex e-con e-child" data-id="02cd146" data-element_type="container">
								<div className="elementor-element elementor-element-6ae9996 elementor-widget__width-auto elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="6ae9996" data-element_type="widget" data-widget_type="divider.default">
									<div className="elementor-widget-container">
										<div className="elementor-divider">
											<span className="elementor-divider-separator">
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className="elementor-element elementor-element-9f30efe e-con-full e-flex e-con e-child" data-id="9f30efe" data-element_type="container">
								<div className="elementor-element elementor-element-69c4973 elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn" data-id="69c4973" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}" data-widget_type="icon.default">
									<div className="elementor-widget-container">
										<div className="elementor-icon-wrapper">
											<img src='/images/contact-us/location.svg'></img>
										</div>
									</div>
								</div>
								<div className="elementor-element elementor-element-5b13f15 elementor-widget elementor-widget-heading" data-id="5b13f15" data-element_type="widget" data-widget_type="heading.default">
									<div className="elementor-widget-container">
										<h5 className="elementor-heading-title elementor-size-default">Suite V3, 4 Woodland Road, DL3 7PJ </h5>		</div>
								</div>
								<div className="elementor-element elementor-element-7acf282 elementor-widget elementor-widget-text-editor" data-id="7acf282" data-element_type="widget" data-widget_type="text-editor.default">
									<div className="elementor-widget-container">
										<p><strong>Mon – Fri: 9AM – 5PM,</strong></p>						</div>
								</div>
								<div className="elementor-element elementor-element-0770a44 elementor-widget elementor-widget-qi_addons_for_elementor_button" data-id="0770a44" data-element_type="widget" data-widget_type="qi_addons_for_elementor_button.default">
									<div className="elementor-widget-container">
										<a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--outlined qodef-type--icon-boxed qodef-size--full qodef-hover--reveal qodef--reveal-horizontal qodef-icon--left qodef-hover--icon-move-horizontal     " data-savepage-href="#" href="#" target="_self">
											<span className="qodef-m-text">GET DIRECTION </span>
											<span className="qodef-m-icon " style={{ width: "24px" }}>
												<span className="qodef-m-icon-inner">
													<svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
														<path d="M12.9132 24.7186C12.3857 25.2265 11.6807 25.5105 10.9471 25.5105C10.2134 25.5105 9.5084 25.2265 8.98092 24.7186C4.15087 20.0384 -2.32199 14.81 0.834631 7.21956C2.54138 3.11543 6.63836 0.489258 10.9471 0.489258C15.2558 0.489258 19.3527 3.11544 21.0595 7.21956C24.2121 14.8005 17.7551 20.0545 12.9132 24.7186Z" fill="#222639" />
														<path d="M14.8563 11.4362C14.8563 13.5954 13.1059 15.3458 10.9467 15.3458C8.7875 15.3458 7.03711 13.5954 7.03711 11.4362C7.03711 9.27699 8.7875 7.52661 10.9467 7.52661C13.1059 7.52661 14.8563 9.27699 14.8563 11.4362Z" fill="#CFDD28" />
													</svg>


												</span>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>


					<div className="elementor-element elementor-element-f8da56c e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="f8da56c" data-element_type="container">
						<div className="e-con-inner">
							<div className="elementor-element elementor-element-f1c2c9d elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="f1c2c9d" data-element_type="widget" data-widget_type="divider.default">
								<div className="elementor-widget-container">
									<link rel="stylesheet" href="css/style29.css" />		<div className="elementor-divider">
										<span className="elementor-divider-separator">
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>




				</div>
			</div>


		</>

	);
}

export default ContactUs