import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import './HeaderLocationPopup.scss'
import { LoadScript, Autocomplete, GoogleMap, Marker } from '@react-google-maps/api';
import { fetchBrowserCurrentLocation, getAddressFromLatLng } from '../../../GeneralFunctions';

const containerStyle = {
  width: '100%',
  minHeight: '200px',
  height: '100%',
};
const libraries = ['places'];

const HeaderLocationPopup = ({ setterFunction, userData, setLocation }) => {
  const GOOGLEMAP_APIKEY = process.env.REACT_APP_GOOGLEMAP_APIKEY;
  const navigate = useNavigate()
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [currentLocation, setCurrentLocation] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [currentLocationAddress, setCurrentLocationAddress] = useState('Fetching current location...');

  useEffect(() => {
    if (userData?.current_latitude && userData?.current_longitude) {
      getAddressFromLatLng(userData.current_latitude, userData.current_longitude)
        .then(address => {
          setSelectedAddress(address);
          setSelectedLocation({
            address: address,
            lat: userData.current_latitude,
            lng: userData.current_longitude,
          });
        })
        .catch(error => {
          setSelectedAddress('');
        });
    }
  }, [userData]);

  const fetchCurrentLocation = () => {
    fetchBrowserCurrentLocation()
      .then(location => {
        setCurrentLocationAddress(location.address);
        setCurrentLocation({
          name: 'Current Location',
          address: location.address,
          lat: location.lat,
          lng: location.lng,
        });
      })
      .catch(error => {
        setCurrentLocationAddress('Unable to fetch location');
      });

  };

  useEffect(() => {
    fetchCurrentLocation();
  }, []);

  const handleSearchSelect = (place) => {
    if (place && place.geometry) {
      // Update the selected location state
      setSelectedLocation({
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setSelectedAddress(place.formatted_address)
      // Update the current location address with the selected place
      // setCurrentLocationAddress(place.formatted_address);
    }
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    getAddressFromLatLng(lat, lng)
      .then(address => {
        setSelectedAddress(address);
        setSelectedLocation({
          address: address,
          lat: lat,
          lng: lng,
        });
      })
      .catch(error => {
        setSelectedAddress('');
      });

  };

  const handleUseCurrentLocation = () => {
    if (currentLocation) {
      setSelectedAddress(currentLocation.address)
      setSelectedLocation({
        address: currentLocation.address,
        lat: currentLocation.lat,
        lng: currentLocation.lng,
      });
    }
  };

  const handleSaveLocation = (type) => {
    let location;
    if (type == 'current') {
      location = currentLocation;
    }
    else {
      location = selectedLocation;
    }
    // validate
    if (!location || !location.lat) {
      setErrors({ location: 'Please select your location.' });
      return;
    } else {
      setErrors({})
    }
    const formData = { current_latitude: location.lat, current_longitude: location.lng }
    if (!window.localStorage.getItem('userID')) {
      setterFunction(false);
      setLocation({ address: location.address, current_latitude: location.lat, current_longitude: location.lng });
      return;
    }
    setIsLoading(true);
    API.put(`/user/users/${window.localStorage.getItem('userID')}/`, formData)
      .then(response => {
        setIsLoading(false);
        setterFunction(false);
        setLocation({ address: location.address, current_latitude: location.lat, current_longitude: location.lng });
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
      });

  };


  return (
    <>
      <div className="custom-modal location-modal">
        <div className="card">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title">
                <h5 className="fw-semibold">Select a Location</h5>
                <button type="button" className="btn-close" onClick={() => { setterFunction(false) }}></button>
              </div>
            </div>
            <div className="modal-body">
              <LoadScript googleMapsApiKey={GOOGLEMAP_APIKEY} libraries={libraries}>
                <Autocomplete
                  onLoad={(autocomplete) => {
                    window.autocomplete = autocomplete;
                  }}
                  onPlaceChanged={() => {
                    const place = window.autocomplete.getPlace();
                    handleSearchSelect(place);
                  }}
                >
                  <div className="search-section">
                    <input
                      type="text"
                      placeholder="Search location"
                      className="nav-search nav-search-field"
                      value={selectedAddress}
                      onChange={(e) => setSelectedAddress(e.target.value)}
                    />
                  </div>
                </Autocomplete>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={{
                    lat: parseFloat(selectedLocation.lat) || 51.509865,
                    lng: parseFloat(selectedLocation.lng) || -0.118092,
                  }}
                  zoom={12}
                  onClick={handleMapClick}
                  options={{
                    mapTypeControl: false, // Remove the map type control
                    streetViewControl: false, // street view
                    zoomControl: false, // Remove zoom controls
                  }}
                >
                  {selectedLocation && (
                    <Marker
                      position={{
                        lat: parseFloat(selectedLocation.lat) || 51.509865,
                        lng: parseFloat(selectedLocation.lng) || -0.118092,
                      }}
                    />
                  )}

                </GoogleMap>

                <a className={`current-location ${currentLocation ? '': 'disabled'}`} onClick={handleUseCurrentLocation} >
                  <div className="current-address">
                    <i className="ri-focus-3-line focus"></i>
                    <div>
                      <h5>Use current-location</h5>
                      <h6>{currentLocationAddress}</h6>
                    </div>
                  </div>
                  <i className="ri-arrow-right-s-line arrow"></i>
                </a>
              </LoadScript>
              {errors.location && <div className="invalid-feedback">{errors.location}</div>}

            </div>
            <div className="modal-footer">
              <a className="btn gray-btn me-1" onClick={() => { setterFunction(false) }} >Close</a>
              <a className="btn theme-btn mt-0" onClick={handleSaveLocation}>Save</a>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
    </>
  )
}

export default HeaderLocationPopup