
import API from './API';
import { UK_COUNTIES } from './Constants';
import { saveAs } from 'file-saver'; // Import file-saver library
import InvoiceTemplate from './InvoiceTemplate';
import ResumeTemplate from './ResumeTemplate';
import { pdf } from '@react-pdf/renderer'; // Import from react-pdf/renderer


export const isoToDatetimeLocal = (isoString) => {
  if (!isoString) return ""; // Handle empty or undefined values
  const date = new Date(isoString);
  return date.toISOString().slice(0, 16); // Convert to YYYY-MM-DDTHH:MM format
};

export const convertTo12HourTime = (timeString) => {
  timeString = timeString.toString();
  console.log("timestring", timeString)
  timeString = timeString.toString().replace(':', '');
  // Extract hours, minutes, and seconds from the input string
  const hours = parseInt(timeString.slice(0, 2));
  const minutes = parseInt(timeString.slice(2, 4));

  // Determine AM/PM
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // If hours is 0, convert to 12

  // Format the time string
  const formattedTime = `${hours12}:${minutes < 10 ? '0' : ''}${minutes}${period}`;

  return formattedTime;
}

export const fetchInvoiceDataAndGeneratePdf = async (id) => {
  try {
    const response = await API.get(`/payments/invoices/${id}/`);
    const invoiceData = response.data;
    // Generate and save PDF blob
    const blob = await pdf(

      <InvoiceTemplate data={invoiceData} />

    ).toBlob();

    saveAs(blob, 'invoice.pdf'); // Automatically trigger download. save as is a very useful library to download files. it will replace the following 9 commented lines

    //   const blobUrl = URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.style.display = 'none';
    //   a.href = blobUrl;
    //   a.download = 'invoice.pdf';
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    //   URL.revokeObjectURL(blobUrl);
    // 
  }
  catch (error) {
    console.error('Error fetching invoice data:', error);
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-GB', options).split('/').reverse().join('-');
};

export const fetchResumeDataAndGeneratePdf = async (id) => {
  try {
    const response = await API.get(`download-resume/${id}/`);
    const invoiceData = response.data.invoice_data;

    // Generate and save PDF blob
    const blob = await pdf(

      <ResumeTemplate invoiceData={invoiceData} />

    ).toBlob();

    saveAs(blob, 'resume.pdf'); // Automatically trigger download. save as is a very useful library to download files. it will replace the following 9 commented lines

    //   const blobUrl = URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   a.style.display = 'none';
    //   a.href = blobUrl;
    //   a.download = 'invoice.pdf';
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    //   URL.revokeObjectURL(blobUrl);
    // 
  }
  catch (error) {
    console.error('Error fetching invoice data:', error);
  }
};


export function formatDateTime(inputDate) {
  const date = new Date(inputDate);
  const now = new Date();
  const options = { hour: '2-digit', minute: '2-digit' };

  // Helper function to format date
  function formatTime(date) {
    return date.toLocaleTimeString([], options).replace(/(\d+):(\d+)/, "$1:$2");
  }

  // Check if the date is today
  const isToday = date.toDateString() === now.toDateString();
  if (isToday) {
    return `Today, ${formatTime(date)}`;
  }

  // Check if the date is yesterday
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  const isYesterday = date.toDateString() === yesterday.toDateString();
  if (isYesterday) {
    return `Yesterday, ${formatTime(date)}`;
  }

  // Format date as '25 March 2024, 8:00 PM'
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  return `${day} ${month} ${year}, ${formatTime(date)}`;
}

export function formatDateTimeToMonthYear(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-GB', options);

  // Rearrange the date format to "day month, year"
  const [day, month, year] = formattedDate.split(' ');
  return `${day} ${month}, ${year}`;
}
export function formatDateTime2MonthYearTime(timestamp) {
  const date = new Date(timestamp);

  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-based
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = padZero(date.getMinutes());
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'

  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${padZero(hours)}:${minutes} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
}

export function formatReviewCount(count) {
  if (count >= 1_000_000) {
    // Format in millions, removing decimal if it is .0
    const millions = (count / 1_000_000).toFixed(1);
    return millions.endsWith('.0') ? `${Math.floor(count / 1_000_000)}M+` : `${millions}M+`;
  } else if (count >= 1_000) {
    // Format in thousands, removing decimal if it is .0
    const thousands = (count / 1_000).toFixed(1);
    return thousands.endsWith('.0') ? `${Math.floor(count / 1_000)}k+` : `${thousands}k+`;
  } else {
    return count.toString();
  }
}

export const timeAgo = (timestamp) => {
  const now = new Date();
  const past = new Date(timestamp);
  const diff = now - past;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);
  const years = Math.floor(months / 12);

  if (seconds < 60) return `${seconds} sec${seconds !== 1 ? 's' : ''} ago`;
  if (minutes < 60) return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
  if (hours < 24) return `${hours} hr${hours !== 1 ? 's' : ''} ago`;
  if (days < 30) return `${days} day${days !== 1 ? 's' : ''} ago`;
  if (months < 12) return `${months} month${months !== 1 ? 's' : ''} ago`;
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};

const GoogleMapApiKey = process.env.REACT_APP_GOOGLEMAP_APIKEY;
export const fetchBrowserCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // Use Google Maps Geocoding API to get address from lat/lng
          let apiUrl = `/communication/get-address/?lat=${lat}&lng=${lng}`;
          API.get(apiUrl)
            .then(response => {
              const address = response.data.results[0]?.formatted_address || 'Current location';
              // Resolve with the location data
              resolve({
                name: 'Current Location',
                address: address,
                lat,
                lng,
              });
            })
            .catch(error => {
              reject('Error fetching address');
              // setIsErrorModalOpen(true);
            });
        },
        (error) => {
          console.error('Error fetching location', error);
          // Reject with an error message
          reject('Unable to fetch location');
        }
      );
    } else {
      // Reject if geolocation is not supported
      reject('Geolocation not supported');
    }
  });
};


export function getAddressFromLatLng(lat, lng) {
  let apiUrl = `/communication/get-address/?lat=${lat}&lng=${lng}`;
  return API.get(apiUrl)
    .then(response => {
      const address = response.data.results[0]?.formatted_address || 'Current location';
      return address;
    })
    .catch(error => {
      return 'Location';
    });
}


export function formatTimeFromMinutes(minutes) {
  minutes = minutes.toFixed(0)
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours} hr${hours > 1 ? 's' : ''}${mins > 0 ? ` ${mins} min${mins > 1 ? '' : ''}` : ''}`;
  } else {
    return `${minutes} min${minutes > 1 ? '' : ''}`;
  }
}

export function convertRichTextJsonToText(jsonData) {
  // Check if jsonData is undefined or null
  if (jsonData === undefined || jsonData === null) {
    return ''; // Return an empty string if no data is passed
  }
  let parsedData;
  // Try to parse the JSON, if it fails treat the description as plain text
  try {
    parsedData = JSON.parse(jsonData);
  } catch (error) {
    // If it's not JSON, treat it as plain text
    const isEmpty = jsonData.trim().length === 0;
    return isEmpty ? '' : jsonData; // Return the description if it's not empty
  }
  // If the parsing is successful, handle it as JSON
  const textWithNewLines = parsedData.blocks
    .map((block) => block.text)
    .join('\n'); // Join each block's text with a newline

  return textWithNewLines;
}