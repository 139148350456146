import React from "react";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import "./TicketsDetails.scss"

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import { TICKET_TYPE_LIST } from "../../../Constants";
import { formatDateTime2MonthYearTime, formatDateTimeToMonthYear } from "../../../GeneralFunctions";


const TicketsDetails = () => {

  const navigate = useNavigate()
  const [data, setData] = useState([]);
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const loadData = () => {
    let apiUrl = `/communication/tickets/${localStorage.getItem('itemSelectedId')}`;
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false)
      });
  }
  useEffect(() => {
    loadData();
  }, []);

  const changeStatus = () => {
    let temp = { status: selectedStatus }
    API.put(`/communication/tickets/${selectedId}/`, temp)
      .then(response => {
        if (selectedStatus == "closed") {
          setMessage("The Ticket is closed, and intimated the customer via mail.");
        }
        else {
          setMessage("The Ticket is Reopened, and intimated the customer via mail.");
        }
        setIsConfirmModalOpen(false)
        setIsMessageModalOpen(true);
        loadData()
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
      });
  }


  return (
    <div className="ticket-details-page">
      <div className="page-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="title-header option-title">
                    <div>
                      <h5>
                        Ticket
                        <span className="ms-1 text-secondary">#{data.id}</span>
                      </h5>
                      <p className="card-text">
                        {data.created_at && formatDateTimeToMonthYear(data.created_at)}
                      </p>
                    </div>

                    <div className="rhs d-none">
                      <div className={`${data.status == 'closed' ? `status-close` : 'status-danger'}`}>
                        {data.status && data.status.replace(/_/g, ' ')}
                      </div>
                    </div>

                  </div>

                  <div className='d-flex w-100 justify-content-between main-data'>
                    {/* customer */}
                    <div className='customer'>
                      <div className='clr-898989 f-14 mb-3'>Customer</div>
                      <div className='w-100 d-flex align-items-center'>
                        <div>
                          {data.profile_image ?
                            <img src={data.profile_image} className='view-invoice-proimg' alt=''></img> :
                            <img src="/images/no-profile-image.png" className='view-invoice-proimg' alt=''></img>
                          }
                        </div>
                        <div className='ms-3 black-clr'>
                          <div className='f-xs fw-600 mb-2 text-capitalize'>{data.customer_name}</div>
                          <div className='f-14 fw-500'>{data.email}</div>
                          <div className='f-14 fw-500'><span>Ph: +{data.phone}</span></div>
                        </div>
                      </div>
                    </div>
                    {/* Initiated By */}
                    <div className='ms-2 initiated'>
                      <div className="d-flex align-items-center">
                        <div className='clr-898989 f-14 me-2'>Initiated By:</div>
                        <div className='f-xs fw-600  capitalize'>{data.initiated_user_name}</div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div className='clr-898989 f-14 me-2'>Ticket Type:</div>
                        <div className='f-xs fw-500  capitalize'>{data.ticket_type && data.ticket_type.replace(/_/g, ' ')}</div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div className='clr-898989 f-14 me-2'>Order ID:</div>
                        <div className='f-xs fw-500  capitalize'>#{data.order}</div>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div className='clr-898989 f-14 me-2'>Created At:</div>
                        <div className='f-14 fw-500  capitalize'>{data.created_at && formatDateTime2MonthYearTime(data.created_at)}</div>
                      </div>
                    </div>
                    <div className="ms-2 status-change">
                      <div className="clr-898989 f-14 text-start w-100">Ticket Status</div>
                      <select className={`form-select status ${data.status ? `status-${data.status}` : ''}`}
                        value={data.status}
                        onChange={(event) => { setSelectedId(data.id);setSelectedStatus(event.target.value); setIsConfirmModalOpen(true) }}
                      >
                        {['in_progress', 'closed'].map((status, index) => (
                          <option key={index} value={status} className={`status-btn ${data.status ? `status-${data.status}` : ''}`}>
                            {status.replace(/_/g, ' ')}
                          </option>
                        ))}
                      </select>
                    </div>

                  </div>
                  <div className='gradientline'></div>
                  {data.reason &&

                    <div className="row mb-3">
                      <div className="d-flex align-items-center">
                        <div className='clr-898989 f-14 me-2'>Reason:</div>
                        <div className='f-xs fw-500  capitalize'>{data.reason}</div>
                      </div>
                    </div>
                  }
                  <h5 className="clr-898989 f-14 mb-2">Request Message</h5>
                  <div className="mb-3 message">
                    <p className="card-text">{data.request_message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Confirm Modal */}
        {isConfirmModalOpen &&
          <div className='custom-modal '>
            <div className='card'>
              <div className='first-screen'>
                <img src='/images/icons/svg/warning.svg'></img>
                <h1>Change Status</h1>
                <p>Are you sure you want to update the status?<br/> A notification email will be sent to the customer.</p>
                <div className='footer'>
                  <button type='button' className='cancel-button' onClick={() => { setIsConfirmModalOpen(false) }}>Cancel</button>
                  <button type='button' className='ok-button' onClick={changeStatus}>Change</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => setIsMessageModalOpen(false)} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div >
  )
}


export default TicketsDetails