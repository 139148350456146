import React from "react";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import "./OrdersList.scss"

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"


import Pagination from "../../../Pagination";
import { PlusSquare } from "react-feather";
import { formatDateTimeToMonthYear } from "../../../GeneralFunctions";
import { DELIVERY_STATUSES } from "../../../Constants";


const OrdersList = () => {

  const navigate = useNavigate()

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    search_key: null,
    payment_status: null,
    delivery_status: null,
    date: null,
    is_favourite: null,
  });

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const toggleFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
    setIsFilterOpen(!isFilterOpen);
  };

  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(12);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {
    loadTableData();
  }, [page, pageSize, filters]);

  const loadTableData = () => {
    setData(null);
    setIsMessageModalOpen(false);
    let apiUrl = `/order/orders/?page=${page}&page_size=${pageSize}`;

    // Loop through the filters object and append selected filters to the apiUrl
    for (let filter in filters) {
      if (filters[filter] !== null) {
        apiUrl += `&${filter}=${filters[filter]}`;
      }
    }
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data);
        setIsLoading(false)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
        setIsLoading(false)

      });
  }


  const deleteItem = () => {
    API.delete(`/order/orders/${selectedItem.id}`)
      .then(response => {
        setMessage("Item deleted successfully");
        setIsMessageModalOpen(true)
      })
      .catch(error => {
        setMessage(error.message);
        setIsErrorModalOpen(true);
      });
  }

  const handleDeliveryStatusChange = (itemId, event) => {
    const selectedStatus = event.target.value;
    API.put(`/order/orders/${itemId}/`, { delivery_status: selectedStatus })
      .then(response => {
        loadTableData();
      })
      .catch((error) => {
        setIsErrorModalOpen(true);
        setMessage(error.response?.data?.message || error.message);
      });
  }

  // To change Application Status (for Custom Select)
  const [isStatusListOpen, setStatusListOpen] = useState([]);
  const toggleStatus = (index) => {
    const updatedDropDownState = [...isStatusListOpen];
    updatedDropDownState[index] = !updatedDropDownState[index];
    setStatusListOpen(updatedDropDownState);
  };
  const handleDeliveryStatusChange1 = (option, itemId, index) => {
    const updatedStatusListState = [...isStatusListOpen];
    updatedStatusListState[index] = false;
    setStatusListOpen(updatedStatusListState);
    // update Item-status
    setIsLoading(true)
    API.put(`/order/orders/${itemId}/`, { delivery_status: option })
      .then((response) => {
        setIsLoading(false);
        loadTableData();
      })
      .catch((error) => {
        setIsErrorModalOpen(true);
        setMessage(error.response?.data?.message || error.message);
      });
    setIsLoading(false);
  }

  const resetFilters = () => {
    setFilters({
      search_key: null,
      payment_status: null,
      delivery_status: null,
      date: null,
      is_favourite: null,
    });
    setIsFilterOpen(false);
  };



  return (
    <div className="admin-list-page orders-list-page">
      <div className="page-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="title-header option-title">
                    <h5>Order List</h5>
                    {/* <div className="d-inline-flex">
                      <button type="button"
                        className="align-items-center m-0 btn save-button d-flex gap-2">
                        Download All Orders
                      </button>
                    </div> */}
                  </div>
                  <div className="table-responsive theme-scrollbar">
                    <div>
                      <div id="table_id_wrapper" class="dataTables_wrapper no-footer">

                        <div id="table_id_filter" class="dataTables_filter d-flex">
                          <div className='filters'>
                            <div onClick={toggleFilterDropdown} className='filter-txt me-2'>
                              <span className="me-1">Filter</span>
                              <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.8374 6.20276L0.806316 0.648682L6.86849 0.648683L3.8374 6.20276Z" fill="#466574" />
                              </svg>
                            </div>
                            {/* Filter Dropdowns */}
                            {isFilterOpen && (
                              <div className='dropdown'>
                                <label className="">Delivery Status</label>
                                <select name="delivery_status" className={`form-select `} value={filters.delivery_status} onChange={handleFilterChange} >
                                  <option value={null} label="Select Delivery status"></option>
                                  {DELIVERY_STATUSES.map((status, index) => (
                                    <option key={index} value={index}>
                                      {status.replace(/_/g, ' ')}
                                    </option>
                                  ))}
                                </select>
                                <label className="mt-3">Payment Status</label>
                                <select className={`form-select `} name="payment_status" value={filters.payment_status} onChange={handleFilterChange}>
                                  <option value={null} label="Select Payment status"></option>
                                  <option value="1">pending</option>
                                  <option value="2">Completed</option>
                                  <option value="3">Failed</option>
                                </select>

                                <label className="mt-3">Date</label>
                                <input
                                  type="date"
                                  name="date"
                                  value={filters.date}
                                  className="date-picker"
                                  onChange={handleFilterChange}
                                />

                                <div className="d-flex w-100 justify-content-end mt-2" >
                                  <button className="reset-btn" onClick={resetFilters}>Reset filters</button>
                                </div>
                              </div>
                            )}
                          </div>
                          <label>
                            <input type="search" class="" aria-controls="table_id" placeholder='Search for Orders'
                              id='search' onKeyUp={(e) => setFilters(prevFilters => ({
                                ...prevFilters, search_key: e.target.value
                              }))}
                            />
                          </label>
                        </div>
                        <table className="table category-table" id="table_id">
                          <thead>

                            <tr>
                              {/* <th><input id="checkall" className="custom-checkbox" type="checkbox" name="text" />
                              </th> */}
                              <th>Order ID</th>
                              <th>Date</th>
                              <th>Customer</th>
                              <th>Payment Method</th>
                              {localStorage.getItem('userRole') !== 'restaurant' &&
                                <th>Restaurant</th>
                              }
                              <th>Amount</th>
                              <th>Delivery Status</th>
                              <th>Option</th>
                              <th>Tracking</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data && data.results && data.results.map((item, index) => (
                              <tr>
                                {/* <td>
                                <input className="custom-checkbox" type="checkbox" name="text" />
                              </td> */}
                                <td>
                                  #{item.id}
                                </td>
                                <td>{item.order_placed_datetime && formatDateTimeToMonthYear(item.order_placed_datetime)}</td>
                                <td>{item.first_name} {item.last_name}</td>
                                <td>{item.payment_method && ['paypal', 'card', 'cod', 'manual'][parseInt(item.payment_method)]}</td>
                                {localStorage.getItem('userRole') !== 'restaurant' &&
                                  <td>{item?.items?.[0].restaurant_name.name}</td>
                                }
                                <td>£{item.total_amount}</td>
                                <td>
                                  <select className={`form-select delivery-status ${item.delivery_status ? `status-${item.delivery_status}` : ''}`}
                                    value={item.delivery_status}
                                    onChange={(event) => handleDeliveryStatusChange(item.id, event)}
                                  >
                                    {DELIVERY_STATUSES.map((status, index) => (
                                      <option key={index} value={index} className={`status-btn ${item.delivery_status ? `status-${item.delivery_status}` : ''}`}>
                                        {status.replace(/_/g, ' ')}
                                      </option>
                                    ))}
                                  </select>
                                  {/* custom-select */}
                                  <div className='status custom-select d-none'>
                                    <div className={`status-btn ${item.delivery_status ? `status-${item.delivery_status}` : ''}`}
                                      onClick={(event) => { toggleStatus(index); }}>
                                      <span>
                                        {item.delivery_status ? DELIVERY_STATUSES[parseInt(item.delivery_status)] : 'status'}
                                      </span>
                                      <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.8374 6.20276L0.806316 0.648682L6.86849 0.648683L3.8374 6.20276Z" fill="#466574" />
                                      </svg>
                                    </div>
                                    {isStatusListOpen[index] && (
                                      <div className='option-list'>
                                        {DELIVERY_STATUSES.map((status, statusIndex) => (
                                          <div
                                            key={statusIndex}
                                            className='option'
                                            onClick={() => handleDeliveryStatusChange(statusIndex, item.id, index)}
                                          >
                                            {status}
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                  </div>
                                </td>
                                <td>
                                  <ul
                                    className="d-flex align-items-center  justify-content-center">
                                    <li>
                                      <a onClick={() => {
                                        localStorage.setItem("itemSelectedId", item.id);
                                        navigate("/admin/orders/details")
                                      }}>
                                        <i className="ri-eye-line"></i>
                                      </a>
                                    </li>

                                    {/* <li>
                                      <button>
                                        <i className="ri-pencil-line"></i>
                                      </button>
                                    </li> */}

                                    {/* <li>
                                      <button className="" onClick={() => { setSelectedItem(item); setIsDeleteConfModalOpen(true) }}>
                                        <i className="ri-delete-bin-line"></i>
                                      </button>
                                    </li> */}
                                  </ul>
                                </td>
                                <td>
                                  <a onClick={() => {
                                    localStorage.setItem("itemSelectedId", item.id);
                                    navigate("/admin/orders/tracking")
                                  }} class="btn btn-sm btn-dashed text-white">
                                    Track
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {data &&
                    <Pagination
                      totalItems={data.count}
                      pageSize={pageSize}
                      currentPage={page}
                      setCurrentPage={setPage}
                      selectPageSize={selectPageSize}
                    >

                    </Pagination>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadTableData} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadTableData} />}
      {isDeleteConfModalOpen && <DeleteConfirmModal resourceName={'order'} setterFunction={setIsDeleteConfModalOpen} onDeleteFunction={deleteItem}></DeleteConfirmModal>}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )
}


export default OrdersList