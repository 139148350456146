// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal.address-add-popup .card {
  max-height: 95vh;
  overflow-y: scroll;
  padding: 10px 30px 30px 30px;
}
.custom-modal.address-add-popup textarea#delivery_instruction::placeholder {
  font-size: 12px !important;
  opacity: 60%;
}
.custom-modal.address-add-popup .phone-input-container .down-arrow {
  left: 20px;
}
.custom-modal.address-add-popup .phone-input-container .down-arrow svg {
  width: 20px;
}
.custom-modal.address-add-popup .phone-input-container .form-control {
  width: 100%;
  padding: 10px 20px 10px 60px !important;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/AddressAddPopup.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,kBAAA;EACA,4BAAA;AAAR;AAGI;EACI,0BAAA;EACA,YAAA;AADR;AAII;EACI,UAAA;AAFR;AAIQ;EACI,WAAA;AAFZ;AAMI;EACI,WAAA;EACA,uCAAA;AAJR","sourcesContent":[".custom-modal.address-add-popup {\r\n    .card {\r\n        max-height: 95vh;\r\n        overflow-y: scroll;\r\n        padding: 10px 30px 30px 30px;\r\n    }\r\n\r\n    textarea#delivery_instruction::placeholder {\r\n        font-size: 12px !important;\r\n        opacity: 60%;\r\n    }\r\n\r\n    .phone-input-container .down-arrow {\r\n        left: 20px;\r\n\r\n        svg {\r\n            width: 20px;\r\n        }\r\n    }\r\n\r\n    .phone-input-container .form-control {\r\n        width: 100%;\r\n        padding: 10px 20px 10px 60px !important;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
