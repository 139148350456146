import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import BlogList from '../../common-component/BlogList/BlogList.js';
import API from '../../../API';
import { formatDateTimeToMonthYear } from '../../../GeneralFunctions.js';
import './Blogs.scss'
import Pagination from '../../../Pagination.js';
import BlogDetail from '../../common-component/BlogDetail/BlogDetail.js';

const Blogs = ({ mode }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(12);
  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [recentData, setRecentData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchKey, setSearchKey] = useState(null);
  const [blogId, setBlogId] = useState(null);
  const [filters, setFilters] = useState({
    category: null
  });


  const handleFilterChange = (key, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value
    }));
    navigate('/blogs');
  };

  const Search = (key) => {
    let searchInput = document.getElementById("search-ip")
    const searchKEY = searchInput.value
    setSearchKey(searchKEY);
    if (key === "Enter") {
      loadData();
      navigate("/blogs");
    }else{
      if (searchKEY ===''){
        loadData();
        navigate("/blogs");
      }
    }
  }
  const handleSearchClick = (e) => {
    loadData();
    navigate('/blogs');
    // setSearchKey('')
  }

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  // load categories'
  const loadCategories = () => {
    API.get(`/communication/blog-categories/?page_size=1000`)
      .then(response => {
        setCategories(response.data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }
  useEffect(() => {
    loadCategories();
  }, [])

  // load data
  const loadRecentData = () => {
    // Load recent
    API.get(`/communication/blogs/?page=${1}&page_size=${4}&is_published=True`)
      .then(response => {
        setRecentData(response.data.results);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const loadData = () => {
    if (mode === 'list') {
      let apiUrl = `/communication/blogs/?page=${page}&page_size=${pageSize}&is_published=True`;
      // Loop through the filters object and append selected filters to the apiUrl
      for (let filter in filters) {
        if (filters[filter] !== null) {
          apiUrl += `&${filter}=${filters[filter]}`;
        }
      }
      if (searchKey) {
        apiUrl += `&search_key=${searchKey}`
      }
      setIsLoading(true);
      API.get(`${apiUrl}`)
        .then(response => {
          setData(response.data);
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          console.error(error);
        });
    } else if (mode === 'detail') {
      const selectedPostId = localStorage.getItem('itemSelectedId');
      if (selectedPostId) {
        // Fetch blog details using selectedPostId
      }
    }

  }
  useEffect(() => {
    loadRecentData();
  }, [])
  useEffect(() => {
    loadData();
  }, [filters, mode])

  return (
    <div className='blogs-page'>
      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Blog {mode === 'list' ? 'List' : 'Details'}</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <a onClick={() => navigate('/home')}><i className="ri-home-line"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Blog {mode === 'list' ? 'List' : 'Details'}
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="section-b-space">
        <div className="container">
          <div className="blog-boxs">
            <div className="row g-4">
              <div className="col-lg-3 order-lg-0 order-1">
                <div className="left-box wow fadeInUp">
                  <div className="shop-left-sidebar">
                    <div className="search-box">
                      <div className="form-input position-relative">
                        <input type="search" className="form-control search" id="search-ip" name='search_key'
                          onKeyUp={(e) => Search(e.key)}  placeholder="Search"></input>
                        <i className="ri-search-line search-icon" onClick={handleSearchClick}></i>
                      </div>
                    </div>
                    <div className="accordion sidebar-accordion" id="accordionPanelsStayOpenExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            <span className="dark-text">Categories</span>
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionPanelsStayOpenExample"
                        >
                          <div className="accordion-body">
                            <ul className="category-list custom-padding custom-height scroll-bar">
                              {categories.filter(category => category.blog_count > 0)
                                .map((category, index) => (
                                  <li key={index} >
                                    <a onClick={() => handleFilterChange('category', category.id)}
                                      className={`${filters.category === category.id ? 'active' : ''}`}>
                                      <div className="form-check ps-0 m-0 category-list-box">
                                        <div className="form-check-label">
                                          <span className="name">{category.name}</span>
                                          <span className="number">({category.blog_count})</span>
                                        </div>
                                      </div>
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                          >
                            <span className="dark-text">Recent Post</span>
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionPanelsStayOpenExample"
                        >
                          <div className="accordion-body">
                            <div className="post-wrap">
                              {recentData.map((post, index) => (
                                <a onClick={() => {
                                  navigate('/blog-details'); setBlogId(post.id);
                                  localStorage.setItem('itemSelectedId', post.id)
                                }}
                                  className="post-box" key={index}>
                                  <div className="img-box">
                                    <img className="img-fluid img" src={post.image} alt={post.alt} />
                                  </div>
                                  <div className="content-box">
                                    <h6>{post.author}</h6>
                                    <span>{post.published_on && formatDateTimeToMonthYear(post.published_on)}</span>
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {mode === 'list' ?
                  <>
                    <BlogList data={data} />
                    {data && data.count > 12 &&
                      <Pagination
                        totalItems={data.count}
                        pageSize={pageSize}
                        currentPage={page}
                        setCurrentPage={setPage}
                        selectPageSize={selectPageSize}
                      >
                      </Pagination>
                    }
                  </> :
                  <>
                    <BlogDetail blogId={blogId} />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {isLoading && <FixedOverlayLoadingSpinner />}


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div >

  );


}

export default Blogs