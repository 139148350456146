import React from "react";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import "./DriverCreate.scss"

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"


import Pagination from "../../../Pagination";


const DriverCreate = () => {

  let navigate = useNavigate();
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    postcode: null,
    country: null,
    is_premium: null,
    search_key: null


  });
  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(10);

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isActionModalOpen, setActionModalOpen] = useState(false);
  const [idSelected, setIdSelected] = useState(0);

  const [isFilterDropOpen, setFilterDropOpen] = useState([false, false, false]);


  const [tabSelected, setTabSelected] = useState(0);


  const [countIndicator, setCountIndicator] = useState([null, null, null, null, null])

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])





  return (
    <div >
      <div class="page-body">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <h5>Drivers</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <ul class="nav setting-main-box driver-main-box sticky theme-scrollbar" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">
                    <li>
                      <button class="nav-link active" id="Settings-tab"
                        data-bs-toggle="pill" data-bs-target="#Settings" role="tab"
                        aria-controls="Settings" aria-selected="true">
                        <i class="ri-settings-line"></i>Driver Details</button>
                    </li>
                    <li>
                      <button class="nav-link" id="Info-tab" data-bs-toggle="pill"
                        data-bs-target="#Info" role="tab"
                        aria-controls="Info" aria-selected="false">
                        <i class="ri-information-2-line"></i>Driver (Activate/Deactivate)</button>
                    </li>
                    <li>
                      <button class="nav-link" id="Image-tab" data-bs-toggle="pill"
                        data-bs-target="#Image" role="tab" aria-controls="Image"
                        aria-selected="false">
                        <i class="ri-image-circle-fill"></i>Car Details</button>
                    </li>
                  </ul>
                </div>
                <div class="col-12">
                  <div class="restaurant-tab">
                    <div class="tab-content" id="v-pills-tabContent">
                      <div class="tab-pane fade show active" id="Settings" role="tabpanel"
                        aria-labelledby="Settings-tab">
                        <div class="input-items">
                          <div class="row gy-3">
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Name</h6>
                                <input type="text" name="text" placeholder="Enter Your Name" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Last Name</h6>
                                <input type="text" name="text" placeholder="Enter Your Last Name" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Email</h6>
                                <input type="email" name="email" placeholder="Enter Your Email" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Phone</h6>
                                <input type="number" name="number" placeholder="Enter Your Number" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Password</h6>
                                <input type="password" name="password" placeholder="Enter Your Password" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Do not Know your cordinates ? </h6>
                                <p class="latitude-box" data-bs-toggle="modal" data-bs-target="#latitude-box" title="Quick View" tabindex="0">
                                  Click Here
                                </p>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-box">
                                <h6>Image</h6>
                                <span class="choose-img-box" data-bs-toggle="modal" data-bs-target="#select-img" title="Quick View" tabindex="0">
                                  <i class="ri-add-circle-line"></i>
                                  Choose image </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Info" role="tabpanel"
                        aria-labelledby="Info-tab">
                        <div class="service-box">
                          <div class="row gy-3">
                            <div class="col-12">
                              <div class="service-item">
                                <input class="custom-checkbox" id="category9" type="radio" name="text" />
                                <label for="category9">Active</label>
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="service-item">
                                <input class="custom-checkbox" id="category10" type="radio" name="text" />
                                <label for="category10">Inactive</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="Image" role="tabpanel"
                        aria-labelledby="Image-tab">
                        <div class="input-items">
                          <div class="row gy-3">
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Car Number</h6>
                                <input type="text" name="text" placeholder="Enter Number" />
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="input-box">
                                <h6>Car Name</h6>
                                <input type="text" name="text" placeholder="Enter Name" />
                              </div>
                            </div>
                            <div class="col-12">
                              <div class="input-box">
                                <h6>Car Image</h6>
                                <span class="choose-img-box" data-bs-toggle="modal" data-bs-target="#select-img" title="Quick View" tabindex="0">
                                  <i class="ri-add-circle-line"></i>
                                  Choose image </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn save-button mt-2" type="submit">Save</button>
            </div>
          </div>
        </div>

      </div>

      {/* modal */}
      <div class="modal theme-modal fade" id="select-img" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Insert Media</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-line"></i>
              </button>
            </div>
            <div class="modal-body pt-0">
              <div class="variant-box">
                <div class="variant-images">
                  <input class="btn-check" id="option5" type="radio" name="options-base"
                    checked="" />
                    <label class="btn" for="option5"><img class="img-fluid" src="/images/admin-panel/modal/logo.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option6" type="radio" name="options-base" />
                    <label class="btn" for="option6"><img class="img-fluid" src="/images/admin-panel/modal/p-18.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option7" type="radio" name="options-base" />
                    <label class="btn" for="option7"><img class="img-fluid"
                      src="/images/admin-panel/modal/person2.png" alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option8" type="radio" name="options-base" />
                    <label class="btn" for="option8"><img class="img-fluid" src="/images/admin-panel/modal/p-15.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option9" type="radio" name="options-base" />
                    <label class="btn" for="option9"><img class="img-fluid" src="/images/admin-panel/modal/p-17.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option10" type="radio" name="options-base" />
                    <label class="btn" for="option10"><img class="img-fluid"
                      src="/images/admin-panel/modal/person3.png" alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option11" type="radio" name="options-base" />
                    <label class="btn" for="option11"><img class="img-fluid" src="/images/admin-panel/modal/p-2.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option12" type="radio" name="options-base" />
                    <label class="btn" for="option12"><img class="img-fluid" src="/images/admin-panel/modal/p-6.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option13" type="radio" name="options-base" />
                    <label class="btn" for="option13"><img class="img-fluid" src="/images/admin-panel/modal/1.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option14" type="radio" name="options-base" />
                    <label class="btn" for="option14"><img class="img-fluid" src="/images/admin-panel/modal/p-1.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option15" type="radio" name="options-base" />
                    <label class="btn" for="option15"><img class="img-fluid" src="/images/admin-panel/modal/p-2.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option16" type="radio" name="options-base" />
                    <label class="btn" for="option16"><img class="img-fluid"
                      src="/images/admin-panel/modal/1-white.png" alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option17" type="radio" name="options-base" />
                    <label class="btn" for="option17"><img class="img-fluid" src="/images/admin-panel/modal/p-5.png"
                      alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option18" type="radio" name="options-base" />
                    <label class="btn" for="option18"><img class="img-fluid"
                      src="/images/admin-panel/modal/p-17.png" alt="" /></label>
                </div>
                <div class="variant-images">
                  <input class="btn-check" id="option19" type="radio" name="options-base" />
                    <label class="btn" for="option19"><img class="img-fluid" src="/images/admin-panel/modal/p-3.png"
                      alt="" /></label>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-cancel" type="button" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button>
              <button class="btn btn-submit" type="submit" data-bs-dismiss="modal" aria-label="Close">Upload
                Image</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal theme-modal add-hours fade" id="latitude-box" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Latitude and Longitude Finder</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-line"></i>
              </button>
            </div>
            <div class="modal-body">
              <div class="row gy-3">
                <div class="col-12">
                  <p class="mb-0">* Do not Know your cordinates ? use
                    <a href="https://www.latlong.net/" target="_blank" class="font-danger">Latitude and Longitude Finder</a>
                  </p>
                </div>
                <div class="col-6">
                  <label>Latitude</label>
                  <input type="number" name="number" />
                </div>
                <div class="col-6">
                  <label>Longitude</label>
                  <input type="number" name="number" />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-cancel" type="button" data-bs-dismiss="modal"
                aria-label="Close">Cancel</button>
              <button class="btn btn-submit" type="submit" data-bs-dismiss="modal" aria-label="Close">Save</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal theme-modal fade" id="staticBackdrop" tabindex="-1" role="dialog" aria-modal="true">
        <div class="modal-dialog modal-md modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5>Logging Out</h5>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">
                <i class="ri-close-line"></i>
              </button>
            </div>
            <div class="modal-body">
              <p class="mb-0">Are you sure you want to log out?</p>
            </div>
            <div class="modal-footer">
              <button class="btn btn-cancel" type="button" data-bs-dismiss="modal"
                aria-label="Close">No</button>
              <button class="btn btn-submit" type="submit" data-bs-dismiss="modal" aria-label="Close"><a href="login.html">Yes</a></button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}


export default DriverCreate