// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-category-list .image-instruction {
  color: rgb(147, 143, 143);
  font-size: 10px;
  margin-bottom: 10px;
  line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/MenuCategoryList/MenuCategoryList.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;EACA,eAAA;EACA,mBAAA;EACA,gBAAA;AAAR","sourcesContent":[".menu-category-list{\r\n    .image-instruction {\r\n        color: rgb(147, 143, 143);\r\n        font-size: 10px;\r\n        margin-bottom: 10px;\r\n        line-height: 1.4;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
