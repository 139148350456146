import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import './LeftNavbar.scss';
import API from '../../API';
import 'simplebar-react/dist/simplebar.min.css';
import '../common-components/css/vendors/scrollbar.css'


export default function LeftNavbar() {

  const navigate = useNavigate();
  const location = useLocation();

  const [navList, setNavList] = useState([])
  const [activeItemId, setActiveItemId] = useState(null);
  const [activeSubItemId, setActiveSubItemId] = useState(null);

  // default
  const [navList2, setNavList2] = useState(
    [
      {
        "id": 1,
        "label": "Dashboard",
        "frontend_url": "/admin/dashboard",
        "icon_image_url": "/images/admin-panel/left-nav/dashboard.svg",
        "position": 1
      },
      {
        "id": 2,
        "label": "Restaurants",
        "icon_image_url": "/images/admin-panel/left-nav/restaurant.svg",
        "frontend_url": "/admin/restaurant/list",
        "items": [
          {
            "id": 1,
            "label": "Restaurants List",
            "frontend_url": "/admin/restaurant/list",
            "position": 1,
          },
          {
            "id": 2,
            "label": "Create Restaurants",
            "frontend_url": "/admin/restaurant/add",
            "position": 2,
          }
        ],
        "position": 2
      },
      {
        "id": 3,
        "label": "Foods",
        "frontend_url": "/admin/menu-item/list",
        "icon_image_url": "/images/admin-panel/left-nav/foods.svg",
        "items": [
          {
            "id": 7,
            "label": "Food List",
            "frontend_url": "/admin/menu-item/list",
            "icon_image_url": "icon-image-mass",
            "position": 1,
          },
          {
            "id": 8,
            "label": "Create Food",
            "frontend_url": "/admin/menu-item/add",
            "icon_image_url": "icon-image-events",
            "position": 2,
          }
        ],
        "position": 3
      },
      {
        "id": 4,
        "label": "Category",
        "frontend_url": "/admin/menu-category/list",
        "icon_image_url": "/images/admin-panel/left-nav/category.svg",
        "items": [],
        "position": 4
      },
      {
        "id": 5,
        "label": "Cuisine",
        "frontend_url": "/admin/cuisine/list",
        "icon_image_url": "/images/admin-panel/left-nav/cuisine.svg",
        "items": [],
        "position": 5
      },
      {
        "id": 6,
        "label": "Orders",
        "frontend_url": "/admin/orders/list",
        "icon_image_url": "/images/admin-panel/left-nav/orders.svg",
        "items": [],
        "position": 6
      },
      {
        "id": 7,
        "label": "Reviews",
        "frontend_url": "/admin/order-review/list",
        "icon_image_url": "/images/admin-panel/left-nav/review.svg",
        "items": [
          {
            "id": 1,
            "label": "Order Reviews",
            "frontend_url": "/admin/order-review/list",
            "position": 1,
          },
          {
            "id": 2,
            "label": "Order Item Reviews",
            "frontend_url": "/admin/order-item-review/list",
            "position": 2,
          }
        ],
        "position": 7
      },
      {
        "id": 8,
        "label": "Invoice",
        "frontend_url": "/admin/invoice/list",
        "icon_image_url": "/images/admin-panel/left-nav/invoice.svg",
        "items": [],
        "position": 8
      },
      {
        "id": 9,
        "label": "Users",
        "frontend_url": "/admin/users/list",
        "icon_image_url": "/images/admin-panel/left-nav/users.svg",
        "items": [
          {
            "id": 1,
            "label": "All Users",
            "frontend_url": "/admin/users/list",
            "position": 1,
          },
          {
            "id": 2,
            "label": "Add New User",
            "frontend_url": "/admin/users/add",
            "position": 2,
          }
        ],
        "position": 9
      },
      {
        "id": 10,
        "label": "Support Tickets",
        "frontend_url": "/admin/users/list",
        "icon_image_url": "/images/admin-panel/left-nav/tickets.svg",
        "items": [
          {
            "id": 1,
            "label": "Ticket List",
            "frontend_url": "/admin/tickets/list",
            "position": 1,
          },
          {
            "id": 2,
            "label": "Add Ticket",
            "frontend_url": "/admin/tickets/add",
            "position": 2,
          }
        ],
        "position": 10
      },
      {
        "id": 11,
        "label": "Reports",
        "frontend_url": "/admin/reports",
        "icon_image_url": "/images/admin-panel/left-nav/reports.svg",
        "items": [],
        "position": 11
      },
      {
        "id": 12,
        "label": "Drivers",
        "frontend_url": "/admin/drivers",
        "icon_image_url": "/images/admin-panel/left-nav/drivers.svg",
        "items": [],
        "position": 12
      },
      {
        "id": 13,
        "label": "Currencies",
        "frontend_url": "/admin/currency/list",
        "icon_image_url": "/images/admin-panel/left-nav/currency.svg",
        "items": [],
        "position": 13
      },
      {
        "id": 14,
        "label": "Settings",
        "frontend_url": "/admin/profile",
        "icon_image_url": "/images/admin-panel/left-nav/settings.svg",
        "items": [
          {
            "id": 1,
            "label": "Edit Profile",
            "frontend_url": "/admin/profile",
            "position": 1,
          }
        ],
        "position": 14
      }
    ]);


  const loadData = () => {
    API.get(`/user/frontend-categories/`)
      .then(response => {
        const responseNavList = response.data
        setNavList(responseNavList)
      })
      .catch(error => {
        console.error(error);
      });
  }

  useEffect(() => {
    loadData();
  }, [])

  // Check if the current URL matches the item's URL
  const isActive = (itemUrl) => location.pathname === itemUrl;

  // Check if any sub-item under the given main item is active
  const isSubItemActive = (subItems) => subItems.some(subItem => isActive(subItem.frontend_url));


 

  const handleNavClick = (navItem) => {
    if (navItem.items && navItem.items.length > 0) {
      const newActiveItemId = navItem.id === activeItemId ? null : navItem.id;
      setActiveItemId(newActiveItemId);
      setActiveSubItemId(null); // Reset sub-item active state when toggling main item
      
    } else {
      navigate(navItem.frontend_url);
      setActiveItemId(navItem.id);
      setActiveSubItemId(null); 
    }
  };

  const handleSubNavClick = (subItem) => {
    setActiveSubItemId(subItem.id);
    navigate(subItem.frontend_url);
  };




  return (

    <div className="sidebar-wrapper">
      <div>
        <div className="logo-wrapper logo-wrapper-center">
          <a href="/admin" data-bs-original-title="" title="">
            <img className="img-fluid for-white" src="/images/admin-panel/logo/full-white.svg" alt="logo"></img>
          </a>
          <div className="back-btn">
            <i className="fa fa-angle-left"></i>
          </div>
          <div className="toggle-sidebar">
            <i className="ri-apps-2-line status_toggle middle sidebar-toggle"></i>
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <a href="/admin">
            <img className="img-fluid main-logo" src="/images/admin-panel/logo/logo.png" alt="logo"></img>
          </a>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" id="left-arrow">
            <i data-feather="arrow-left"></i>
          </div>

          <div id="sidebar-menu" className=''>
            <ul className="sidebar-links" id="simple-bar">
              <li className="back-btn"></li>
              {navList.map((navItem) => (
                <li key={navItem.id} className="sidebar-list">
                  <a className={`sidebar-link sidebar-title link-na  ${isActive(navItem.frontend_url) || isSubItemActive(navItem.items) ? 'active' : ''}`}
                    onClick={() => handleNavClick(navItem)} >
                    <img src={navItem.icon_image_url} alt={navItem.label} className="sidebar-icon" />
                    <span>{navItem.label}</span>
                    {navItem.items && navItem.items.length > 0 && (
                      <div className="according-menu">
                        <i className={`ri-arrow-${(isActive(navItem.frontend_url) || isSubItemActive(navItem.items)) ? 'down' : 'right'}-s-line`}></i>
                      </div>
                    )}
                  </a>

                  {navItem.items && activeItemId === navItem.id && (
                    <ul className="sidebar-submenu">
                      {navItem.items.map((subItem) => (
                        <li key={subItem.id}>
                          <a className={`${isActive(subItem.frontend_url) ? 'active' : ''}`}
                            onClick={() => handleSubNavClick(subItem)} >
                            {subItem.label}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>

          </div>

          

          <div className="right-arrow" id="right-arrow">
            <i data-feather="arrow-right"></i>
          </div>
        </nav>
      </div>
    </div>





  )
}
