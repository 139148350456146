import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FixedOverlayLoadingSpinner from "../../FixedOverlayLoadingSpinner"
import API from '../../API';
import { useContext } from 'react';
import { UserContext } from './UserContext';

const SocialAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const { setIsLoggedIn, setRedirectUrl } = useContext(UserContext);

  const LocalredirectUrl = localStorage.getItem('LoginRedirectURL');


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const provider = location.pathname.split('/')[2]; // Extract provider from URL path

    if (code) {
      API.get(`/social_auth/${provider}/callback/?code=${code}`)
        .then(response => {
          // Store token in localStorage 
          localStorage.setItem('accessToken', response.data.access_token);
          localStorage.setItem('userID', response.data.user_id);
          localStorage.setItem('refreshToken', response.data.refresh_token);
          localStorage.setItem('userRole', response.data.role)
          setIsAuthenticated(true);
          if (response.data.is_deactivated === true) {
            localStorage.setItem('deactivatedUser', 'true');
            setIsLoggedIn(false);
            setRedirectUrl("/account-deactivated")
            navigate('/account-deactivated')
          }
          else {
            setIsLoggedIn(true);
            if (LocalredirectUrl) {
              navigate(LocalredirectUrl)
            } else {
              if (['admin', 'restaurant', 'driver', 'staff'].includes(response.data.role)) {
                navigate('/admin')

              }
              else {

                navigate('/')
              }
            }

          }
        })
        .catch(error => {
          console.error('Authentication failed', error);
          setError('Authentication failed. Please try again.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setError('Invalid redirect URL');
      setIsLoading(false);
    }
  }, []);

  return (
    <div>
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  );
};

export default SocialAuthCallback;
