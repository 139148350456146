// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: #0770a8;
}

.admin-page-not-found .error-section {
  display: flex;
  overflow-y: hidden;
  min-height: 570px;
}
.admin-page-not-found .error-section .banner-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: calc(10px + 10 * (100vw - 320px) / 1600);
  padding: calc(30px + 70 * (100vw - 320px) / 1600) 0;
  width: 100%;
}
.admin-page-not-found .error-section .banner-content .banner-img {
  width: calc(150px + 150 * (100vw - 320px) / 1600);
  height: auto;
}
.admin-page-not-found .error-section .banner-content p {
  font-size: calc(10px + 4 * (100vw - 320px) / 1600);
  color: rgba(var(--content-color), 1);
  margin: 0 auto;
  width: 70%;
  letter-spacing: 0.5px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/Layout.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;AACF;;AAEA;EACE,aAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,6CAAA;EACA,mDAAA;EACA,WAAA;AAEJ;AADI;EACE,iDAAA;EACA,YAAA;AAGN;AAAI;EACE,kDAAA;EACA,oCAAA;EACA,cAAA;EACA,UAAA;EACA,qBAAA;AAEN","sourcesContent":[".auth-mask {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100vh;\r\n  width: 100%;\r\n  color: #0770a8;\r\n}\r\n\r\n.admin-page-not-found .error-section {\r\n  display: flex;\r\n  overflow-y: hidden;\r\n  min-height: 570px;\r\n  .banner-content {\r\n    display: flex;\r\n    justify-content: center;\r\n    flex-direction: column;\r\n    gap: calc(10px + 10 * (100vw - 320px) / 1600);\r\n    padding: calc(30px + 70 * (100vw - 320px) / 1600) 0;\r\n    width: 100%;\r\n    .banner-img {\r\n      width: calc(150px + 150 * (100vw - 320px) / 1600);\r\n      height: auto;\r\n    }\r\n\r\n    p {\r\n      font-size: calc(10px + 4 * (100vw - 320px) / 1600);\r\n      color: rgba(var(--content-color), 1);\r\n      margin: 0 auto;\r\n      width: 70%;\r\n      letter-spacing: 0.5px;\r\n    }\r\n  }\r\n}\r\n\r\n@media(max-width:991px) {}\r\n\r\n@media(max-width:576px) {}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
