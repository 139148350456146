// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-select-container .current-location {
  padding: 5px 0;
  border-bottom: 1px rgb(213, 213, 213) solid;
  display: flex;
  gap: 10px;
  font-size: calc(14px + 4 * (100vw - 320px) / 1600);
}
.location-select-container .current-location h5 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  font-weight: 600;
  line-height: 1;
  color: rgba(var(--theme-color), 1);
}
.location-select-container .current-location .current-btn {
  color: rgba(var(--theme-color), 1);
}
.location-select-container .current-location .focus {
  font-weight: 600;
  color: rgba(var(--theme-color), 1);
}
.location-select-container .current-location .current-address h6 {
  width: 100%;
  font-size: 14px;
  margin-top: 4px;
  color: rgba(var(--content-color), 1);
}
.location-select-container input {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/MapLocationSelector/MapLocationSelector.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,2CAAA;EACA,aAAA;EACA,SAAA;EACA,kDAAA;AAAR;AAEQ;EACI,kDAAA;EACA,gBAAA;EACA,cAAA;EACA,kCAAA;AAAZ;AAGQ;EACI,kCAAA;AADZ;AAIQ;EACI,gBAAA;EACA,kCAAA;AAFZ;AAKQ;EACI,WAAA;EACA,eAAA;EACA,eAAA;EACA,oCAAA;AAHZ;AAOI;EACI,eAAA;AALR","sourcesContent":[".location-select-container {\r\n    .current-location {\r\n        padding: 5px 0;\r\n        border-bottom: 1px rgb(213, 213, 213) solid;\r\n        display: flex;\r\n        gap: 10px;\r\n        font-size: calc(14px + 4 * (100vw - 320px) / 1600);\r\n\r\n        h5 {\r\n            font-size: calc(14px + 2 * (100vw - 320px) / 1600);\r\n            font-weight: 600;\r\n            line-height: 1;\r\n            color: rgba(var(--theme-color), 1);\r\n        }\r\n\r\n        .current-btn {\r\n            color: rgba(var(--theme-color), 1);\r\n        }\r\n\r\n        .focus {\r\n            font-weight: 600;\r\n            color: rgba(var(--theme-color), 1);\r\n        }\r\n\r\n        .current-address h6 {\r\n            width: 100%;\r\n            font-size: 14px;\r\n            margin-top: 4px;\r\n            color: rgba(var(--content-color), 1);\r\n        }\r\n    }\r\n\r\n    input {\r\n        font-size: 14px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
