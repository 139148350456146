import React, { useEffect, useState, useRef } from 'react'

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
import 'swiper/css/navigation'; // Import Navigation styles

import { Navigation } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import API from '../../../API.js';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { formatTimeFromMinutes } from '../../../GeneralFunctions.js';

import "./post-6.scss";
import "./post-1666.scss";
import "./post-18826.scss";
import "./post-199.scss";

import "./home.scss";
import "./home2.scss";
import "./home3.scss";
import "./responsive.scss";





const Userhome = ({ userData, loadUserData, setSearchKey, searchKey, selectCategory, location }) => {
  const navigate = useNavigate();


  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [bannerSoundOn, setBannerSoundOn] = useState(false)
  const [videoSrc, setVideoSrc] = useState('');
  const [suggestDropDownList, setSSuggestDropDownList] = useState(null)
  const [suggestDropDownOpen, setSuggestDropDownOpen] = useState(false);
  const [filters, setFilters] = useState({
    latitude: localStorage.getItem('lat') || null,
    longitude: localStorage.getItem('lng') || null,
  });
  const [isLoadingSections, setIsLoadingSections] = useState({
    brands: false,
    popularRestaurants: false,
    deals: false,
  });

  // slider data
  const [categories, setCategories] = useState(null);
  const [dealsList, setDealsList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [popularRestaurants, setPopularRestaurants] = useState([]);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const [opacity, setOpacity] = useState(0);


  useEffect(() => {
    localStorage.removeItem('searchKey');
    // Ensure the DOM is fully loaded
    $(document).ready(function () {
      // Get the height of the main-content div
      const mainContentHeight = $('.main-content').height();

      // Apply the same height to the side-pane and page divs
      $('.side-pane').height(mainContentHeight);
      $('.page').height(mainContentHeight);
    });
  }, []);

  const Search = (key) => {
    var searchInput;
    if (window.innerWidth <= 768) {
      searchInput = document.getElementById("search-ip-mob")
    }
    else {
      searchInput = document.getElementById("search-ip-web")
    }
    setSearchKey(searchInput.value);
    localStorage.setItem('searchKey', searchInput.value);
    if (key === "Enter") {
      const searchKEY = searchInput.value

      setSearchKey(searchKEY);
      navigate("/shop");
      setSuggestDropDownOpen(false)
    }
    else {

      const searchKEY = searchInput.value;
      setSearchKey(searchKEY);
      API.get(`/restaurants/user_search_result/?page=1&page_size=10&search_key=${searchKEY}`)
        .then(response => {
          setSSuggestDropDownList(response.data.results);
          setSuggestDropDownOpen(true)
        })
        .catch(error => {
          console.log(error.message);

        });
    }

  }

  const handleSearchButton = () => {
    if (searchKey) {
      localStorage.setItem('searchKey', searchKey);
    }
    navigate("/shop");
  }

  $(document).mouseup(function (e) {
    var container = $(".search-seggestion-dropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {

      setSuggestDropDownOpen(false)


    }
  });

  const loadCategories = () => {
    API.get('/restaurants/menu-categories')
      .then(response => {
        setCategories(response.data.results);
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        // setIsErrorModalOpen(true);
      });
  }

  const loadBrandsList = () => {
    let apiUrl;
    if (location.current_latitude === 'null' || location.current_latitude === null) {
      apiUrl = `/restaurants/user_search_result/`;
      return;
    } else {
      apiUrl = `/restaurants/user_search_result/?latitude=${location.current_latitude}&longitude=${location.current_longitude}`;
    }
    setIsLoadingSections(prevState => ({
      ...prevState,
      brands: true,
    }));
    API.get(apiUrl)
      .then(response => {
        setBrandsList(response.data.results);
        setIsLoadingSections(prevState => ({
          ...prevState,
          brands: false,
        }));
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsErrorModalOpen(true);
        setIsLoadingSections(prevState => ({
          ...prevState,
          brands: false,
        }));
      });
  }

  const loadPopularRestaurants = () => {
    let apiUrl;
    if (location.current_latitude === 'null' || location.current_latitude === null) {
      return;
    } else {
      apiUrl = `/restaurants/user_search_result/?latitude=${location.current_latitude}&longitude=${location.current_longitude}&sort_by=popular`;
    }
    setIsLoadingSections(prevState => ({
      ...prevState,
      popularRestaurants: true,
    }));

    API.get(apiUrl)
      .then(response => {
        setPopularRestaurants(response.data.results);
        setIsLoadingSections(prevState => ({
          ...prevState,
          popularRestaurants: false,
        }));
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsLoadingSections(prevState => ({
          ...prevState,
          popularRestaurants: false,
        }));
      });
  }

  const loadDeals = () => {
    let apiUrl;
    if (location.current_latitude === 'null' || location.current_latitude === null) {
      return;
    } else {
      apiUrl = `/payments/coupons/available/?latitude=${location.current_latitude}&longitude=${location.current_longitude}&is_expired=false`;
    }
    setIsLoadingSections(prevState => ({
      ...prevState,
      deals: true,
    }));

    API.get(apiUrl)
      .then(response => {
        setDealsList(response.data.results);
        setIsLoadingSections(prevState => ({
          ...prevState,
          deals: false,
        }));
      })
      .catch(error => {
        setMessage(error.response?.data?.message || error.message);
        setIsLoadingSections(prevState => ({
          ...prevState,
          deals: false,
        }));
      });
  }

  useEffect(() => {
    loadBrandsList();
    loadPopularRestaurants();
    loadDeals();
  }, [location])

  useEffect(() => {
    selectCategory(null)
    loadCategories();
  }, [])

  const CategorySwiperBreakpoints = {
    320: {
      slidesPerView: 2, // 1 slide per view on small screens
    },
    420: {
      slidesPerView: 3, // 2 slides per view on small screens
    },
    768: {
      slidesPerView: 4, // 4 slides per view on medium screens
    },
    1024: {
      slidesPerView: 6, // 6 slides per view on large screens
    },
    1440: {
      slidesPerView: 7, // 8 slides per view on extra-large screens
    },
  };

  const todayDealSwiperBreakpoints = {
    320: {
      slidesPerView: 2, // 1 slide per view on small screens
    },
    420: {
      slidesPerView: 3, // 2 slides per view on small screens
    },
    768: {
      slidesPerView: 4, // 4 slides per view on medium screens
    },
    1200: {
      slidesPerView: 4, // 4 slides per view on large screens
    },
    1440: {
      slidesPerView: 5, // 4 slides per view on extra-large screens
    },
  };

  const BrandSwiperBreakpoints = {
    320: {
      slidesPerView: 2,
    },
    420: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 6,
    },
    1440: {
      slidesPerView: 7,
    },
  };

  const handleRestaurantCardClick = (item) => {
    localStorage.setItem('filters', JSON.stringify(filters));
    localStorage.setItem('selectedItemID', item.id);
    navigate('/restaurant-menu')
  }

  const getCouponDescription = (data) => {
    const {
      discount_type,
      discount_value
    } = data;

    let discountDescription = '';
    if (discount_type === 'flat') {
      discountDescription = `Flat cashback of £${discount_value}`;
    } else if (discount_type === 'percentage') {
      discountDescription = `Up to ${discount_value}% OFF`;
    } else {
      discountDescription = 'Not Available.';
    }
    return discountDescription
  }

  return (


    <div id="page" className="hfeed site home-page">



      <div data-elementor-type="wp-page" data-elementor-id="2" className="elementor elementor-2">

        <section className="elementor-section elementor-top-section elementor-element elementor-element-10d1e59 elementor-section-items-stretch elementor-section-height-min-height elementor-reverse-mobile elementor-section-boxed elementor-section-height-default" data-id="10d1e59" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-79628975" data-id="79628975" data-element_type="column">
              <div className="elementor-widget-wrap elementor-element-populated">
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-172229de elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="172229de" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-66f5d7f9" data-id="66f5d7f9" data-element_type="column">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-3d650be5 elementor-widget elementor-widget-heading" data-id="3d650be5" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <link rel="stylesheet" href="css/style30.css" />
                            <h1 className="elementor-heading-title elementor-size-default">Secure Your <br /><span style={{ color: "#CFDD28" }}>Financial Future</span><br /> with Confidence</h1>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-52de05d7 elementor-widget elementor-widget-heading" data-id="52de05d7" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-heading-title elementor-size-default">As your reliable Financial Partner, we’re here to guide you every step of the way—whether you're investing, planning for retirement, or buying your first home. </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-b616bed elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="b616bed" data-element_type="section" data-settings="{&quot;animation_mobile&quot;:&quot;none&quot;}">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7c9e5703" data-id="7c9e5703" data-element_type="column">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-4aed05b elementor-align-right elementor-widget__width-auto elementor-mobile-align-justify elementor-widget-mobile__width-inherit elementor-widget elementor-widget-button" data-id="4aed05b" data-element_type="widget" data-widget_type="button.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a className="elementor-button elementor-button-link elementor-size-sm" data-savepage-href="#" href="contact-us">
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-text">Talk To Our Experts </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-3728723d elementor-align-center elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-button" data-id="3728723d" data-element_type="widget" data-widget_type="button.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a className="elementor-button elementor-button-link elementor-size-sm" data-savepage-href="#" href="/services">
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-icon elementor-align-icon-left">
                                    <i aria-hidden="true" className="fas fa-mouse"></i> </span>
                                  <span className="elementor-button-text" onClick={() => navigate("contact-us")}>Explore Our Services</span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-768b900d" data-id="768b900d" data-element_type="column">

              {/* <img src='/images/home/sec-1.png'></img> */}
            </div>
          </div>
        </section>


        <div className="footer-width-fixer elementor-1666 sec-2">
          <div data-elementor-type="wp-post" data-elementor-id="18811" className="elementor elementor-18811">
            <section className="elementor-section elementor-top-section elementor-element elementor-element-28b4552 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="28b4552" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
              <div className="elementor-background-overlay"></div>
              <div className="elementor-container elementor-column-gap-no">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-bf61e5b" data-id="bf61e5b" data-element_type="column">
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section className="elementor-section elementor-inner-section elementor-element elementor-element-78d4c3d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="78d4c3d" data-element_type="section">
                      <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-450f210" data-id="450f210" data-element_type="column">
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div className="elementor-element elementor-element-d7680dd elementor-widget elementor-widget-heading" data-id="d7680dd" data-element_type="widget" data-widget_type="heading.default">
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">Welcome to <br></br><strong>Reserve Financial Services </strong></h2>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </section>


                  </div>
                </div>
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-21f7fe7" style={{ marginTop: "30px", marginBottom: "30px" }} data-id="21f7fe7" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                  <div className="elementor-element elementor-element-d829c22 elementor-widget elementor-widget-text-editor" data-id="d829c22" data-element_type="widget" data-widget_type="text-editor.default">
                    <div className="elementor-widget-container">
                      At the heart of everything we do is your financial well-being. When you choose to work with us, we promise to provide an exceptional, personal service tailored to your unique financial dreams and aspirations. </div>
                  </div>

                  <section className="elementor-section elementor-inner-section elementor-element elementor-element-dadbb46 elementor-widget__width-initial elementor-widget-mobile__width-inherit elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="dadbb46" data-element_type="section">
                      <div className="elementor-container elementor-column-gap-extended">
                        <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-209b773" data-id="209b773" data-element_type="column">


                          {/* add */}
                          <div className="elementor-element elementor-element-47729f18 elementor-widget elementor-widget-qi_addons_for_elementor_button"
                            data-id="47729f18" data-element_type="widget"
                            data-widget_type="qi_addons_for_elementor_button.default">
                            <div className="elementor-widget-container">
                              <a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--filled qodef-type--standard  qodef-hover--reveal qodef--reveal-horizontal qodef-icon--right qodef-hover--icon-move-horizontal     "
                                data-savepage-href="#" href="/contact-us"
                                target="_self">
                                <span className="qodef-m-text">Contact Us</span>
                                <span className="qodef-m-icon ">
                                  <span className="qodef-m-icon-inner">
                                    <i aria-hidden="true" className="jki jki-arrow-right-solid"></i> <i
                                      aria-hidden="true" className="jki jki-arrow-right-solid"></i> </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                </div>
              </div>
            </section>
          </div>
        </div>


        <div className="body_wrap">
          <div className="page_wrap">
            <a className="zurich_skip_link skip_to_content_link" href="#content_skip_link_anchor" tabindex="1">Skip to content</a> <a className="zurich_skip_link skip_to_footer_link" href="#footer_skip_link_anchor" tabindex="1">Skip to footer</a>

            <div className="menu_mobile_overlay scheme_dark inited_click" style={{ display: "none" }}>
            </div>
            <div className="menu_mobile menu_mobile_fullscreen scheme_dark inited_arrows inited_click">
              <div className="menu_mobile_inner with_widgets">
                <div className="menu_mobile_header_wrap">
                  <a className="sc_layouts_logo" href="#">
                    <img src='' alt="Zurich" width="145" height="29" data-savepage-loading="lazy" />
                  </a>
                  <a className="menu_mobile_close menu_button_close inited_click" tabindex="0">
                    <span className="menu_button_close_text">Close</span>
                    <span className="menu_button_close_icon"></span>
                  </a> </div>

              </div>

            </div>
       
              <div className="content_wrap_fullscreen">
                <div className="content">
                  <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#"></a>
                  <article id="post-3847" className="post_item_single post_type_page post-3847 page type-page status-publish hentry">
                    <div className="post_content entry-content">
                      <div data-elementor-type="wp-page" data-elementor-id="3847" className="elementor elementor-3847">



                        <section className="elementor-section elementor-top-section elementor-element elementor-element-262e7e8d elementor-section-full_width elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="262e7e8d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div className="elementor-container elementor-column-gap-no">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5be3b462 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5be3b462" data-element_type="column">
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-5377997e sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5377997e" data-element_type="widget" data-widget_type="spacer.default">
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-element elementor-element-7e0b5c7 sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7e0b5c7" data-element_type="widget" data-widget_type="spacer.default">
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                                <div data-animation-type="block" className="elementor-element elementor-element-63dcfa48 elementor-widget__width-initial animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="63dcfa48" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zurich-fadeinup&quot;}" data-widget_type="trx_sc_title.default">
                                  <div className="elementor-widget-container">
                                    <div className="sc_title sc_title_default">
                                      <h1 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">We Simplify Complex Financial Decisions </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>

                                <section data-animation-type="block" className="elementor-section elementor-inner-section elementor-element elementor-element-20ac1aae elementor-section-full_width elementor-section-height-default elementor-section-height-default animation_type_block sc_fly_static sc_cover_link_present trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="20ac1aae" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;zurich-fadeinup&quot;}"><a href="#" className="sc_cover sc_cover_default inited" data-place="row"></a>
                                  <div className="elementor-container elementor-column-gap-extended">
                                    
                                  <div className='custom-box-zog'>
                                   
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-32a31638 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="32a31638" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">

                                        <div className="elementor-element elementor-element-1322abe1 num-hover sc_fly_static elementor-widget elementor-widget-heading trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1322abe1" data-element_type="widget" data-widget_type="heading.default">
                                          <div className="elementor-widget-container">
                                            <h5 className="elementor-heading-title elementor-size-default">01</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2f55d2e2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2f55d2e2" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-4e06ba33 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="4e06ba33" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                          <div className="elementor-widget-container">
                                            <div className="sc_title sc_title_default">
                                              <h3 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Investments & Savings</span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
</div>

                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-130ad8d5 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="130ad8d5" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-544cb685 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="544cb685" data-element_type="widget" data-widget_type="text-editor.default">
                                          <div className="elementor-widget-container">
                                            <p>Maximise your financial potential with our personalised Investments and Savings plans. Let us guide you towards a prosperous future. </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-19a749fe sc_layouts_column_align_right sc_layouts_column sc-mobile_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="19a749fe" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-216ca3da elementor-view-framed elementor-widget__width-auto rotate-hover elementor-shape-circle sc_fly_static elementor-widget elementor-widget-icon trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="216ca3da" data-element_type="widget" data-widget_type="icon.default">
                                          <div className="elementor-widget-container">
                                            <div className="elementor-icon-wrapper"> <a className="elementor-icon" href="#">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="34.356" height="23.642" viewBox="0 0 34.356 23.642"><path id="Path_4452" data-name="Path 4452" d="M33.888,52.608,23.664,42.384A1.6,1.6,0,1,0,21.4,44.643l7.5,7.5H1.6a1.6,1.6,0,0,0,0,3.195H28.9l-7.5,7.5a1.6,1.6,0,0,0,2.259,2.259L33.888,54.867A1.6,1.6,0,0,0,33.888,52.608Z" transform="translate(0 -41.916)" />
                                              </svg> </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>

                                <section data-animation-type="block" className="elementor-section elementor-inner-section elementor-element elementor-element-cf81f1a elementor-section-full_width elementor-section-height-default elementor-section-height-default animation_type_block sc_fly_static sc_cover_link_present trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="cf81f1a" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;zurich-fadeinup&quot;}"><a href="/home/slider-masonry/" className="sc_cover sc_cover_default inited" data-place="row"></a>
                                  <div className="elementor-container elementor-column-gap-extended">
                                    
                                    <div className='custom-box-zog'>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4671327c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="4671327c" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-54e1c371 sc_fly_static elementor-widget elementor-widget-trx_sc_cover trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="54e1c371" data-element_type="widget" data-widget_type="trx_sc_cover.default">
                                          <div className="elementor-widget-container">  </div>
                                        </div>
                                        <div className="elementor-element elementor-element-1322abe1 num-hover sc_fly_static elementor-widget elementor-widget-heading trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2b915cbd" data-element_type="widget" data-widget_type="heading.default">
                                          <div className="elementor-widget-container">
                                            <h5 className="elementor-heading-title elementor-size-default">02</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2f55d2e2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="68588ba1" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-4e06ba33 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="615c4543" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                          <div className="elementor-widget-container">
                                            <div className="sc_title sc_title_default">
                                              <h3 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Mortgage Services</span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>


                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2e599e8d sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2e599e8d" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-5a897d2 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5a897d2" data-element_type="widget" data-widget_type="text-editor.default">
                                          <div className="elementor-widget-container">
                                            <p>Your dream home awaits! Our mortgage services provide tailored solutions and expert guidance to help you navigate the buying process effortlessly. </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-12357837 sc_layouts_column_align_right sc_layouts_column sc-mobile_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="12357837" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-16435afe elementor-view-framed elementor-widget__width-auto rotate-hover elementor-shape-circle sc_fly_static elementor-widget elementor-widget-icon trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="16435afe" data-element_type="widget" data-widget_type="icon.default">
                                          <div className="elementor-widget-container">
                                            <div className="elementor-icon-wrapper"> <a className="elementor-icon" href="#">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="34.356" height="23.642" viewBox="0 0 34.356 23.642"><path id="Path_4452" data-name="Path 4452" d="M33.888,52.608,23.664,42.384A1.6,1.6,0,1,0,21.4,44.643l7.5,7.5H1.6a1.6,1.6,0,0,0,0,3.195H28.9l-7.5,7.5a1.6,1.6,0,0,0,2.259,2.259L33.888,54.867A1.6,1.6,0,0,0,33.888,52.608Z" transform="translate(0 -41.916)" />
                                              </svg> </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>

                                <section data-animation-type="block" className="elementor-section elementor-inner-section elementor-element elementor-element-cf81f1a elementor-section-full_width elementor-section-height-default elementor-section-height-default animation_type_block sc_fly_static sc_cover_link_present trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="cf81f1a" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;zurich-fadeinup&quot;}"><a href="/home/slider-masonry/" className="sc_cover sc_cover_default inited" data-place="row"></a>
                                  <div className="elementor-container elementor-column-gap-extended">
                                    
                                  <div className='custom-box-zog'> 
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-4671327c sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="4671327c" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-54e1c371 sc_fly_static elementor-widget elementor-widget-trx_sc_cover trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="54e1c371" data-element_type="widget" data-widget_type="trx_sc_cover.default">
                                          <div className="elementor-widget-container">  </div>
                                        </div>
                                        <div className="elementor-element elementor-element-1322abe1 num-hover sc_fly_static elementor-widget elementor-widget-heading trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2b915cbd" data-element_type="widget" data-widget_type="heading.default">
                                          <div className="elementor-widget-container">
                                            <h5 className="elementor-heading-title elementor-size-default">03</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2f55d2e2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="68588ba1" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-4e06ba33 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="615c4543" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                          <div className="elementor-widget-container">
                                            <div className="sc_title sc_title_default">
                                              <h3 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Pension and Retirement </span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
</div>

                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2e599e8d sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2e599e8d" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-5a897d2 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="5a897d2" data-element_type="widget" data-widget_type="text-editor.default">
                                          <div className="elementor-widget-container">
                                            <p>Secure your ideal retirement with our personalised pension planning. We help you build a sustainable income for a worry-free future.  </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-12357837 sc_layouts_column_align_right sc_layouts_column sc-mobile_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="12357837" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-16435afe elementor-view-framed elementor-widget__width-auto rotate-hover elementor-shape-circle sc_fly_static elementor-widget elementor-widget-icon trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="16435afe" data-element_type="widget" data-widget_type="icon.default">
                                          <div className="elementor-widget-container">
                                            <div className="elementor-icon-wrapper"> <a className="elementor-icon" href="#">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="34.356" height="23.642" viewBox="0 0 34.356 23.642"><path id="Path_4452" data-name="Path 4452" d="M33.888,52.608,23.664,42.384A1.6,1.6,0,1,0,21.4,44.643l7.5,7.5H1.6a1.6,1.6,0,0,0,0,3.195H28.9l-7.5,7.5a1.6,1.6,0,0,0,2.259,2.259L33.888,54.867A1.6,1.6,0,0,0,33.888,52.608Z" transform="translate(0 -41.916)" />
                                              </svg> </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>

                                <section data-animation-type="block" className="elementor-section elementor-inner-section elementor-element elementor-element-44a8b039 elementor-section-full_width elementor-section-height-default elementor-section-height-default animation_type_block sc_fly_static sc_cover_link_present trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="44a8b039" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;zurich-fadeinup&quot;}"><a href="/home/slider-zoom-in/" className="sc_cover sc_cover_default inited" data-place="row"></a>
                                  <div className="elementor-container elementor-column-gap-extended">
                               
                                  <div className='custom-box-zog'>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-52cfe55d sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="52cfe55d" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-12c28c7f sc_fly_static elementor-widget elementor-widget-trx_sc_cover trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="12c28c7f" data-element_type="widget" data-widget_type="trx_sc_cover.default">
                                          <div className="elementor-widget-container">  </div>
                                        </div>
                                        <div className="elementor-element elementor-element-1322abe1 num-hover sc_fly_static elementor-widget elementor-widget-heading trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="75a70215" data-element_type="widget" data-widget_type="heading.default">
                                          <div className="elementor-widget-container">
                                            <h5 className="elementor-heading-title elementor-size-default">04</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-2f55d2e2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="479025a6" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-4e06ba33 sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="4f3f00d9" data-element_type="widget" data-widget_type="trx_sc_title.default">
                                          <div className="elementor-widget-container">
                                            <div className="sc_title sc_title_default">
                                              <h3 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Insurance Services </span>
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
</div>



                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-7a63fd15 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7a63fd15" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-7be8fa75 sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7be8fa75" data-element_type="widget" data-widget_type="text-editor.default">
                                          <div className="elementor-widget-container">
                                            <p>Stay protected with our comprehensive insurance solutions. We help you find the right coverage to safeguard your family and assets.  </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-21fd851e sc_layouts_column_align_right sc_layouts_column sc-mobile_layouts_column_align_left sc_layouts_column sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="21fd851e" data-element_type="column">
                                      <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-4833ead3 elementor-view-framed elementor-widget__width-auto rotate-hover elementor-shape-circle sc_fly_static elementor-widget elementor-widget-icon trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="4833ead3" data-element_type="widget" data-widget_type="icon.default">
                                          <div className="elementor-widget-container">
                                            <div className="elementor-icon-wrapper"> <a className="elementor-icon" href="#">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="34.356" height="23.642" viewBox="0 0 34.356 23.642"><path id="Path_4452" data-name="Path 4452" d="M33.888,52.608,23.664,42.384A1.6,1.6,0,1,0,21.4,44.643l7.5,7.5H1.6a1.6,1.6,0,0,0,0,3.195H28.9l-7.5,7.5a1.6,1.6,0,0,0,2.259,2.259L33.888,54.867A1.6,1.6,0,0,0,33.888,52.608Z" transform="translate(0 -41.916)" />
                                              </svg> </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>


                                <div className="elementor-element elementor-element-2be65bcd sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2be65bcd" data-element_type="widget" data-widget_type="spacer.default">
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-element elementor-element-378b7175 sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="378b7175" data-element_type="widget" data-widget_type="spacer.default">
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>




                      </div>
                    </div>

                  </article>
                </div>
              </div>
          
          </div>
        </div>



        {/* place 3 sections here */}
        <div data-elementor-type="wp-page" data-elementor-id="1666" className="elementor elementor-1666 sec-3">
          <div className="elementor-element elementor-element-65d75740 e-flex e-con-boxed e-con e-parent e-lazyloaded"
            data-id="65d75740" data-element_type="container">
            <div className="e-con-inner">
              <div className="elementor-element elementor-element-1eeee608 e-con-full jkit-sticky-element--enabled e-flex jkit-sticky-element-on--down jkit-sticky-position--sticky e-con e-child sticky-pinned"
                data-id="1eeee608" data-element_type="container"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;jkit_sticky_device&quot;:[&quot;desktop&quot;,&quot;laptop&quot;],&quot;jkit_sticky_top_position&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]}}"
                style={window.innerWidth>768?{ position: "sticky" }:{ position: "relative" }}>
                <div className="elementor-element elementor-element-55465065 e-con-full e-flex e-con e-child"
                  data-id="55465065" data-element_type="container">

                  <div className="elementor-element elementor-element-3144772b animated-fast elementor-widget elementor-widget-qi_addons_for_elementor_animated_text animated fadeIn"
                    data-id="3144772b" data-element_type="widget"
                    data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:200}"
                    data-widget_type="qi_addons_for_elementor_animated_text.default">
                    <div className="elementor-widget-container">
                      <div
                        className="qodef-shortcode qodef-m qodef-qi-animated-text qodef--animated-by-word qodef--alignment-left qodef-qi--has-appear qodef--appear-from-right qodef-qi--appeared">
                        <h2 className="qodef-m-title">
                          <span className="qodef-e-word">Empower    </span> <span
                            className="qodef-e-word"> Your </span> <span
                              className="qodef-e-word"></span>Wealth <span className="qodef-e-word">Through</span>
                          <span className="qodef-e-word"></span> <span className="qodef-e-word">Smart </span>
                          <span className="qodef-e-word">Investing</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-41cae526 e-flex e-con-boxed e-con e-child"
                    data-id="41cae526" data-element_type="container">
                    <div className="e-con-inner">
                      <div className="elementor-element elementor-element-3896b010 e-flex e-con-boxed e-con e-child"
                        data-id="3896b010" data-element_type="container">
                        <div className="e-con-inner">
                          <div className="elementor-element elementor-element-5fda77cb elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn"
                            data-id="5fda77cb" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}"
                            data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <img src='/images/home/sec-4-icon-1.png' aria-hidden="true" className="icon icon-light-bulb-1"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-165bdffa elementor-widget elementor-widget-heading"
                            data-id="165bdffa" data-element_type="widget"
                            data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h6 className="elementor-heading-title elementor-size-default">Investment
                                Strategies</h6>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-378eb8ab elementor-widget elementor-widget-text-editor"
                            data-id="378eb8ab" data-element_type="widget"
                            data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                              <p>Personalised investment plans to <br />achieve your financial goals. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-14e77e85 e-flex e-con-boxed e-con e-child"
                        data-id="14e77e85" data-element_type="container">
                        <div className="e-con-inner">
                          <div className="elementor-element elementor-element-1fffd90c elementor-widget__width-auto animated-fast elementor-view-default elementor-widget elementor-widget-icon animated fadeIn"
                            data-id="1fffd90c" data-element_type="widget"
                            data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:0}"
                            data-widget_type="icon.default">
                            <div className="elementor-widget-container">
                              <div className="elementor-icon-wrapper">
                                <div className="elementor-icon">
                                  <img src='/images/home/sec-4-icon-2.png' aria-hidden="true" className="icon icon-growth"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-48a832a6 elementor-widget elementor-widget-heading"
                            data-id="48a832a6" data-element_type="widget"
                            data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                              <h6 className="elementor-heading-title elementor-size-default">Expert Guidance </h6>
                            </div>
                          </div>
                          <div className="elementor-element elementor-element-58cdadcd elementor-widget elementor-widget-text-editor"
                            data-id="58cdadcd" data-element_type="widget"
                            data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                              <p>Receive professional advice to make<br /> informed investment choices. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-25180379 elementor-widget elementor-widget-qi_addons_for_elementor_button" style={{ display: "none" }}
                    data-id="25180379" data-element_type="widget"
                    data-widget_type="qi_addons_for_elementor_button.default">
                    <div className="elementor-widget-container">
                      <a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--filled qodef-type--standard  qodef-hover--reveal qodef--reveal-horizontal qodef-icon--right qodef-hover--icon-move-horizontal     "
                        data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/#"
                        target="_self">
                        <span className="qodef-m-text">FIND OUT MORE </span>
                        <span className="qodef-m-icon ">
                          <span className="qodef-m-icon-inner">
                            <i aria-hidden="true" className="jki jki-arrow-right-solid"></i> <i
                              aria-hidden="true" className="jki jki-arrow-right-solid"></i> </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="elementor-element elementor-element-5997e849 e-flex e-con-boxed e-con e-child"
                  data-id="5997e849" data-element_type="container">
                  <div className="e-con-inner">
                    <div className="elementor-element elementor-element-6c1c78f4 elementor-widget elementor-widget-image"
                      data-id="6c1c78f4" data-element_type="widget" data-widget_type="image.default">
                      <div className="elementor-widget-container">
                        <img loading="lazy" decoding="async" width="550" height="551"
                          src={window.innerWidth > 768 ? "/images/home/sec-4.png" : "/images/home/sec-4-mob.png"}
                          className="attachment-large size-large wp-image-35" alt=""
                          data-savepage-srcset="/images/home/sec-4.png 550w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-03-300x300.jpg 300w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-03-150x150.jpg 150w"
                          srcset="" sizes="(max-width: 550px) 100vw, 550px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-4b3d17f9 e-con-full jkit-sticky-element--enabled e-flex jkit-sticky-element-on--down jkit-sticky-position--sticky e-con e-child sticky-pinned"
                data-id="4b3d17f9" data-element_type="container"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;jkit_sticky_device&quot;:[&quot;desktop&quot;,&quot;laptop&quot;],&quot;jkit_sticky_top_position&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]}}"
                style={window.innerWidth>768?{ position: "sticky" }:{ position: "relative" }}>
                <div className="elementor-element elementor-element-68ad4c2e e-flex e-con-boxed e-con e-child"
                  data-id="68ad4c2e" data-element_type="container">
                  <div className="e-con-inner">
                    <div className="elementor-element elementor-element-4ae2f404 elementor-widget elementor-widget-image"
                      data-id="4ae2f404" data-element_type="widget" data-widget_type="image.default">
                      <div className="elementor-widget-container">
                        <img loading="lazy" decoding="async" width="551" height="551"
                          src={window.innerWidth > 768 ? "/images/home/sec-5.png" : "/images/home/sec-5-mob.png"}
                          className="attachment-large size-large wp-image-34" alt=""
                          data-savepage-srcset="/images/home/sec-5.png 551w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-02-300x300.jpg 300w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-02-150x150.jpg 150w"
                          srcset="" sizes="(max-width: 551px) 100vw, 551px" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-element elementor-element-65a74489 e-flex e-con-boxed e-con e-child"
                  data-id="65a74489" data-element_type="container">
                  <div className="e-con-inner">
                    {/* <div className="elementor-element elementor-element-a68e405 elementor-widget__width-auto elementor-widget elementor-widget-qi_addons_for_elementor_highlight"
                      data-id="a68e405" data-element_type="widget"
                      data-widget_type="qi_addons_for_elementor_highlight.default">
                      <div className="elementor-widget-container">
                        <p
                          className="qodef-shortcode qodef-m  qodef-qi-highlight  qodef-highlight-style--color">
                          <span className="qodef-highlight-text"
                            style={{ backgroundImage: `linear-gradient(to bottom, transparent 0%, #00000000 0%, #00000000 100%, transparent 100%)` }}>Mortgage Services </span>
                        </p>
                      </div>
                    </div> */}
                    <div className="elementor-element elementor-element-26ac3bc8 animated-fast elementor-widget elementor-widget-qi_addons_for_elementor_animated_text animated fadeIn"
                      data-id="26ac3bc8" data-element_type="widget"
                      data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:200}"
                      data-widget_type="qi_addons_for_elementor_animated_text.default">
                      <div className="elementor-widget-container">
                        <div
                          className="qodef-shortcode qodef-m qodef-qi-animated-text qodef--animated-by-word qodef--alignment-left qodef-qi--has-appear qodef--appear-from-right qodef-qi--appeared">
                          <h2 className="qodef-m-title">
                            <span className="qodef-e-word">Bringing You    </span> <span
                              className="qodef-e-word">Closer to </span> <span
                                className="qodef-e-word">Your</span> <span
                                  className="qodef-e-word">Dream</span> <span
                                    className="qodef-e-word">Home</span>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-4d26b22e elementor-widget elementor-widget-text-editor"
                      data-id="4d26b22e" data-element_type="widget" data-widget_type="text-editor.default">
                      <div className="elementor-widget-container">
                        <p>Experience a seamless mortgage process with our expert guidance. </p>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-20907cce e-flex e-con-boxed e-con e-child"
                      data-id="20907cce" data-element_type="container">
                      <div className="e-con-inner">
                        <div className="elementor-element elementor-element-19917b6e e-flex e-con-boxed e-con e-child"
                          data-id="19917b6e" data-element_type="container">
                          <div className="e-con-inner">
                            <div className="elementor-element elementor-element-7d839063 elementor-widget__width-auto elementor-widget elementor-widget-qi_addons_for_elementor_progress_bar_circle"
                              data-id="7d839063" data-element_type="widget"
                              data-widget_type="qi_addons_for_elementor_progress_bar_circle.default">
                              <div className="elementor-widget-container">
                                <div className="qodef-shortcode qodef-m qodef-qi-progress-bar-circle qodef--init"
                                  data-active-line-color="#F9D67C" data-active-line-width="2"
                                  data-inactive-line-color="#ececec" data-inactive-line-width="1"
                                  data-number="95" data-text-color="#FFFFFF">
                                  <div className="qodef-m-inner">
                                    <div className="qodef-m-canvas"><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 100 100"
                                      style={{ display: "block", width: "100%" }}>
                                      <path
                                        d="M 50,50 m 0,-48.75 a 48.75,48.75 0 1 1 0,97.5 a 48.75,48.75 0 1 1 0,-97.5"
                                        stroke="#ececec" stroke-width="1.25"
                                        fill-opacity="0" />
                                      <path
                                        d="M 50,50 m 0,-48.75 a 48.75,48.75 0 1 1 0,97.5 a 48.75,48.75 0 1 1 0,-97.5"
                                        stroke="#171717" stroke-width="2.5"
                                        fill-opacity="0"
                                        style={{ strokeDasharray: `306.348px, 306.348px`, strokeDashoffset: "15.3174px" }} />
                                    </svg>
                                      <div className="qodef-m-value"
                                        style={{ color: "#171717" }}>95<sup
                                          className="qodef-m-percentage">%</sup></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-461b2b37 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                              data-id="461b2b37" data-element_type="widget"
                              data-widget_type="heading.default">
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">Happy
                                  <br /> Homeowners</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-2c1098ea e-flex e-con-boxed e-con e-child"
                          data-id="2c1098ea" data-element_type="container">
                          <div className="e-con-inner">
                            <div className="elementor-element elementor-element-19d83ad7 elementor-widget__width-auto elementor-widget elementor-widget-qi_addons_for_elementor_progress_bar_circle"
                              data-id="19d83ad7" data-element_type="widget"
                              data-widget_type="qi_addons_for_elementor_progress_bar_circle.default">
                              <div className="elementor-widget-container">
                                <div className="qodef-shortcode qodef-m qodef-qi-progress-bar-circle qodef--init"
                                  data-active-line-color="#F9D67C" data-active-line-width="2"
                                  data-inactive-line-color="#ececec" data-inactive-line-width="1"
                                  data-number="99" data-text-color="#FFFFFF">
                                  <div className="qodef-m-inner">
                                    <div className="qodef-m-canvas"><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 100 100"
                                      style={{ display: "block", width: "100%" }}>
                                      <path
                                        d="M 50,50 m 0,-48.57244825124911 a 48.57244825124911,48.57244825124911 0 1 1 0,97.14489650249821 a 48.57244825124911,48.57244825124911 0 1 1 0,-97.14489650249821"
                                        stroke="#ececec"
                                        stroke-width="1.4275517487508922"
                                        fill-opacity="0" />
                                      <path
                                        d="M 50,50 m 0,-48.57244825124911 a 48.57244825124911,48.57244825124911 0 1 1 0,97.14489650249821 a 48.57244825124911,48.57244825124911 0 1 1 0,-97.14489650249821"
                                        stroke="#171717"
                                        stroke-width="2.8551034975017844"
                                        fill-opacity="0"
                                        style={{ strokeDasharray: `305.232px, 305.232px`, strokeDashoffset: "15.2616px" }} />
                                    </svg>
                                      <div className="qodef-m-value"
                                        style={{ color: "#171717" }}>99<sup
                                          className="qodef-m-percentage">%</sup></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="elementor-element elementor-element-777e9819 elementor-widget__width-auto elementor-widget elementor-widget-heading"
                              data-id="777e9819" data-element_type="widget"
                              data-widget_type="heading.default">
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">Mortgage<br/> Approval Rate </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-element elementor-element-39663db elementor-widget elementor-widget-qi_addons_for_elementor_button" style={{ display: "none" }}
                      data-id="39663db" data-element_type="widget"
                      data-widget_type="qi_addons_for_elementor_button.default">
                      <div className="elementor-widget-container">
                        <a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--filled qodef-type--standard  qodef-hover--reveal qodef--reveal-horizontal qodef-icon--right qodef-hover--icon-move-horizontal     "
                          data-savepage-href="#" href="https://elementor.thememarch.com/fingcon/#"
                          target="_self">
                          <span className="qodef-m-text">FIND OUT MORE </span>
                          <span className="qodef-m-icon ">
                            <span className="qodef-m-icon-inner">
                              <i aria-hidden="true" className="jki jki-arrow-right-solid"></i> <i
                                aria-hidden="true" className="jki jki-arrow-right-solid"></i> </span>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="elementor-element elementor-element-23bfaeab e-con-full jkit-sticky-element--enabled e-flex jkit-sticky-element-on--down jkit-sticky-position--sticky e-con e-child sticky-pinned"
                data-id="23bfaeab" data-element_type="container"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;jkit_sticky_device&quot;:[&quot;desktop&quot;,&quot;laptop&quot;],&quot;jkit_sticky_top_position&quot;:{&quot;unit&quot;:&quot;px&quot;,&quot;size&quot;:0,&quot;sizes&quot;:[]}}"
                style={window.innerWidth>768?{ position: "sticky" }:{ position: "relative" }}>
                <div className="elementor-element elementor-element-319c3562 e-con-full e-flex e-con e-child"
                  data-id="319c3562" data-element_type="container">

                  <div className="elementor-element elementor-element-2836e31a animated-fast elementor-widget elementor-widget-qi_addons_for_elementor_animated_text animated fadeIn"
                    data-id="2836e31a" data-element_type="widget"
                    data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:200}"
                    data-widget_type="qi_addons_for_elementor_animated_text.default">
                    <div className="elementor-widget-container">
                      <div
                        className="qodef-shortcode qodef-m qodef-qi-animated-text qodef--animated-by-word qodef--alignment-left qodef-qi--has-appear qodef--appear-from-right qodef-qi--appeared">
                        <h2 className="qodef-m-title">
                          <span className="qodef-e-word">Pension  </span> <span
                            className="qodef-e-word"> Advice</span> <span className="qodef-e-word"></span>
                          <span className="qodef-e-word"> & Retirement</span> <span
                            className="qodef-e-word">Options</span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-40519800 e-flex e-con-boxed e-con e-child"
                    data-id="40519800" data-element_type="container">
                    <div className="e-con-inner">
                      <div className="elementor-element elementor-element-24a7236c elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_progress_bar_horizontal"
                        data-id="24a7236c" data-element_type="widget"
                        data-widget_type="qi_addons_for_elementor_progress_bar_horizontal.default">
                        <div className="elementor-widget-container">
                          <div className="qodef-shortcode qodef-m qodef-qi-progress-bar-horizontal qodef-percentage--floating-above qodef--init"
                            data-active-line-color="#F9D67C" data-active-line-width="5"
                            data-inactive-line-color="#F7F3EC" data-inactive-line-width="2"
                            data-number="90" data-percentage-type="floating-above"
                            data-gradient-fill="no" data-rand-id="50809470"
                            data-gradient-start="#D9E7FA" data-gradient-end="#FCC4AF"
                            data-text-color="#1e1e1e">
                            <div className="qodef-m-inner">
                              <div className="qodef-m-content">
                                <p className="qodef-m-title">
                                  Client Satisfaction </p>
                                <div className="qodef-m-value" style={{ left: "80%" }}>
                                  <div className="qodef-m-value-inner">98<span
                                    className="qodef-m-percentage">%</span></div>
                                </div>
                              </div>
                              <div className="qodef-m-canvas"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 1.0526315789473684" preserveAspectRatio="none"
                                style={{ display: "block", width: "100%" }}>
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="0.42105263157894735"
                                  fill-opacity="0" />
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="1.0526315789473684"
                                  fill-opacity="0"
                                  style={{ strokeDasharray: `100px, 100px`, strokeDashoffset: "5px" }} />
                              </svg></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-6458e9c4 elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_progress_bar_horizontal"
                        data-id="6458e9c4" data-element_type="widget"
                        data-widget_type="qi_addons_for_elementor_progress_bar_horizontal.default">
                        <div className="elementor-widget-container">
                          <div className="qodef-shortcode qodef-m qodef-qi-progress-bar-horizontal qodef-percentage--floating-above qodef--init"
                            data-active-line-color="#F9D67C" data-active-line-width="5"
                            data-inactive-line-color="#F7F3EC" data-inactive-line-width="2"
                            data-number="30" data-percentage-type="floating-above"
                            data-gradient-fill="no" data-rand-id="115328458"
                            data-gradient-start="#D9E7FA" data-gradient-end="#FCC4AF"
                            data-text-color="#1e1e1e">
                            <div className="qodef-m-inner">
                              <div className="qodef-m-content">
                                <p className="qodef-m-title">
                                  Increase in Retirement Savings</p>
                                <div className="qodef-m-value" style={{ left: "80%" }}>
                                  <div className="qodef-m-value-inner">30<span
                                    className="qodef-m-percentage">%</span></div>
                                </div>
                              </div>
                              <div className="qodef-m-canvas"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 1.0526315789473684" preserveAspectRatio="none"
                                style={{ display: "block", width: "100%" }}>
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="0.42105263157894735"
                                  fill-opacity="0" />
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="1.0526315789473684"
                                  fill-opacity="0"
                                  style={{ strokeDasharray: `100px, 100px`, strokeDashoffset: "20px" }} />
                              </svg></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="elementor-element elementor-element-531632a2 elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_progress_bar_horizontal"
                        data-id="531632a2" data-element_type="widget"
                        data-widget_type="qi_addons_for_elementor_progress_bar_horizontal.default">
                        <div className="elementor-widget-container">
                          <div className="qodef-shortcode qodef-m qodef-qi-progress-bar-horizontal qodef-percentage--floating-above qodef--init"
                            data-active-line-color="#F9D67C" data-active-line-width="5"
                            data-inactive-line-color="#F7F3EC" data-inactive-line-width="2"
                            data-number="25" data-percentage-type="floating-above"
                            data-gradient-fill="no" data-rand-id="624607642"
                            data-gradient-start="#D9E7FA" data-gradient-end="#FCC4AF"
                            data-text-color="#1e1e1e">
                            <div className="qodef-m-inner">
                              <div className="qodef-m-content">
                                <p className="qodef-m-title">
                                  Investment Growth Potential </p>
                                <div className="qodef-m-value" style={{ left: "80%" }}>
                                  <div className="qodef-m-value-inner">25<span
                                    className="qodef-m-percentage">%</span></div>
                                </div>
                              </div>
                              <div className="qodef-m-canvas"><svg xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 1.0526315789473684" preserveAspectRatio="none"
                                style={{ display: "block", width: "100%" }}>
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="0.42105263157894735"
                                  fill-opacity="0" />
                                <path d="M 0,0.5263157894736842 L 100,0.5263157894736842"
                                  stroke="#CFDD28" stroke-width="1.0526315789473684"
                                  fill-opacity="0"
                                  style={{ strokeDasharray: `100px, 100px`, strokeDashoffset: "25px" }} />
                              </svg></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-47729f18 elementor-widget elementor-widget-qi_addons_for_elementor_button" 
                    data-id="47729f18" data-element_type="widget"
                    data-widget_type="qi_addons_for_elementor_button.default">
                    <div className="elementor-widget-container">
                      <a className="qodef-shortcode qodef-m  qodef-qi-button qodef-html--link qodef-layout--filled qodef-type--standard  qodef-hover--reveal qodef--reveal-horizontal qodef-icon--right qodef-hover--icon-move-horizontal     "
                        data-savepage-href="#" href="/services/pension-and retirement"
                        target="_self">
                        <span className="qodef-m-text">Get Started</span>
                        <span className="qodef-m-icon ">
                          <span className="qodef-m-icon-inner">
                            <i aria-hidden="true" className="jki jki-arrow-right-solid"></i> <i
                              aria-hidden="true" className="jki jki-arrow-right-solid"></i> </span>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="elementor-element elementor-element-12aea26e e-flex e-con-boxed e-con e-child"
                  data-id="12aea26e" data-element_type="container">
                  <div className="e-con-inner">
                    <div className="elementor-element elementor-element-24454ce9 elementor-widget elementor-widget-image"
                      data-id="24454ce9" data-element_type="widget" data-widget_type="image.default">
                      <div className="elementor-widget-container">
                        <img loading="lazy" decoding="async" width="550" height="551"
                          src={window.innerWidth > 768 ? "/images/home/sec-6.png" : "/images/home/sec-6-mob.png"}
                          className="attachment-large size-large wp-image-33" alt=""
                          data-savepage-srcset="/images/home/sec-6.png 550w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-01-300x300.jpg 300w, https://elementor.thememarch.com/fingcon/wp-content/uploads/2024/05/sticky-section-services-01-150x150.jpg 150w"
                          srcset="" sizes="(max-width: 550px) 100vw, 550px" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="elementor-section elementor-top-section elementor-element elementor-element-408481e3 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" style={window.innerWidth>768?{ margin: "0px 0" }:{ margin: "0px 0 50px 0" }} data-id="408481e3" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_mobile&quot;:&quot;none&quot;}">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-70ace2f3" data-id="70ace2f3" data-element_type="column">
              <div className="elementor-widget-wrap elementor-element-populated">
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-4ea3f2b7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4ea3f2b7" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-185f66dc" data-id="185f66dc" data-element_type="column">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-4e5cec0f elementor-widget elementor-widget-heading" data-id="4e5cec0f" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">Insurance Solutions for Life's Unexpected Moments. </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-70840ec1 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="70840ec1" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-5d7d5337 animated-fast animated fadeInUp" data-id="5d7d5337" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:700}">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-77501041 elementor-position-left elementor-mobile-position-left elementor-view-stacked elementor-shape-square elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="77501041" data-element_type="widget" data-widget_type="icon-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon" style={{ display: "none" }}>
                                <img className="elementor-icon elementor-animation-" src='/images/home/sec-7-icon.svg' style={{ height: "30px" }}>
                                </img>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h5 className="elementor-icon-box-title">
                                  <span>
                                    Embrace Life's Possibilities  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-1946d185 elementor-widget elementor-widget-heading" data-id="1946d185" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-heading-title elementor-size-default">With our comprehensive coverage options, you can pursue your dreams confidently, knowing your loved ones are protected every step of the way.
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-66d0cd69 elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="66d0cd69" data-element_type="widget" data-widget_type="divider.default">
                          <div className="elementor-widget-container">
                            <link rel="stylesheet" href="css/style35.css" />
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator">
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-7204fb2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7204fb2" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-7010006f animated-fast animated fadeInUp" data-id="7010006f" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:700}">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-8aea87c elementor-position-left elementor-mobile-position-left elementor-view-stacked elementor-shape-square elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="8aea87c" data-element_type="widget" data-widget_type="icon-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon" style={{ display: "none" }}>
                                <img className="elementor-icon elementor-animation-" src='/images/home/sec-7-icon.svg' style={{ height: "30px" }}>
                                </img>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h5 className="elementor-icon-box-title">
                                  <span>
                                    Your Safety, Our Priority </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-3b105af9 elementor-widget elementor-widget-heading" data-id="3b105af9" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-heading-title elementor-size-default">We provide personalised insurance solutions that safeguard your family's future, ensuring you can focus on what truly matters—creating lasting memories.
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-25d88aac elementor-widget-divider--view-line elementor-widget elementor-widget-divider" data-id="25d88aac" data-element_type="widget" data-widget_type="divider.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-divider">
                              <span className="elementor-divider-separator">
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-5d3265a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5d3265a2" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-76b68d54 animated-fast animated fadeInUp" data-id="76b68d54" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;,&quot;animation_delay&quot;:700}">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-23e6ef28 elementor-position-left elementor-mobile-position-left elementor-view-stacked elementor-shape-square elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="23e6ef28" data-element_type="widget" data-widget_type="icon-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-icon-box-wrapper">
                              <div className="elementor-icon-box-icon" style={{ display: "none" }}>
                                <img className="elementor-icon elementor-animation-" src='/images/home/sec-7-icon.svg' style={{ height: "30px" }}>
                                </img>
                              </div>
                              <div className="elementor-icon-box-content">
                                <h5 className="elementor-icon-box-title">
                                  <span>Navigate Life's Journey Fearlessly  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-495f068f elementor-widget elementor-widget-heading" data-id="495f068f" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-heading-title elementor-size-default">Our expert guidance empowers you to make informed insurance choices, allowing you to embrace life's adventures with peace of mind and security.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-6b265655" data-id="6b265655" data-element_type="column">
              {/* <div className="elementor-widget-wrap elementor-element-populated">
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-1d30f43 elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="1d30f43" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeInUp&quot;}">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-74ef5222" data-id="74ef5222" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-1dd85d12 elementor-widget elementor-widget-image" data-id="1dd85d12" data-element_type="widget" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            <img decoding="async" src="/images/home/sec-7.png" title="earlnapp_ss" alt="earlnapp_ss" loading="lazy" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div> */}
              <img decoding="async" src="/images/home/sec-7.png" title="earlnapp_ss" alt="earlnapp_ss" loading="lazy" />

            </div>
          </div>
        </section>

        <section className="elementor-section elementor-top-section elementor-element elementor-element-bfe40f0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bfe40f0" data-element_type="section">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4be10ac6" data-id="4be10ac6" data-element_type="column">
              <div className="elementor-widget-wrap elementor-element-populated">
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-7c02292f elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="7c02292f" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-52f195b8" data-id="52f195b8" data-element_type="column">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-6f2264bc elementor-widget__width-initial elementor-widget elementor-widget-heading" data-id="6f2264bc" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">Advantages Of Investment
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="sec-8 elementor-section elementor-inner-section elementor-element elementor-element-1880752c animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="1880752c" data-element_type="section" >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1fb77484" data-id="1fb77484" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-3b9b1895 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            {/* <img loading="lazy" decoding="async" width="382" height="640" src="/images/home/sec-8-1.png" className="attachment-full size-full wp-image-19297" alt="" data-savepage-srcset="/images/home/sec-8-1.png 382w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/feauture_1-e1685504380911-179x300.png 179w" srcset="" sizes="(max-width: 382px) 100vw, 382px" /> */}
                          <div className='number'>01</div>
                          </div>
                        </div>
                        <div className="elementor-element elementor-element-34071089 elementor-widget elementor-widget-image-box" data-id="34071089" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <link rel="stylesheet" href="css/style34.css" />
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <h5 className="elementor-image-box-title">Wealth Growth </h5>
                                <p className="elementor-image-box-description">Investing builds wealth over time through the power of compounding. </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d673c7e" data-id="d673c7e" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-40305d39 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="40305d39" data-element_type="widget" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            {/* <img loading="lazy" decoding="async" width="382" height="640" src="/images/home/sec-8-2.png" className="attachment-full size-full wp-image-19298" alt="" data-savepage-srcset="/images/home/sec-8-2.png 382w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/feauture_2-179x300.png 179w" srcset="" sizes="(max-width: 382px) 100vw, 382px" /> */}
                            <div className='number'>02</div> </div>
                        </div>
                        <div className="elementor-element elementor-element-5b9c7f07 elementor-widget elementor-widget-image-box" data-id="5b9c7f07" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <h5 className="elementor-image-box-title">Risk Diversification </h5>
                                <p className="elementor-image-box-description">Spread investments across assets to minimise risk and enhance stability. </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-74c261c1" data-id="74c261c1" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-3dcc50d3 elementor-widget__width-initial elementor-widget elementor-widget-image" data-id="3dcc50d3" data-element_type="widget" data-widget_type="image.default">
                          <div className="elementor-widget-container">
                            {/* <img loading="lazy" decoding="async" width="382" height="640" src="/images/home/sec-8-3.png" className="attachment-full size-full wp-image-19299" alt="" data-savepage-srcset="/images/home/sec-8-3.png 382w, https://elementor.nathatype.com/ovesment/wp-content/uploads/sites/3/2022/06/feauture_3-e1685504435288-179x300.png 179w" srcset="" sizes="(max-width: 382px) 100vw, 382px" /> */}
                            <div className='number'>03</div></div>
                        </div>
                        <div className="elementor-element elementor-element-1231218c elementor-widget elementor-widget-image-box" data-id="1231218c" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <h5 className="elementor-image-box-title">Financial Freedom </h5>
                                <p className="elementor-image-box-description">Achieve financial goals faster by investing wisely and consistently. </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
        </section>

        <section className="sec-9 elementor-section elementor-top-section elementor-element elementor-element-bfe40f0 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bfe40f0" data-element_type="section">
          <div className="elementor-container elementor-column-gap-no">
            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4be10ac6" data-id="4be10ac6" data-element_type="column">
              <div className="elementor-widget-wrap elementor-element-populated">
                <section className="elementor-section elementor-inner-section elementor-element elementor-element-7c02292f elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="7c02292f" data-element_type="section">
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-52f195b8" data-id="52f195b8" data-element_type="column">
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-6f2264bc elementor-widget__width-initial elementor-widget elementor-widget-heading" data-id="6f2264bc" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default" style={{color:"#222639"}}>What Our Customers Says
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="elementor-section elementor-inner-section elementor-element elementor-element-1880752c animated-fast elementor-section-boxed elementor-section-height-default elementor-section-height-default animated fadeInUp" data-id="1880752c" data-element_type="section" >
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1fb77484" data-id="1fb77484" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-34071089 elementor-widget elementor-widget-image-box" data-id="34071089" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <link rel="stylesheet" href="css/style34.css" />
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>I was nervous about getting a mortgage, but they walked me through the process and made it stress-free.</strong> </p>
                                <p className="elementor-image-box-description"><strong>-Morgan</strong> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d673c7e" data-id="d673c7e" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-5b9c7f07 elementor-widget elementor-widget-image-box" data-id="5b9c7f07" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>As a property investor, I needed a finance partner I could rely on. They provided clear, actionable advice that helped me grow my portfolio with ease.</strong> </p>
                                <p className="elementor-image-box-description"><strong>-Richard Hayes</strong> </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-74c261c1" data-id="74c261c1" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-1231218c elementor-widget elementor-widget-image-box" data-id="1231218c" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>I never knew how much risk I was taking with my investments until they stepped in. Now, I have a solid strategy that protects my assets while still growing them.</strong></p>
                                <p className="elementor-image-box-description"><strong>-Adil Ahmed</strong></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-container elementor-column-gap-no">
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-1fb77484" data-id="1fb77484" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-34071089 elementor-widget elementor-widget-image-box" data-id="34071089" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <link rel="stylesheet" href="css/style34.css" />
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>It's not just about giving advice; they take the time to listen. I trust them completely with my wealth planning.</strong> </p>
                                <p className="elementor-image-box-description"><strong>-Ravi Shankar</strong> </p>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-d673c7e" data-id="d673c7e" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-5b9c7f07 elementor-widget elementor-widget-image-box" data-id="5b9c7f07" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>They helped me organise my investments and explained everything clearly. I now feel much more in control.</strong> </p>
                                <p className="elementor-image-box-description"><strong>-Sanjay Desai</strong> </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-74c261c1" data-id="74c261c1" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;gradient&quot;}">
                      <div className="elementor-widget-wrap elementor-element-populated sec-8-card">
                        <div className="elementor-background-overlay"></div>
                        <div className="elementor-element elementor-element-7d15bdd elementor-star-rating--align-center elementor-widget__width-auto elementor--star-style-star_fontawesome elementor-widget elementor-widget-star-rating" data-id="3b9b1895" data-element_type="widget" data-widget_type="image.default">

                          <div className="elementor-widget-container">

                            <div className='elementor-star-rating__wrapper'>
                              <div className='elementor-star-rating'>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                                <i className='elementor-star-full'></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="elementor-element elementor-element-1231218c elementor-widget elementor-widget-image-box" data-id="1231218c" data-element_type="widget" data-widget_type="image-box.default">
                          <div className="elementor-widget-container">
                            <div className="elementor-image-box-wrapper">
                              <div className="elementor-image-box-content">
                                <p className="elementor-image-box-description"><strong>The team at Reserve made everything easy to understand and were always there to answer my questions. Great service!</strong></p>
                                <p className="elementor-image-box-description"><strong>-Amir Rahman</strong></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

              </div>
            </div>
          </div>
        </section>

        <div className="body_wrap">
          <div className="page_wrap">
            <a className="zurich_skip_link skip_to_content_link" href="#content_skip_link_anchor" tabindex="1">Skip to content</a> <a className="zurich_skip_link skip_to_footer_link" href="#footer_skip_link_anchor" tabindex="1">Skip to footer</a>

            <div className="menu_mobile_overlay scheme_dark inited_click" style={{ display: "none" }}>
            </div>
            <div className="menu_mobile menu_mobile_fullscreen scheme_dark inited_arrows inited_click">
              <div className="menu_mobile_inner with_widgets">
                <div className="menu_mobile_header_wrap">
                  <a className="sc_layouts_logo" href="#">
                    <img src='' alt="Zurich" width="145" height="29" data-savepage-loading="lazy" />
                  </a>
                  <a className="menu_mobile_close menu_button_close inited_click" tabindex="0">
                    <span className="menu_button_close_text">Close</span>
                    <span className="menu_button_close_icon"></span>
                  </a> </div>

              </div>

            </div>
            <div className="page_content_wrap">
              <div className="content_wrap_fullscreen">
                <div className="content">
                  <a id="content_skip_link_anchor" className="zurich_skip_link_anchor" href="#"></a>
                  <article id="post-3847" className="post_item_single post_type_page post-3847 page type-page status-publish hentry">
                    <div className="post_content entry-content">
                      <div data-elementor-type="wp-page" data-elementor-id="3847" className="elementor elementor-3847">




                        <section className="elementor-section elementor-top-section elementor-element elementor-element-2e52d1a2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2e52d1a2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                          <div className="elementor-container elementor-column-gap-extended">
                            <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-2d4ae3a4 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="2d4ae3a4" data-element_type="column">
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div data-animation-type="block" className="elementor-element elementor-element-c3cb45e animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="c3cb45e" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zurich-fadeinup&quot;}" data-widget_type="trx_sc_title.default">
                                  <div className="elementor-widget-container">
                                    <div className="sc_title sc_title_default">
                                      <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">FROM OUR BLOG </span>
                                      <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag"><span className="sc_item_title_text">Latest Insights </span>
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                                <div className="elementor-element elementor-element-36347656 sc_height_small sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="36347656" data-element_type="widget" data-widget_type="spacer.default">
                                  <div className="elementor-widget-container">
                                    <div className="elementor-spacer">
                                      <div className="elementor-spacer-inner"></div>
                                    </div>
                                  </div>
                                </div>
                                <div data-animation-type="block" className="elementor-element elementor-element-310c3cbe sc_style_default animation_type_block sc_fly_static elementor-widget elementor-widget-trx_sc_blogger trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited animated zurich-fadeinup" data-id="310c3cbe" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;zurich-fadeinup&quot;}" data-widget_type="trx_sc_blogger.default">
                                  <div className="elementor-widget-container">
                                    <div className="sc_blogger sc_blogger_default sc_blogger_default_classic sc_item_filters_tabs_none alignnone">
                                      <div className="sc_blogger_slider sc_item_slider slider_swiper_outer slider_outer slider_outer_nocontrols slider_outer_pagination slider_outer_pagination_bullets slider_outer_pagination_pos_bottom slider_outer_nocentered slider_outer_overflow_hidden slider_outer_multi" id="swiper_07370608887211597_outer">
                                        <div className="slider_container swiper-slider-container slider_swiper;" data-slides-space="30" data-effect="slide" data-slides-min-width="220" data-pagination="bullets" data-direction="horizontal" data-mouse-wheel="0" data-autoplay="0" data-loop="1" data-free-mode="0" data-slides-centered="0" data-slides-overflow="0" data-mouse-helper-hide-cursor="1" data-mouse-helper="hover" data-mouse-helper-centered="1" data-mouse-helper-magnet="0" data-mouse-helper-bg-color="rgba(0, 0, 0, 1)" data-mouse-helper-mode="normal" data-mouse-helper-axis="xy" data-mouse-helper-delay="8" data-mouse-helper-text-round="0" id="swiper_07370608887211597" style={{ display: "block", opacity: 1 }} data-busy="0">
                                          <div className="slides slider-wrapper swiper-wrapper sc_item_columns_3" style={{ cursor: "grab", transitionDuration: "0ms",  }} id="swiper-wrapper-a10c50c8e1021083a7" >
                                          
                                          
                                            {/* <div className="slider-slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-slide-number="1" data-swiper-slide-index="1" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="2 / 4"><div data-post-id="430" data-item-number="2" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-430 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" src="/images/home/sec-10-1.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/cybersecurity-in-online-banking-protect-your-assets/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories">
                                                    <span className="post_meta_item post_categories cat_sep">
                                                      <a href="/home/category/business/" rel="category tag">Business</a>
                                                      </span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="2">
                                                    <a href="/home/cybersecurity-in-online-banking-protect-your-assets/" rel="bookmark">Cybersecurity in online banking: protect your assets</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/home/">Mar 4, 2024</a></span><a href="/home/" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-duplicate" data-slide-number="2" data-swiper-slide-index="2" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="3 / 4"><div data-post-id="426" data-item-number="3" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-426 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" src="/images/home/sec-10-1.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/neobanks-disrupting-the-traditional-banking-sector/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="3">
                                                    <a href="/home/neobanks-disrupting-the-traditional-banking-sector/" rel="bookmark">Neobanks: disrupting the traditional banking sector</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/neobanks-disrupting-the-traditional-banking-sector/#comments" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">3</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-duplicate swiper-slide-prev" data-slide-number="3" data-swiper-slide-index="3" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="4 / 4"><div data-post-id="5299" data-item-number="4" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-5299 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" src="/images/home/sec-10-1.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/the-future-of-online-banking-shaping-the-industry/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="4">
                                                    <a href="/home/the-future-of-online-banking-shaping-the-industry/" rel="bookmark">The future of online banking: shaping the industry</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/the-future-of-online-banking-shaping-the-industry/#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div> */}
                                            <div className="slider-slide swiper-slide swiper-slide-active" data-slide-number="0" data-swiper-slide-index="0" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="1 / 4"><div data-post-id="432" data-item-number="1" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-432 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="/images/home/sec-10-1.png" src="/images/home/sec-10-1.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/blog-details/1" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="1">
                                                    <a href="/blog-details/1" rel="bookmark">Smart Saving Strategies for Long-Term Financial Security</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/blog-details/1#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-next" data-slide-number="1" data-swiper-slide-index="1" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="2 / 4"><div data-post-id="430" data-item-number="2" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-430 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="https://zurich.axiomthemes.com/wp-content/uploads/2024/03/post7-copyright-890x664.jpg" src="/images/home/sec-10-2.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="blog-details/2" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="2">
                                                    <a href="/blog-details/2" rel="bookmark"> First-Time Homebuyer? Here’s What You <br/>Need to Know About Mortgages</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/cybersecurity-in-online-banking-protect-your-assets/#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide" data-slide-number="2" data-swiper-slide-index="2" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="3 / 4"><div data-post-id="426" data-item-number="3" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-426 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="https://zurich.axiomthemes.com/wp-content/uploads/2024/03/post8-copyright-890x664.jpg" src="/images/home/sec-10-3.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="blog-details/3" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="3">
                                                    <a href="/blog-details/3" rel="bookmark"> How to Improve Your Credit Score for a<br/> Better Mortgage Rate</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">3</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            {/* <div className="slider-slide swiper-slide swiper-slide-duplicate-prev" data-slide-number="3" data-swiper-slide-index="3" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="4 / 4"><div data-post-id="5299" data-item-number="4" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-5299 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="https://zurich.axiomthemes.com/wp-content/uploads/2024/03/post21-copyright-890x664.jpg" src="/images/home/sec-10-3.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/the-future-of-online-banking-shaping-the-industry/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="4">
                                                    <a href="/blog-details/4" rel="bookmark">The future of online banking: shaping the industry</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/the-future-of-online-banking-shaping-the-industry/#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active" data-slide-number="0" data-swiper-slide-index="0" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="1 / 4"><div data-post-id="432" data-item-number="1" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-432 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="/images/home/sec-10-1.png" src="/images/home/sec-10-3.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/blog-details/1" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="1">
                                                    <a href="/blog-details/2" rel="bookmark">Digital transformation in online banking 2024</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/blog-details/2#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>

                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next" data-slide-number="1" data-swiper-slide-index="1" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="2 / 4"><div data-post-id="430" data-item-number="2" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_even sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-430 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="https://zurich.axiomthemes.com/wp-content/uploads/2024/03/post7-copyright-890x664.jpg" src="/images/home/sec-10-3.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/cybersecurity-in-online-banking-protect-your-assets/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="2">
                                                    <a href="/home/cybersecurity-in-online-banking-protect-your-assets/" rel="bookmark">Cybersecurity in online banking: protect your assets</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/cybersecurity-in-online-banking-protect-your-assets/#respond" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">0</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>
                                              </div></div>

                                            </div>
                                            <div className="slider-slide swiper-slide swiper-slide-duplicate" data-slide-number="2" data-swiper-slide-index="2" style={{ width: "410px", marginRight: "30px" }} role="group" aria-label="3 / 4"><div data-post-id="426" data-item-number="3" className="sc_blogger_item sc_item_container post_container sc_blogger_item_default sc_blogger_item_default_classic sc_blogger_item_odd sc_blogger_item_align_none post_format_standard sc_blogger_item_with_image sc_blogger_item_no_excerpt sc_blogger_item_image_position_top post-426 post type-post status-publish format-standard has-post-thumbnail hentry category-business tag-insurance tag-planning tag-project tag-tax">
                                              <div className="sc_blogger_item_body"><div className="post_featured with_thumb hover_link sc_item_featured sc_blogger_item_featured"><img decoding="async" width="890" height="664" data-savepage-src="https://zurich.axiomthemes.com/wp-content/uploads/2024/03/post8-copyright-890x664.jpg" src="/images/home/sec-10-3.png" className="attachment-zurich-thumb-square size-zurich-thumb-square wp-post-image" alt="" data-savepage-loading="lazy" /> <div className="mask"></div> <a href="/home/neobanks-disrupting-the-traditional-banking-sector/" aria-hidden="true" className="link"></a> </div>
                                                <div className="sc_blogger_item_content entry-content">
                                                  <div className="post_meta sc_blogger_item_meta post_meta_categories"><span className="post_meta_item post_categories cat_sep"><a href="/home/category/business/" rel="category tag">Business</a></span>
                                                  </div>
                                                  <h5 className="sc_blogger_item_title entry-title" data-item-number="3">
                                                    <a href="/home/neobanks-disrupting-the-traditional-banking-sector/" rel="bookmark">Neobanks: disrupting the traditional banking sector</a>
                                                  </h5>
                                                  <div className="post_meta sc_blogger_item_meta post_meta"><span className="post_meta_item post_date"><a href="/">Mar 4, 2024</a></span><a href="/home/neobanks-disrupting-the-traditional-banking-sector/#comments" className="post_meta_item post_meta_comments icon-comment-light inited"><span className="post_meta_number">3</span><span className="post_meta_label">Comments</span></a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            </div> */}
                                          </div>
                                          <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                                        </div>
                                        <div className="slider_pagination_wrap swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 1" aria-current="true"></span><span className="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span><span className="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 3"></span><span className="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>



                      </div>
                    </div>

                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>



  );


}

export default Userhome