import React from "react";
import { useEffect, useState } from "react";
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import "./OrdersDetails.scss"

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import LiveTrackingMapComponent from "../../../user-panel/common-component/LiveTrackingMapComponent/LiveTrackingMapComponent";
import { convertTo12HourTime, formatTimeFromMinutes } from "../../../GeneralFunctions";


const OrdersDetails = () => {

  const navigate = useNavigate()

  const [data, setData] = useState([]);


  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isDeleteConfModalOpen, setIsDeleteConfModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const PAYMENT_METHODS = ['paypal', 'card', 'cod', 'manual']

  const [page, setPage] = useState(1);
  const [pageSize, selectPageSize] = useState(12);

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])
  const loadData = () => {
    let apiUrl = `/order/orders/${localStorage.getItem('itemSelectedId')}`;
    setIsLoading(true)
    API.get(apiUrl)
      .then(response => {
        setData(response.data)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false)
      });
  }
  useEffect(() => {
    loadData();
  }, []);



  return (
    <div className="order-details-page">
      <div className="page-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-7">
              <div className="card">
                <div className="delivery-root">
                  {data &&
                    <div style={{ height: "450px" }}>
                      < LiveTrackingMapComponent
                        restaurantLocation={
                          data?.items?.[0]?.restaurant_name?.latitude ? {
                            lat: parseFloat(data.items[0].restaurant_name.latitude),
                            lng: parseFloat(data.items[0].restaurant_name.longitude),
                          } : null
                        }
                        // driverLocation={driverLocation}
                        customerLocation={
                          data?.delivery_latitude ? {
                            lat: parseFloat(data.delivery_latitude),
                            lng: parseFloat(data.delivery_longitude),
                          } : null
                        }
                      />
                    </div>
                  }

                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.8415665736!2d-0.26674604057231066!3d51.52873932359012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1714986124652!5m2!1sen!2sin" width="100%" height="450"
                    style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <ul className="nav setting-main-box driver-main-box sticky theme-scrollbar" id="v-pills-tab" role="tablist"
                        aria-orientation="vertical">
                        <li>
                          <button className="nav-link active" id="Settings-tab"
                            data-bs-toggle="pill" data-bs-target="#Settings" role="tab"
                            aria-controls="Settings" aria-selected="true">
                            <i className="ri-settings-line"></i>Order Status</button>
                        </li>
                        <li>
                          <button className="nav-link" id="Info-tab" data-bs-toggle="pill"
                            data-bs-target="#Info" role="tab"
                            aria-controls="Info" aria-selected="false">
                            <i className="ri-information-2-line"></i>Order Details</button>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12">
                      <div className="restaurant-tab">
                        <div className="tab-content" id="v-pills-tabContent">
                          <div className="tab-pane fade show active" id="Settings" role="tabpanel"
                            aria-labelledby="Settings-tab">
                            <div className="input-items">
                              <div className="row gy-3">
                                <div className="col-12">
                                  {data.is_pickup ?
                                    <div className="input-box driver-details-box ">
                                      <h6>Pickup</h6>
                                      <div className="driver-details-item pickup">
                                        <div className="driver-image pickup">
                                          <img className="img-fluid img" src="/images/svg/delivery1.svg" alt="p1"></img>
                                        </div>
                                        <div className="driver-content">
                                          <div className="driver-info pickup">
                                            {data.user_pickup_request_time ?
                                              <>
                                                <span className='sm-head'>Requested Time:</span> <br></br>
                                                <h5>
                                                  {data.user_pickup_request_date} <br></br>
                                                  {convertTo12HourTime(data.user_pickup_request_time)}
                                                </h5>
                                              </> :
                                              <div>
                                                <h5>
                                                  Pickup option has been selected by the customer.<br />
                                                  No pickup time requested.
                                                </h5>

                                              </div>
                                            }
                                          </div>
                                          {data.items?.[0]?.restaurant_name?.pickup_instruction &&
                                            <div className="driver-info">
                                              <span className='sm-head'>Estimated Delivery Time :</span>
                                              <h5>{data.items[0].restaurant_name.pickup_instruction}</h5>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="input-box driver-details-box">
                                      <h6>Drivers Information</h6>
                                      <div className="driver-details-item">
                                        <div className="driver-image">
                                          <img className="img-fluid img" src="/images/icons/p1.png" alt="p1"></img>
                                        </div>
                                        <div className="driver-content">
                                          <div className="driver-info">
                                            <h6>Driver Name :</h6>
                                            <h5>----</h5>
                                          </div>
                                          <div className="driver-info">
                                            <h6>Estimated Delivery Time :</h6>
                                            <h5>{data.estimated_delivery_time ? formatTimeFromMinutes(data.estimated_delivery_time) : "----"}</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </div>
                                <div className="col-12">
                                  <div className="input-box driver-details-box">
                                    <h6>Delivery Details</h6>
                                    <ul className="delivery-list">
                                      {/* <li>
                                        <img src="/images/svg/driver.svg" alt="" />
                                        <div>
                                          <h5>Driver position</h5>
                                          <p>Blackville</p>
                                        </div>
                                      </li> */}
                                      <li>
                                        <img src="/images/svg/placed.svg" alt="" />
                                        <div>
                                          <h5>Restaurant Address</h5>
                                          <p>
                                            {data?.items?.[0].restaurant_name.name}<br />
                                            {data?.items?.[0]?.restaurant_name?.primary_address && (
                                              [
                                                data.items[0].restaurant_name.primary_address.address_line1,
                                                data.items[0].restaurant_name.primary_address.premise,
                                                data.items[0].restaurant_name.primary_address.street,
                                                data.items[0].restaurant_name.primary_address.posttown,
                                                data.items[0].restaurant_name.primary_address.city,
                                                data.items[0].restaurant_name.primary_address.county,
                                                data.items[0].restaurant_name.primary_address.postcode,
                                                data.items[0].restaurant_name.primary_address.country,
                                              ]
                                                .filter(Boolean) // Remove any undefined or null values
                                                .join(', ')
                                            )}
                                          </p>
                                        </div>
                                      </li>
                                      <li>
                                        <img src="/images/svg/user-map.svg" alt="" />
                                        <div>
                                          <h5>Delivery Address</h5>
                                          <p>
                                            {
                                              [
                                                data.first_name,
                                                data.last_name,
                                                data.address_line1,
                                                data.premise,
                                                data.street,
                                                data.posttown,
                                                data.city,
                                                data.county,
                                                data.postcode,
                                                data.country,
                                              ]
                                                .filter(Boolean) // Remove any undefined or null values
                                                .join(', ')
                                            }
                                          </p>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="Info" role="tabpanel"
                            aria-labelledby="Info-tab">
                            <div className="checkout-detail">
                              <div className="cart-address-box">
                                <div className="add-img">
                                  <img className="img-fluid img sm-size"
                                    src="/images/svg/location.svg" alt="rp1"></img>
                                </div>
                                <div className="add-content">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="dark-text deliver-place">
                                      Deliver to :
                                      <i className="ri-check-line"></i>
                                    </h5>
                                  </div>
                                  <h6 className="address mt-sm-2 mt-1 content-color">
                                    {
                                      [
                                        data.first_name,
                                        data.last_name,
                                        data.address_line1,
                                        data.premise,
                                        data.street,
                                        data.posttown,
                                        data.city,
                                        data.county,
                                        data.postcode,
                                        data.country,
                                      ]
                                        .filter(Boolean) // Remove any undefined or null values
                                        .join(', ')
                                    }
                                  </h6>
                                </div>
                              </div>
                              <div className="cart-address-box mt-3">
                                <div className="add-img">
                                  <img className="img-fluid img sm-size"
                                    src="/images/svg/wallet-add.svg" alt="rp1"></img>
                                </div>
                                <div className="add-content">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h5 className="dark-text deliver-place">
                                      Payment Method: <i className="ri-check-line"></i>
                                    </h5>
                                  </div>
                                  <h6 className="address mt-sm-2 mt-1 content-color">
                                    {data.payment_method && PAYMENT_METHODS[parseInt(data.payment_method)]}
                                  </h6>
                                </div>
                              </div>
                              {/* delivery instructions */}
                              {data.delivery_instructions &&
                                <div className="cart-address-box mt-3">
                                  <div className="add-img">
                                    <img className="img-fluid img sm-size" src="/images/svg/wallet-add.svg"
                                      alt="rp1"></img>
                                  </div>
                                  <div className="add-content">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h5 className="dark-text deliver-place">Delivery Instructions:</h5>
                                    </div>
                                    <h6 className="address mt-2 content-color instructions-br">
                                      {data.delivery_instructions}
                                    </h6>
                                  </div>

                                </div>
                              }
                              {/* cooking instructions */}
                              {data.cooking_instructions &&
                                <div className="cart-address-box mt-3">
                                  <div className="add-img">
                                    <img className="img-fluid img sm-size" src="/images/svg/wallet-add.svg"
                                      alt="rp1"></img>
                                  </div>
                                  <div className="add-content">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <h5 className="dark-text deliver-place">Cooking Instructions:</h5>
                                    </div>
                                    <h6 className="address mt-2 content-color instructions-br">
                                      {data.cooking_instructions}
                                    </h6>
                                  </div>

                                </div>
                              }
                              {/* menu items */}
                              <ul>
                                {data.items && data.items.length > 0 && data.items.map((item, index) => (
                                  <li key={index}>
                                    <div className="horizontal-product-box">
                                      <div className="product-content">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <h5>{item.menu_item_detail.name}</h5>
                                          <h6 className="product-price">£{parseFloat(item.price) * parseInt(item.count)}</h6>
                                        </div>
                                        {item.topping &&
                                          <h6 className="ingredients-text">{item.topping_details.description}</h6>
                                        }
                                      </div>
                                    </div>
                                  </li>

                                ))}

                              </ul>
                              <h5 className="bill-details-title fw-semibold dark-text">
                                Bill Details
                              </h5>
                              <div className="sub-total">
                                <h6 className="content-color fw-normal">Sub Total</h6>
                                <h6 className="fw-semibold">£{Math.round(((data.sub_total) + Number.EPSILON) * 100) / 100}</h6>
                              </div>
                              <div className="sub-total">
                                <h6 className="content-color fw-normal">
                                  Delivery Charge
                                </h6>
                                <h6 className="fw-semibold success-color">
                                  £ {data.delivery_charge && data.delivery_charge.toFixed(2)}
                                </h6>
                              </div>
                              <div className="sub-total">
                                <h6 className="content-color fw-normal">
                                  Discount (10%)
                                </h6>
                                <h6 className="fw-semibold">undefined</h6>
                              </div>
                              <div className="grand-total">
                                <h6 className="fw-semibold dark-text">Total</h6>
                                <h6 className="fw-semibold amount">£{parseFloat(data.total_amount)}</h6>
                              </div>
                              <img className="dots-design" src="/images/svg/dots-design.svg"
                                alt="dots"></img>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => setIsMessageModalOpen(false)} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )
}


export default OrdersDetails