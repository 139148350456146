// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-modal .card {
  width: 500px;
  padding: 0;
}
.location-modal .modal-header {
  width: 100%;
  padding: 20px 20px 20px 20px !important;
  border-bottom: #dadada 1px solid;
  border-color: #dadada !important;
}
.location-modal .modal-body {
  padding: 20px 20px 20px 20px !important;
}
.location-modal .modal-body .current-location.disabled {
  cursor: default;
}
.location-modal .modal-footer {
  width: 100%;
  padding: 10px 10px 10px 10px !important;
  border-top: #dadada 1px solid;
  border-color: #dadada !important;
}
.location-modal .search-section input {
  width: 100%;
  background-color: rgba(var(--box-bg), 1);
  border: none;
  padding: calc(10px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  border: 1px solid rgba(var(--dashed-line), 1);
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/HeaderLocationPopup/HeaderLocationPopup.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,UAAA;AAAR;AAGI;EACI,WAAA;EACA,uCAAA;EACA,gCAAA;EACA,gCAAA;AADR;AAII;EACI,uCAAA;AAFR;AAIY;EACI,eAAA;AAFhB;AAMI;EACI,WAAA;EACA,uCAAA;EACA,6BAAA;EACA,gCAAA;AAJR;AAMI;EACI,WAAA;EACA,wCAAA;EACA,YAAA;EACA,gDAAA;EACA,cAAA;EACA,kDAAA;EACA,6CAAA;EACA,kBAAA;AAJR","sourcesContent":[".location-modal {\r\n    .card {\r\n        width: 500px;\r\n        padding: 0;\r\n    }\r\n\r\n    .modal-header {\r\n        width: 100%;\r\n        padding: 20px 20px 20px 20px !important;\r\n        border-bottom: #dadada 1px solid;\r\n        border-color: #dadada !important;\r\n    }\r\n\r\n    .modal-body {\r\n        padding: 20px 20px 20px 20px !important;\r\n        .current-location{\r\n            &.disabled{\r\n                cursor: default;\r\n            }\r\n        }\r\n    }\r\n    .modal-footer {\r\n        width: 100%;\r\n        padding: 10px 10px 10px 10px !important;\r\n        border-top: #dadada 1px solid;\r\n        border-color: #dadada !important;\r\n    }\r\n    .search-section  input {\r\n        width: 100%;\r\n        background-color: rgba(var(--box-bg), 1);\r\n        border: none;\r\n        padding: calc(10px + 2 * (100vw - 320px) / 1600);\r\n        line-height: 1;\r\n        font-size: calc(14px + 2 * (100vw - 320px) / 1600);\r\n        border: 1px solid rgba(var(--dashed-line), 1);\r\n        border-radius: 5px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
