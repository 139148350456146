// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty .star {
  color: rgb(212, 212, 212);
}

.common-rating-section .rating-star {
  display: flex;
  align-items: center;
  gap: 1px;
}
.common-rating-section .rating-star li {
  padding: 0;
  border-bottom: none;
  line-height: 1;
}
.common-rating-section .rating-star li .star {
  color: rgba(var(--rate-color), 1);
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
  line-height: 1;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/common-component/RatingStar/RatingStar.scss"],"names":[],"mappings":"AACI;EACI,yBAAA;AAAR;;AAII;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AADR;AAII;EACI,UAAA;EACA,mBAAA;EACA,cAAA;AAFR;AAKI;EACI,iCAAA;EACA,kDAAA;EACA,cAAA;AAHR","sourcesContent":[".empty{\r\n    .star{\r\n        color: rgb(212, 212, 212);\r\n    }\r\n}\r\n.common-rating-section {\r\n    .rating-star {\r\n        display: flex;\r\n        align-items: center;\r\n        gap: 1px;\r\n    }\r\n\r\n    .rating-star li {\r\n        padding: 0;\r\n        border-bottom: none;\r\n        line-height: 1;\r\n    }\r\n\r\n    .rating-star li .star {\r\n        color: rgba(var(--rate-color), 1);\r\n        font-size: calc(14px + 2 * (100vw - 320px) / 1600);\r\n        line-height: 1;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
