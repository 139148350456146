import React from "react";
import "./Footer.scss";

import { useNavigate } from 'react-router-dom';

const Footer = () => {
  let navigate = useNavigate();
  var LOGOURL;

  if (window.innerWidth > 768) {
    LOGOURL = "/images/zog-logo.svg"
  }
  else {

    LOGOURL = "/images/zog-logo-mobile-footer.svg"
  }


  return (
    <div class="home page-template page-template-elementor_header_footer page page-id-1666 wp-custom-logo qodef-qi--no-touch qi-addons-for-elementor-1.7.2 jkit-color-scheme elementor-default elementor-template-full-width elementor-kit-6 elementor-page elementor-page-1666 e--ua-firefox"
    data-elementor-device-mode="desktop">
    <div className="ekit-template-content-markup ekit-template-content-footer ekit-template-content-theme-support">
    <div data-elementor-type="wp-post" data-elementor-id="78" className="elementor elementor-78">
      <div className="elementor-element elementor-element-c31da1a e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="c31da1a" data-element_type="container" >
      <div className="e-con-inner">
        <div className="elementor-element elementor-element-6107d0b e-flex e-con-boxed e-con e-child" data-id="6107d0b" data-element_type="container">
          <div className="e-con-inner">
            <div className="elementor-element elementor-element-10d6fd1 e-con-full e-flex e-con e-child" data-id="10d6fd1" data-element_type="container">
              <div className="elementor-element elementor-element-9507c36 e-flex e-con-boxed e-con e-child" data-id="9507c36" data-element_type="container">
                <div className="e-con-inner">
                  <div className="elementor-element elementor-element-eb057b4 elementor-widget elementor-widget-image" data-id="eb057b4" data-element_type="widget" data-widget_type="image.default">
                    <div className="elementor-widget-container">
                      <a href="/home">
                      <img width="160" height="35" src={window.innerWidth>768?"/images/logo/logo-footer.svg":"/images/logo/primary-logo.svg"} className="attachment-large size-large wp-image-153" alt="" decoding="async" />								
                      </a>
                      		</div>
                      
                      </div>
                  <div className="elementor-element elementor-element-bb89bdc elementor-widget elementor-widget-text-editor" data-id="bb89bdc" data-element_type="widget" data-widget_type="text-editor.default">
                    <div className="elementor-widget-container">
                      <p>We are committed to offering personalised financial services that help you achieve your goals with confidence and clarity. </p>						</div>
                  </div>
                  <div className="elementor-element elementor-element-7f17c9b elementor-widget elementor-widget-elementskit-social-media" data-id="7f17c9b" data-element_type="widget" data-widget_type="elementskit-social-media.default">
                    <div className="elementor-widget-container">
                      <div className="ekit-wid-con">			 <ul className="ekit_social_media">
                        <li className="elementor-repeater-item-e49ca6d">
                          <a className="facebook">
                          <svg aria-hidden="true" className="e-font-icon-svg e-fab-x-facebook" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06H297V6.26S273.55 0 248.29 0c-73.22 0-120.92 44.38-120.92 124.72v70.62H92.66V288h34.71v224h100.17V288z"/>
</svg>


                          </a>
                        </li>
                        <li className="elementor-repeater-item-fb0a372">
                          <a className="linkedin">

                          <svg aria-hidden="true" className="e-font-icon-svg e-fab-x-linkedin" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <path fill="currentColor" d="M426 0H86C38.6 0 0 38.6 0 86v340c0 47.4 38.6 86 86 86h340c47.4 0 86-38.6 86-86V86c0-47.4-38.6-86-86-86zM166.7 416.5h-65V208.6h65v207.9zm-32.6-237.2h-.4c-21.9 0-36.1-15-36.1-33.7 0-19.1 14.5-33.7 36.8-33.7s36.1 14.6 36.5 33.7c0 18.7-14.3 33.7-36.8 33.7zm264.9 237.2h-64.9v-112c0-28.2-10.1-47.4-35.2-47.4-19.2 0-30.6 13-35.7 25.6-1.8 4.4-2.2 10.5-2.2 16.7v117.1h-64.9V208.6h64.9v28.4c8.6-13.2 24-32.1 58.3-32.1 42.5 0 74.4 27.7 74.4 87.3v124.3z"/>
</svg>


                          </a>
                        </li>
                        <li className="elementor-repeater-item-200f023">
                          <a className="twitter">

                            <svg aria-hidden="true" className="e-font-icon-svg e-fab-x-twitter" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                          </a>
                        </li>
                        <li className="elementor-repeater-item-b815b6c">
                          <a className="instagram">

                            <svg aria-hidden="true" className="e-font-icon-svg e-fab-instagram" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                          </a>
                        </li>
                      </ul>
                      </div>		</div>
                  </div>
                  <div className="elementor-element elementor-element-e396fd7 elementor-widget elementor-widget-spacer" data-id="e396fd7" data-element_type="widget" data-widget_type="spacer.default">
                    <div className="elementor-widget-container">
                      <link rel="stylesheet" href="css/style34.css" />		<div className="elementor-spacer">
                        <div className="elementor-spacer-inner"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="elementor-element elementor-element-1420b9a e-flex e-con-boxed e-con e-child" data-id="1420b9a" data-element_type="container">
              <div className="e-con-inner">
                
                <div className="elementor-element elementor-element-b012ca0 e-con-full e-flex e-con e-child" data-id="b012ca0" data-element_type="container">
                  <div className="elementor-element elementor-element-a0e4bb6 e-con-full e-flex e-con e-child" data-id="a0e4bb6" data-element_type="container">
                    <div className="elementor-element elementor-element-42207b4 elementor-widget elementor-widget-heading" data-id="42207b4" data-element_type="widget" data-widget_type="heading.default">
                      <div className="elementor-widget-container">
                      <a href="/about-us"> <h5 className="elementor-heading-title elementor-size-default">About Us</h5>		</a> </div>
                    </div>
                    <div className="elementor-element elementor-element-8a77f38 elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_interactive_link_showcase" data-id="8a77f38" data-element_type="widget" data-widget_type="qi_addons_for_elementor_interactive_link_showcase.default">
                      <div className="elementor-widget-container">
                        <div className="qodef-shortcode qodef-m qodef-qi-interactive-link-showcase qodef-layout--background qodef-hover--underline qodef--init">
                          <div className="qodef-m-items">
                            <a itemprop="url" className="qodef-m-item qodef-e qodef--active" data-savepage-href="#" href="/risk-disclosure-policy" target="_self">
                              <span className="qodef-e-title"><span className="qodef-e-inner-title">Risk Disclosure Policy</span></span>
                            </a>

                             <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/responsible-investment-policy" target="_self">
                              <span className="qodef-e-title"><span className="qodef-e-inner-title">Responsible Investment Policy</span></span>
                              </a>
                             {/* <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="#" target="_self">
                              <span className="qodef-e-title"><span className="qodef-e-inner-title">Gallery</span></span>
                              </a>
                              <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="#" target="_self">
                              <span className="qodef-e-title"><span className="qodef-e-inner-title">Faq</span></span>
                              </a> */}
                              <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/contact-us" target="_self">
                                <span className="qodef-e-title"><span className="qodef-e-inner-title">Contact Us</span></span>
                              </a>
                          </div>
                          <div className="qodef-m-images">
                            <div className="qodef-e-image qodef-background qodef--active" style={{backgroundImage: `url()`}}>
                            </div>
                            <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                            </div>
                            <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                            </div>
                            <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                            </div>
                            <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-b37433f e-con-full e-flex e-con e-child" data-id="b37433f" data-element_type="container">
                    <div className="elementor-element elementor-element-fe10d09 e-flex e-con-boxed e-con e-child" data-id="fe10d09" data-element_type="container">
                      <div className="e-con-inner">
                        <div className="elementor-element elementor-element-5f49fc9 elementor-widget elementor-widget-heading" data-id="5f49fc9" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                          <a href="/services"> <h5 className="elementor-heading-title elementor-size-default">Services</h5></a>		</div>
                        </div>
                        <div className="elementor-element elementor-element-252fab3 elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_interactive_link_showcase" data-id="252fab3" data-element_type="widget" data-widget_type="qi_addons_for_elementor_interactive_link_showcase.default">
                          <div className="elementor-widget-container">
                            <div className="qodef-shortcode qodef-m qodef-qi-interactive-link-showcase qodef-layout--background qodef-hover--underline qodef--init">
                              <div className="qodef-m-items">
                                <a itemprop="url" className="qodef-m-item qodef-e qodef--active" data-savepage-href="#" href="/services/investing-and-saving" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Investments & Savings </span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/mortgage-services" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Mortgage Services </span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/pension-and-retirement" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Pension and Retirement </span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/insurance-services" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Insurance Services </span></span>
                                </a> 
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/wealth-management" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Wealth Management</span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/real-estate-finance" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Real Estate Finance</span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/personal-financial-planning" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Personal Financial Planning</span></span>
                                </a>
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="/services/financial-risk-advisory" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Financial Risk Advisory</span></span>
                                </a>
                              </div>
                              <div className="qodef-m-images">
                                <div className="qodef-e-image qodef-background qodef--active" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="elementor-element elementor-element-44cba8c e-con-full e-flex e-con e-child" data-id="44cba8c" data-element_type="container">
                    <div className="elementor-element elementor-element-6f970d6 e-flex e-con-boxed e-con e-child" data-id="6f970d6" data-element_type="container">
                      <div className="e-con-inner">
                        <div className="elementor-element elementor-element-4e8c3d6 elementor-widget elementor-widget-heading" data-id="4e8c3d6" data-element_type="widget" data-widget_type="heading.default">
                          <div className="elementor-widget-container">
                          <a href="/contact-us"> <h5 className="elementor-heading-title elementor-size-default">Contact Us</h5>	</a>	</div>
                        </div>
                        <div className="elementor-element elementor-element-82d6d52 elementor-widget__width-initial elementor-widget elementor-widget-qi_addons_for_elementor_interactive_link_showcase" data-id="82d6d52" data-element_type="widget" data-widget_type="qi_addons_for_elementor_interactive_link_showcase.default">
                          <div className="elementor-widget-container">
                            <div className="qodef-shortcode qodef-m qodef-qi-interactive-link-showcase qodef-layout--background qodef-hover--underline qodef--init">
                              <div className="qodef-m-items">
                                <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="#" target="_self">
                                  <span className="qodef-e-title">
                                    <span className="qodef-e-inner-title">Address: </span>
                                    </span>
                                    

                                    <br/>
                                  <a href="tel:02045424350"><span className="qodef-e-text">Suite V3,<br/> 4 Woodland Road, <br/>DL3 7PJ </span>
                                  </a>


                                </a>
                                <br/>
                                 <a itemprop="url" className="qodef-m-item qodef-e" data-savepage-href="#" href="#" target="_self">
                                  <span className="qodef-e-title">
                                    <span className="qodef-e-inner-title">Phone:</span>
                                  </span>
                                  <br/>
                                  <a href="tel:02045424350"><span className="qodef-e-text">02045424350 </span>
                                  </a>
                                </a>
                                <br/> <a itemprop="url" className="qodef-m-item qodef-e qodef--active" data-savepage-href="#" href="#" target="_self">
                                  <span className="qodef-e-title"><span className="qodef-e-inner-title">Email:</span></span>
                                  <br/>
                                  <a href="mailto:connect@reservefs.com"><span className="qodef-e-text email">connect@reservefs.com </span></a>
                                </a>
                              </div>
                              <div className="qodef-m-images">
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background" style={{backgroundImage: `url()`}}>
                                </div>
                                <div className="qodef-e-image qodef-background qodef--active" style={{backgroundImage: `url()`}}>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="elementor-element elementor-element-6d8cb81 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="6d8cb81" data-element_type="container" >
    <div className="e-con-inner">
      <div className="elementor-element elementor-element-21ba73b elementor-widget elementor-widget-text-editor" data-id="21ba73b" data-element_type="widget" data-widget_type="text-editor.default">
        <div className="elementor-widget-container">
          <p>Copyright 2024, All Rights Reserved. Powered by Zog Global <a href="/privacy-policy">Privacy Policy</a> <a href="/cookie-policy">Cookie Policy</a> <a href="/terms-and-conditions"> Terms and Conditions</a></p>						</div>
      </div>
    </div>
  </div>
    </div >
 
    </div>
    </div>
  );
};

export default Footer;