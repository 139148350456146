import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import API from '../../../API';
import './ProfileSettings.scss'
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import LogoutPopup from '../../../authentication/LogoutPopup';
import { UserContext } from '../../../authentication/pages/UserContext';

const ProfileSettings = ({ userData, loadUserDataForHeader }) => {

  const navigate = useNavigate()
  const [isLogoutModalShow, setLogoutModalShow] = useState(false)
  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmType, setConfirmType] = useState(null);
  const userID = localStorage.getItem('userID');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    cooking_instructions: '',
  });

  const { isLoggedIn, setIsLoggedIn, login, logout } = useContext(UserContext);


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleConfirmAction = () => {
    if (confirmType === 'deactivate') {
      deactivateAccount();
    } else if (confirmType === 'delete') {
      deleteAccount();
    }
    else if (confirmType === 'cancel_delete_request') {
      cancelDeleteRequest();
    }
    setIsConfirmModalOpen(false);
  };

  const handleSuccessButton = () => {
    if (confirmType === 'deactivate') {
      logout();
      setIsLoggedIn(false);
      setTimeout(navigate("/home"), 1000)
      setIsMessageModalOpen(false)
    } else {
      setIsMessageModalOpen(false)
    }
    setIsConfirmModalOpen(false);
  };

  const deactivateAccount = () => {
    setIsLoading(true)
    API.put(`/user/users/${userID}/`, { is_deactivated: true })
      .then(response => {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
        setMessage('Your account has been successfully deactivated.')
        setIsMessageModalOpen(true);

      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }

  const deleteAccount = () => {
    setIsLoading(true)
    API.put(`/user/users/${userID}/`, { is_deleted: true })
      .then(response => {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
        setMessage('Your account deletion request has been sent.')
        setIsMessageModalOpen(true);
        loadUserDataForHeader();
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }

  const cancelDeleteRequest = () => {
    setIsLoading(true)
    API.put(`/user/users/${userID}/`, { is_deleted: false })
      .then(response => {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
        setMessage('Your account deletion request has been cancelled.')
        setIsMessageModalOpen(true);
        loadUserDataForHeader();
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }
  const downloadCustomerData = () => {
    setIsLoading(true)
    API.get(`/user/export-user-data/${userID}/`, { responseType: 'blob' })
      .then(response => {
        setIsLoading(false);

        // Create a URL for the blob and set it as the href for a download link
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${userData.username}_data.zip`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }

  // Add cooking Instructions
  const UpdateUserData = () => {
    setIsLoading(true)
    API.put(`/user/users/${userID}/`, formData)
      .then(response => {
        setIsLoading(false);
        setIsConfirmModalOpen(false);
        setMessage('Details Saved Successfully!')
        setIsMessageModalOpen(true);
        loadUserDataForHeader();
      })
      .catch(error => {
        setIsLoading(false)
        setMessage(error.response?.data?.message || error.message)
        setIsErrorModalOpen(true)
      });
  }

  return (
    <div className='user-profile-setting'>

      <section className="page-head-section">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Profile</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <a href="index.html"><i className="ri-home-line"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Profile</li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="profile-section section-b-space">
        <div className="container">
          <div className="row g-3">
            <div className="col-lg-3">
              <div className="profile-sidebar sticky-top">
                <div className="profile-cover">
                  <img className="img-fluid profile-pic" src={`${userData && userData.profile_image ? userData.profile_image : '/images/no-profile-image.png'}`} alt="profile" />
                </div>
                <div className="profile-name">
                  <h5 className="user-name">
                    {userData && userData.first_name} &nbsp;
                    {userData && userData.last_name}
                  </h5>
                  <h6>{userData && userData.email}</h6>
                </div>
                <ul className="profile-list">
                  <li>
                    <i className="ri-user-3-line"></i>
                    <a onClick={() => navigate('/profile')}>Change Profile</a>
                  </li>
                  <li>
                    <i className="ri-shopping-bag-3-line"></i>
                    <a onClick={() => navigate('/orders')}>My Order</a>
                  </li>
                  <li>
                    <i className="ri-map-pin-line"></i>
                    <a onClick={() => navigate('/saved-address')}>Saved Address</a>
                  </li>
                  <li>
                    <i className="ri-bank-card-line"></i>
                    <a onClick={() => navigate('/saved-payment-methods')}>Payment Methods</a>
                  </li>
                  <li>
                    <i className="ri-medicine-bottle-line"></i>
                    <a onClick={() => navigate('/allergies')}>Allergens</a>
                  </li>
                  <li>
                    <i className="ri-question-line"></i>
                    <a onClick={() => navigate('/faq')}>Help</a>
                  </li>
                  <li className="active">
                    <i className="ri-settings-3-line"></i>
                    <a onClick={() => navigate('/profile-settings')}>Settings</a>
                  </li>
                  <li>
                    <i className="ri-logout-box-r-line"></i>
                    <a onClick={() => setLogoutModalShow(true)}>Log Out</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="setting-content">
                <div className="title">
                  <h3>Settings</h3>
                </div>
                <div className='download-sec mb-4'>
                  <h3 className="fw-medium dark-text">Cooking Instructions</h3>
                  <p className="content-color">
                    Please provide any general cooking preferences or instructions, so you can easily apply these when checking out your order.
                  </p>
                  <p className='content-color mt-2'>
                      For example, "No sugar," "Less salt," or "Extra sauce."
                    </p>
                  <div className='mt-1 mb-1'>
                    <textarea id="cooking_instruction" name="cooking_instruction" className="form-control" rows={3}
                      placeholder="Enter your cooking instructions here..." onChange={handleInputChange}
                      value={userData && userData.cooking_instruction} />
                  </div>
                  <div className='d-flex justify-content-center align-items-center'>
                    <button className="btn theme-btn mt-2" onClick={UpdateUserData} >
                      Save
                    </button>
                  </div>
                </div>
                {/* download */}
                <div className='download-sec mb-4'>
                  <h3 className="fw-medium dark-text">Download Your Data</h3>
                  <p className="content-color">
                    You can download a copy of your data for your records. This will include your profile information, order history, and other relevant data.
                  </p>
                  <button className="btn theme-btn download-btn mt-2"
                    onClick={downloadCustomerData}
                  >
                    <span className='me-1'>Download Data</span>
                    <i className="fa-solid fa-download"></i>
                  </button>
                </div>
                {/* Notification preference */}
                <ul className="notification-setting d-none">
                  <li>
                    <div className="notification pt-0">
                      <h6 className="fw-normal dark-text">Offer Update</h6>
                      <div className="switch-btn">
                        <input type="checkbox" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="notification">
                      <h6 className="fw-normal dark-text">Order Update</h6>
                      <div className="switch-btn">
                        <input type="checkbox" />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="notification pb-0">
                      <h6 className="fw-normal dark-text">New Update</h6>
                      <div className="switch-btn">
                        <input type="checkbox" />
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="delete-account ">
                  <h3 className="fw-medium dark-text">Deactivate Your Account</h3>
                  <p className="content-color">
                    Hi <span className="dark-text fw-medium">
                      {userData && userData.first_name} &nbsp;
                      {userData && userData.last_name}
                    </span>,
                  </p>
                  <p className="content-color">
                    We understand that you may need a break. Deactivating your account will hide your profile and personal information from others. You can reactivate your account at any time by simply logging back in.
                  </p>
                  <h6 className="dark-text fw-medium mt-sm-3 mt-2 mb-2">Note :</h6>
                  <p className="content-color">
                    While your account is deactivated, you won't receive any notifications, and your profile will not be visible. However, your data will remain intact, and you can reactivate your account whenever you wish.
                  </p>
                  <p className="content-color mt-2">
                    If you would like to proceed with deactivation, please click the button below.
                  </p>
                  <a onClick={() => { setConfirmType('deactivate'); setIsConfirmModalOpen(true); }} className="btn theme-btn deactivate-btn mt-3">
                    Deactivate Account
                  </a>
                </div>

                <div className="delete-account">
                  <h3 className="fw-medium dark-text">Delete Your Account</h3>
                  {userData && userData.is_deleted ? (
                    <>
                      <p className="content-color">
                        Your account deletion request was received on&nbsp;
                        <strong>{new Date(userData.delete_requested_at).toLocaleDateString()}</strong>.
                      </p>
                      <p className="content-color">
                        Your account will be permanently deleted in 30 days from the request date, which is&nbsp;
                        <strong>
                          {new Date(new Date(userData.delete_requested_at).setDate(new Date(userData.delete_requested_at).getDate() + 30)).toLocaleDateString()}
                        </strong>.
                      </p>
                      <p className="content-color mt-2">
                        If you change your mind, you can cancel your deletion request.
                        Click the button below to send a cancellation request or contact support for further assistance.
                      </p>
                      <a onClick={() => { setConfirmType('cancel_delete_request'); setIsConfirmModalOpen(true); }} className="btn theme-btn delete-btn mt-3" >Cancel Request</a>
                    </>
                  ) : (
                    <>
                      <p className='content-color'>
                        To permanently delete your account and remove data, please click the button below.
                      </p>
                      <a onClick={() => { setConfirmType('delete'); setIsConfirmModalOpen(true); }} className="btn theme-btn delete-btn mt-3" >Delete Account</a>

                      <h6 className="dark-text fw-medium mt-sm-3 mt-2 mb-2">Note :</h6>
                      <p className="content-color">
                        Deleting your account will permanently remove your profile,
                        personal settings, and all other associated information. once
                        your account is deleted, you will be logged out and will be
                        unable to log back in.
                      </p>
                      <p className="content-color info mt-2">
                        Your account will be deleted in 30 days. If you
                        change your mind, you can log back in within this period to
                        cancel the deletion request.
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {isConfirmModalOpen &&
        <div className='custom-modal confirm-modal'>
          <div className='card'>
            <div className='first-screen'>
              <img
                src={`/images/${confirmType === 'deactivate' ? 'authentication-screens/logout-popup-icon.svg' :
                  confirmType === 'cancel_delete_request' ? 'authentication-screens/logout-popup-icon.svg' : 'delete-popup-icon.svg'}`} />
              <h1>{confirmType === 'deactivate' ? 'Deactivation' : confirmType === 'cancel_delete_request' ? 'Cancel' : 'Delete'}</h1>
              {confirmType === 'cancel_delete_request' ?
                <p>Are you sure you want to cancel account deletion request?</p>
                :
                <p>Are you sure you want to {confirmType === 'deactivate' ? 'deactivate' : 'delete'} your account?</p>
              }
              <div className='footer'>
                <button type='button' className='cancel-button' onClick={() => { setIsConfirmModalOpen(false) }}>Cancel</button>
                <button type='button' className='ok-button'
                  onClick={handleConfirmAction} >
                  {confirmType === 'deactivate' ? 'Deactivate' :
                    confirmType === 'cancel_delete_request' ? 'Proceed' :
                      'Delete'}
                </button>
              </div>
            </div>
          </div>
        </div>
      }

      {isLogoutModalShow && <LogoutPopup setterFunction={setLogoutModalShow}></LogoutPopup>}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => { setIsErrorModalOpen(false) }} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen}
        okClickedFunction={() => handleSuccessButton()} />}
      {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )

}

export default ProfileSettings