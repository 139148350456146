import React, { useEffect } from 'react'
import './PrivacyPolicy.scss'
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function () {
            $(window).scrollTop(0);
        });
    }, [])

    // header scroll
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            // Check if scroll position is at 100vh
            if (scrollPosition >= 5) {
                $(".header").addClass("shrinked");
            } else {
                $(".header").removeClass("shrinked");

            }
        }
        window.addEventListener('scroll', handleScroll);
        // Clean up event listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className=' privacy-policy'>

            <div className="body_wrap">
                <div className="page_wrap">

                    <header className="top_panel top_panel_custom top_panel_custom_92 top_panel_custom_header-default&#9;&#9;&#9;&#9; without_bg_image scheme_dark">
                        <div data-elementor-type="cpt_layouts" data-elementor-id="92" className="elementor elementor-92">
                            <section className="elementor-section elementor-top-section elementor-element elementor-element-0ee19b5 elementor-section-full_width sc_layouts_row sc_layouts_row_type_compact elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="0ee19b5" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}"> <div className="elementor-background-overlay"></div>
                                <div className="elementor-container elementor-column-gap-extended">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d2ebbc2 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d2ebbc2" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">

                                            <div className="sc_layouts_item elementor-element elementor-element-caf6eed sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="caf6eed" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-d67700c elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="d67700c" data-element_type="section">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-f56af19 sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="f56af19" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="sc_layouts_item elementor-element elementor-element-1970e32 sc_fly_static elementor-widget elementor-widget-trx_sc_layouts_title trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="1970e32" data-element_type="widget" data-widget_type="trx_sc_layouts_title.default">
                                                                <div className="elementor-widget-container">
                                                                    <div className="sc_layouts_title sc_align_center with_content without_image without_tint">
                                                                        <div className="sc_layouts_title_content">
                                                                            <div className="sc_layouts_title_title">
                                                                                <h1 className="sc_layouts_title_caption">Terms And Conditions</h1>
                                                                            </div>
                                                                            <div className="sc_layouts_title_breadcrumbs">
                                                                                <div className="breadcrumbs"><a className="breadcrumbs_item home underline_hover" href="https://zurich.axiomthemes.com/">Home</a><span className="breadcrumbs_delimiter"></span><span className="breadcrumbs_item current">Terms And Conditions</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div className="sc_layouts_item elementor-element elementor-element-7672996 sc_fly_static elementor-widget elementor-widget-spacer trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="7672996" data-element_type="widget" data-widget_type="spacer.default">
                                                <div className="elementor-widget-container">
                                                    <div className="elementor-spacer">
                                                        <div className="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </header>

                    <div className="page_content_wrap">
                        <div className="content_wrap_fullscreen">
                            <div className="content">
                                <article id="post-211" className="post_item_single post_type_page post-211 page type-page status-publish hentry">
                                    <div className="post_content entry-content">
                                        <div data-elementor-type="wp-page" data-elementor-id="211" className="elementor elementor-211">

                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">

                                                                    <p>Welcome to Reserve Financial Services (“we”, “us”, “our”). By accessing our website and using our services, you agree to comply with and be bound by the following terms and conditions.  </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Privacy Statement</h5>
                                                                    <p>At Reserve Financial Services we are committed to safeguarding your privacy. Information collected from our clients is accessed only by authorised employees, strictly on a need-to-know basis. We regularly review our systems and data to ensure we deliver the best possible service. Unauthorised actions against computer systems and data are criminal offenses, and we will pursue legal action against any parties responsible for such activities.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Confidentiality</h5>
                                                                    <p>We comply with all relevant data protection laws, including the General Data Protection Regulation (GDPR) and the Data Protection Act 1998. Client information may be shared with third parties, such as suppliers, but only when necessary or legally required by authorities. Client records are treated as confidential and will not be disclosed to third parties without consent, except under these circumstances. Clients have the right to request access to their records, provided reasonable notice is given. We recommend that clients keep copies of any communications, documents, or literature issued during the provision of our services. Where relevant, we will provide clients with written materials as part of an agreed contract.

                                                                        We do not sell, rent, or share your personal information with third parties for marketing purposes. Any communication from our company will only be in connection with the agreed services and products. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Disclaimer
                                                                        Exclusions and Limitations</h5>
                                                                    <p>The information on this website is provided on an "as is" basis. To the maximum extent permitted by law, Reserve Financial Services:</p>

                                                                    <ul>
                                                                        <li>
                                                                            Excludes all representations and warranties related to this website and its content, including any inaccuracies or omissions from information provided by affiliates or third parties.
                                                                        </li>
                                                                        <li>
                                                                            Excludes all liability for any damages arising from the use of this website, including but not limited to, loss of business or profits (regardless of whether such losses were foreseeable or if the company was advised of the potential for loss), damage to your computer, software, or data, or any indirect or consequential damages. However, this does not exclude liability for personal injury or death caused by our negligence. The exclusions and limitations stated here apply only to the extent allowed by law and do not affect your statutory consumer rights.
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Client Agreement </h5>
                                                                    <p>Upon engaging our services, a formal agreement will be established outlining the specific terms, including fees, duration, and other pertinent details. This agreement will take precedence over these terms in case of any discrepancies. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Fees and Payments </h5>
                                                                    <p>Our fees vary depending on the services provided. Details of our fee structure will be communicated during the initial consultation and will be outlined in your client agreement. All payments are due as specified in the agreement, and late payments may incur additional charges. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Liability </h5>
                                                                    <p>While we strive to provide accurate and up-to-date information, we do not guarantee that all information on our website or provided through our services is complete or error-free. We will not be held liable for any losses or damages arising from your reliance on our services or the information provided. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Intellectual Property </h5>
                                                                    <p>All content on our website, including text, graphics, logos, and images, is the property of Reserve Financial Services or its content suppliers and is protected by intellectual property laws. You may not reproduce, distribute, or modify any content without our prior written consent. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Links to Third-Party Websites </h5>
                                                                    <p>Our website may contain links to third-party websites for your convenience. We do not endorse or take responsibility for the content, privacy policies, or practices of these websites. Accessing these links is at your own risk. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Changes to the Terms and Conditions </h5>
                                                                    <p>We reserve the right to modify these Terms & Conditions at any time. Any changes will be effective immediately upon posting on our website. Your continued use of our services after changes have been made constitutes your acceptance of the new terms. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>Governing Law </h5>
                                                                    <p>These Terms & Conditions shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising from or related to these terms shall be subject to the exclusive jurisdiction of the courts of England and Wales. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>


                                            <section className="elementor-section elementor-top-section elementor-element elementor-element-762336b2 elementor-section-boxed elementor-section-height-default elementor-section-height-default sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="762336b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}">
                                                <div className="elementor-container elementor-column-gap-extended">
                                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-416ba2ff sc_content_align_inherit sc_layouts_column_icons_position_left sc_fly_static trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="416ba2ff" data-element_type="column">
                                                        <div className="elementor-widget-wrap elementor-element-populated">
                                                            <div className="elementor-element elementor-element-111ece5e sc_fly_static elementor-widget elementor-widget-text-editor trx_addons_parallax_layers_inited trx_addons_parallax_blocks_inited" data-id="111ece5e" data-element_type="widget" data-widget_type="text-editor.default">
                                                                <div className="elementor-widget-container">
                                                                    <h5>𝗖𝗼𝗻𝘁𝗮𝗰𝘁 𝗜𝗻𝗳𝗼𝗿𝗺𝗮𝘁𝗶𝗼𝗻  </h5>
                                                                    <p>If you have any questions or concerns regarding these Terms & Conditions, please contact us at:

                                                                        <br />
                                                                        <br />
                                                                     <strong>  Reserve Financial Services</strong> 
                                                                        <br />
                                                                        Suite V3, 4 Woodland Road, DL3 7PJ, Darlington, Co Durham
                                                                        <br />
                                                                        queries@reservefinancialservices.com
                                                                        <br />
                                                                        02045424350

                                                                        <br />
                                                                        <br />
                                                                        </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>





                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div> </div>
        </div>
    )
}

export default PrivacyPolicy