import React from 'react'
import './NotificationPopup.scss'
import { timeAgo } from '../../../GeneralFunctions'

const NotificationPopup = ({ notificationData }) => {
  return (
    <div className='admin-notification-popup'>
      <ul class="notification-dropdown">

        <li className='head'>
          {/* <i class="ri-notification-line"></i> */}
          <h4 class="mb-0">Notitications</h4>
        </li>
        <div className='items-container'>
          {notificationData && notificationData.length > 0 &&
            notificationData.map((item, index) => (
              <li key={index} onClick={() => { }}>
                <p>
                  {/* <i class="fa fa-circle me-2 font-info"></i> */}
                  <span className='message-text'>{item.message}</span>
                  
                  <span
                    class="pull-right">{item.created_at && timeAgo(item.created_at)}</span>
                </p>
              </li>
            ))}
        </div>
        <li className='button d-none'>
          <a class="btn btn-primary" href="javascript:void(0)">Check all notification</a>
        </li>
      </ul>
    </div>
  )
}

export default NotificationPopup