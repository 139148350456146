// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blogs-page .left-box .search-box .form-control {
  cursor: text;
}
.blogs-page .left-box .search-box .search-icon {
  cursor: pointer;
}
.blogs-page .left-box .shop-left-sidebar .category-list li a.active .form-check-label .name, .blogs-page .left-box .shop-left-sidebar .category-list li a.active .form-check-label .number {
  color: rgba(var(--theme-color), 1);
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/Blogs/Blogs.scss"],"names":[],"mappings":"AAGY;EACI,YAAA;AAFhB;AAIY;EACI,eAAA;AAFhB;AAQoB;EACI,kCAAA;AANxB","sourcesContent":[".blogs-page {\r\n    .left-box {\r\n        .search-box {\r\n            .form-control {\r\n                cursor: text;\r\n            }\r\n            .search-icon {\r\n                cursor: pointer;\r\n            }\r\n        }\r\n        .shop-left-sidebar {\r\n            .category-list li a.active {\r\n                .form-check-label{\r\n                    .name, .number{\r\n                        color: rgba(var(--theme-color), 1);\r\n                    }\r\n                }\r\n            }\r\n        }\r\n\r\n    }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
