import React, { useEffect, useState, useRef } from 'react'
import './DriverAdd.scss'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';


import 'react-phone-input-2/lib/bootstrap.css';
import { isValidPhoneNumber } from 'libphonenumber-js';
import PhoneInputField from '../../../authentication/pages/CustomPhone/CustomPhoneInput.js';
import MultipleImageUploader from '../../../admin-panel/common-components/MultipleImageUploader.js';
import Addresses from '../../common-components/Addresses/Addresses.js';

const DriverAdd = ({ mode }) => {



  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isDriverExist, setIsDriverExist] = useState(false);
  const [isAddressExist, setIsAddressExist] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [images, setImages] = useState([])
  const [errors, setErrors] = useState([]);
  const [documentTypes, setDocumentTypes] = useState({
    "Driving License": [],
    "Vehicle RC": [],
    "Photo ID Proof": ["Passport", "National ID"],
    "Address ID Proof": ["Utility Bill", "Bank Statement", "Lease Agreement"]
  });


  // const [setErrors, setErrors] = useState([]);
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  const [tabCompletion, setTabCompletion] = useState({
    driver_info: false,
    employment_details: false,
    address: false,
    documents: false,
  })

  const [data, setData] = useState({
    user_info: {
      dob: null,
      gender: null,
    },
    driver_info: {
      place_of_birth: null,
      nationality: null,
      ni_number: null,
      have_criminal_record: false,
      details_of_criminal_records: null,
      vehicle_type: null,
    },
    employment_details: {
      previous_job_title: null,
      previous_employer_details: null,
      is_fresher: false,
    },
    documents: [
      { name: "Driving License", document_no: null, file: null, file_url: null, type: null },
      { name: "Vehicle RC", document_no: null, file: null, file_url: null, type: null },
      { name: "Photo ID Proof", document_no: null, file: null, file_url: null, type: null },
      { name: "Address ID Proof", document_no: null, file: null, file_url: null, type: null },
    ],
  });

  const handleInputChange = (key) => (e) => {
    const { name, type, checked, value } = e.target;
    const inputValue = type === 'checkbox' ? checked : type === 'number' ? parseFloat(value) : value;

    setData((prevState) => {
      // Create the new state
      const newState = {
        ...prevState,
        [key]: {
          ...prevState[key],
          [name]: inputValue,
        },
      };

      // Check the specific condition for criminal records
      if (name === 'have_criminal_record' && inputValue === false) {
        newState.driver_info.details_of_criminal_records = '';
      }
      // Check the specific condition for fresher status
      if (name === 'is_fresher' && inputValue === true) {
        newState.employment_details.previous_employer_details = '';
        newState.employment_details.previous_job_title = '';
      }
      return newState;
    });
  };


  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      images: []
    }));
  }, [images, data]);




  const loadDriverExist = () => {
    if (mode !== "add") {
      setIsDriverExist(true)
      setDriverId(localStorage.getItem("itemSelectedId"));
    }

  }


  const loadDocuments = () => {
    if (driverId) {
      setIsLoading(true)
      API.get(`/delivery_person/documents/?driver_id=${driverId}`)
        .then(response => {
          setIsLoading(false);
          const apiDocuments = response.data.results;
          // Update documents based on API response
          const updatedDocuments = data.documents.map(doc => {
            const foundDoc = apiDocuments.find(apiDoc => apiDoc.name === doc.name);
            return foundDoc
              ? { ...doc, document_no: foundDoc.document_no || null, file: foundDoc.file || null, file_url: foundDoc.file || null, type: foundDoc.type || null }
              : doc; // Keep original if not found
          });

          setData(prevState => ({
            ...prevState,
            documents: updatedDocuments,
          }));
        })
        .catch(error => {
          setMessage(error.message);
          // setIsErrorModalOpen(true);
          setIsLoading(false)
        });
    }
  }
  const loadAddress = () => {
    setIsLoading(true);
    API.get(`/user/address/`)
      .then(response => {
        setTabCompletion(prevState => ({
          ...prevState,
          address: response.data.results && response.data.results.length > 0,
        }));
        setIsLoading(false)
      })
      .catch(error => {
        // setMessage(error.message);
        setIsLoading(false)
      });
  }

  const loadData = () => {
    if (driverId) {
      window.localStorage.setItem("itemSelectedId", driverId)
      setIsLoading(true)
      API.get(`/delivery_person/${driverId}/`)
        .then(response => {
          const result = response.data || {};
          setData({
            driver_info: result.driver_info || {},
            employment_details: result.employment_details || {},
            user_info: result.user_info || {},
            documents: data.documents,
          });
          setIsLoading(false);
          // set tab button state
          setTabCompletion(prevState => ({
            ...prevState,
            driver_info: result.driver_info?.gender !== null,
            employment_details: result.driver_info?.gender !== null,
          }));
        })
        .catch(error => {
          setMessage(error.response?.data?.message || error.message);
          setIsErrorModalOpen(true);
          setIsLoading(false)
        });
    }

  }

  // load data
  useEffect(() => {
    loadDriverExist();
    loadData();
    loadDocuments();
    loadAddress();
  }, [driverId, isMessageModalOpen]);


  const validateForm = (formdata, tabName) => {
    const errors = {};
    if (tabName == 'driver_info') {
      if (!formdata.place_of_birth || !formdata.place_of_birth.trim()) {
        errors.place_of_birth = "Required.";
      }
      if (!formdata.nationality || !formdata.nationality.trim()) {
        errors.nationality = "Required.";
      }
      if (!formdata.vehicle_type || !formdata.vehicle_type.trim()) {
        errors.vehicle_type = "Select vehicle type.";
      }
      if (!formdata.ni_number || !formdata.ni_number.trim()) {
        errors.ni_number = "Required.";
      }
      if (formdata.have_criminal_record) {
        if (!formdata.details_of_criminal_records || !formdata.details_of_criminal_records.trim()) {
          errors.details_of_criminal_records = "Required.";
        }
      }
      // user_data
      if (!data.user_info.dob || !data.user_info.dob.trim()) {
        errors.dob = "Required.";
      }
      if (!data.user_info.gender || !data.user_info.gender.trim()) {
        errors.gender = "Select gender.";
      }

    }
    if (tabName == 'employment_details') {
      if (!formdata.is_fresher) {
        if (!formdata.previous_job_title || !formdata.previous_job_title.trim()) {
          errors.previous_job_title = "Required.";
        }
        if (!formdata.previous_employer_details || !formdata.previous_employer_details.trim()) {
          errors.previous_employer_details = "Required.";
        }
      }
    }


    return errors;
  };

  const validateDocumentErrors = (doc) => {
    const docErrors = {};
    if (!doc.document_no) {
      docErrors.document_no = 'Required.';
    }
    if (!doc.file) {
      docErrors.file = 'Required.';
    }
    if (documentTypes[doc.name] && documentTypes[doc.name].length > 0 && !doc.type) {
      docErrors.type = 'Required.';
    }
    return docErrors;
  };

  // submit Driver info
  const handleSaveMaindata = (tabName) => {
    let updateFormData;
    if (tabName == 'driver_info') {
      updateFormData = {
        ...data.driver_info,
        user: window.localStorage.getItem('userID')
      };
    }

    const validationErrors = validateForm(updateFormData, tabName);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      console.log(validationErrors);
      return;
    }
    // return;
    if (isDriverExist) {
      API.put(`/delivery_person/${driverId}/`, updateFormData)
        .then(response => {
          // Handle the response
          API.put(`/user/users/${window.localStorage.getItem('userID')}/`, data.user_info)
            .then(response => {
              setIsLoading(false);
              setMessage("Details updated successfully");
              setIsMessageModalOpen(true);

            })
        })
        .catch(error => {
          // Handle the error
          console.error('Error saving restaurant:', error);
          setMessage(error.response?.data?.message || error.message)
          setIsLoading(false);
          setIsErrorModalOpen(true)
        });
    }
    else {
      API.post(`/delivery_person/`, updateFormData)
        .then(response => {
          API.put(`/user/users/${window.localStorage.getItem('userID')}/`, data.user_info)
            .then(response => {
              setIsLoading(false);
              setMessage("Details saved successfully");
              setIsMessageModalOpen(true);

            })

        })
        .catch(error => {
          // Handle the error
          console.error('Error saving restaurant:', error);
          setMessage(error.response?.data?.message || error.message)
          setIsLoading(false);
          setIsErrorModalOpen(true)
        });
    }

  }
  // submit employee_details
  const handleSaveEmployment = (tabName) => {
    let updateFormData;
    if (tabName == 'employment_details') {
      updateFormData = {
        ...data.employment_details,
      };
    }

    const validationErrors = validateForm(updateFormData, tabName);
    setErrors(validationErrors);
    console.log('errors', validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      return;
    }
    // return;
    if (isDriverExist) {
      API.put(`/delivery_person/${driverId}/`, updateFormData)
        .then(response => {
          // Handle the response
          setIsLoading(false);
          setMessage("Details updated successfully");
          setIsMessageModalOpen(true);

        })
        .catch(error => {
          // Handle the error
          console.error('Error saving restaurant:', error);
          setMessage(error.response?.data?.message || error.message)
          setIsLoading(false);
          setIsErrorModalOpen(true)
        });
    }

  }

  // documents
  const handleDocumentInputChange = (index, field, value) => {
    const updatedDocuments = data.documents.map((doc, idx) => {
      if (idx === index) {
        return { ...doc, [field]: value };
      }
      return doc;
    });
    setData(prevState => ({
      ...prevState,
      documents: updatedDocuments,
    }));
  };

  const handleDocumentFileChange = (index, file) => {
    const updatedDocuments = data.documents.map((doc, idx) => {
      if (idx === index) {
        return { ...doc, file: file };
      }
      return doc;
    });

    setData(prevState => ({
      ...prevState,
      documents: updatedDocuments,
    }));
  };

  const handleSaveDocument = (index) => {
    const documentData = data.documents[index];
    const formData = new FormData();
    formData.append('driver', driverId);
    formData.append('name', documentData.name);
    formData.append('type', documentData.type);
    formData.append('document_no', documentData.document_no || '');
    formData.append('file', documentData.file);

    const validationErrors = validateDocumentErrors(documentData);
    setErrors(prevErrors => ({
      ...prevErrors,
      [index]: validationErrors // Store errors for this document by index
    }));
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    setIsLoading(true);
    API.post('/delivery_person/documents/', formData, {
      headers: { "Content-Type": "multipart/form-data", }
    })
      .then(response => {
        setIsLoading(false);
        loadDocuments();
      })
      .catch(error => {
        // Handle the error
        setMessage(error.response?.data?.message || error.message)
        setIsLoading(false);
        setIsErrorModalOpen(true)
      });
  }

  const handlCompleteRegButton = () => {
    setMessage('Registration completed');
  }
  

  return (
    <div className='driver-add-page'>

      <section className="page-head-section d-none">
        <div className="container page-heading">
          <h2 className="h3 mb-3 text-white text-center">Complete Your Registration</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-star">
              <li className="breadcrumb-item">
                <a href="index.html"><i className="ri-home-line"></i>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">Register</li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="section-b-space sec-2">
        <div className="container">
          <div className="title animated-title">
            <div className="loader-line"></div>
            <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
              <div>
                <h2>Delivery Partner Information</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="card">
            {/* <div className="card-header">
              <h5>Restaurants</h5>
            </div> */}
            <div className="card-body">
              <div className="row">
                <div className="col-xxl-3 col-xl-4">
                  <ul className="nav setting-main-box sticky theme-scrollbar" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">

                    <li>
                      <button className="nav-link active" id="Info-tab" data-bs-toggle="pill"
                        data-bs-target="#Info" role="tab"
                        aria-controls="Info" aria-selected="false">
                        <i className="ri-information-2-line"></i>Driver Details</button>
                    </li>
                    <li>
                      <button className="nav-link" id="Employment-tab" data-bs-toggle="pill"
                        data-bs-target="#Employment" role="tab" aria-controls="Employment"
                        aria-selected="false" disabled={!tabCompletion.driver_info}>
                        <i className="ri-briefcase-4-fill"></i>Employment</button>
                    </li>
                    <li>
                      <button className="nav-link" id="Address-tab" data-bs-toggle="pill"
                        data-bs-target="#Address" role="tab" aria-controls="Address"
                        aria-selected="false" disabled={!tabCompletion.driver_info}>
                        <i className="ri-map-pin-2-fill"></i>Address</button>
                    </li>
                    <li>
                      <button className="nav-link " id="Legal-tab"
                        data-bs-toggle="pill" data-bs-target="#Legal" role="tab"
                        aria-controls="Legal" aria-selected="true" disabled={!tabCompletion.address || !tabCompletion.driver_info}>
                        <i className="ri-file-fill"></i>Documents </button>
                    </li>

                  </ul>
                </div>
                <div className="col-xxl-9 col-xl-8 col-12">
                  <div className="restaurant-tab">
                    <div className="tab-content" id="v-pills-tabContent">

                      <div className="tab-pane fade show active" id="Info" role="tabpanel"
                        aria-labelledby="Info-tab">
                        <div className="input-items">
                          <div className="row gy-3">
                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>Vehicle Type</h6>
                                <select
                                  className='form-select'
                                  name="vehicle_type"
                                  value={data.driver_info.vehicle_type}
                                  onChange={handleInputChange('driver_info')}
                                >
                                  <option value={null} selected disabled>Select Vehicle Type</option>
                                  <option value="Car">Car</option>
                                  <option value="Bike">Bike</option>
                                </select>
                                {errors.vehicle_type && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.vehicle_type}</div>}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>Date of birth</h6>
                                <input type="date" name="dob" placeholder="Enter Date of birth"
                                  value={data.user_info.dob}
                                  onChange={handleInputChange('user_info')} />
                                {errors.dob && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.dob}</div>}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>Gender</h6>
                                <select
                                  className='form-select'
                                  name="gender"
                                  value={data.user_info.gender}
                                  onChange={handleInputChange('user_info')}
                                >
                                  <option value={null} disabled selected>Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                </select>
                                {errors.gender && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.gender}</div>}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>Place of Birth</h6>
                                <input type="text" name="place_of_birth" placeholder="Enter Place of Birth"
                                  value={data.driver_info.place_of_birth}
                                  onChange={handleInputChange('driver_info')} />
                                {errors.place_of_birth && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.place_of_birth}</div>}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>Nationality</h6>
                                <input type="text" name="nationality" placeholder="Enter Nationality"
                                  value={data.driver_info.nationality}
                                  onChange={handleInputChange('driver_info')} />
                                {errors.nationality && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.nationality}</div>}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-box">
                                <h6>National Insurance Number</h6>
                                <input type="text" name="ni_number" placeholder="Enter NI number"
                                  value={data.driver_info.ni_number}
                                  onChange={handleInputChange('driver_info')} />
                                {errors.ni_number && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.ni_number}</div>}
                              </div>
                            </div>

                            <div className="col-md-12 d-flex align-items-end">
                              <div className="custom-check-item">
                                <input className="custom-checkbox" id="have_criminal_record" type="checkbox" name="have_criminal_record"
                                  checked={data.driver_info.have_criminal_record}
                                  onChange={handleInputChange('driver_info')} />
                                <h6 for="have_criminal_record">Do you have any criminal record?</h6>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="input-box">
                                <h6>Criminal Record Details</h6>
                                <textarea name="details_of_criminal_records" rows="4" placeholder="Enter Criminal Record Details"
                                  value={data.driver_info.details_of_criminal_records}
                                  onChange={handleInputChange('driver_info')}
                                  disabled={!data.driver_info.have_criminal_record} ></textarea>
                                {errors.details_of_criminal_records && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.details_of_criminal_records}</div>}

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button onClick={() => handleSaveMaindata('driver_info')} className="btn register-button mt-1">Save and Proceed</button>
                        </div>
                      </div>
                      <div className="tab-pane fade show" id="Employment" role="tabpanel"
                        aria-labelledby="Employment-tab">
                        <div className="input-items">
                          <div className="row gy-3">
                            <div className="col-md-4 d-flex align-items-end">
                              <div className="custom-check-item">
                                <input className="custom-checkbox" id="is_fresher" type="checkbox" name="is_fresher"
                                  checked={data.employment_details.is_fresher}
                                  onChange={handleInputChange('employment_details')} />
                                <h6 for="is_fresher">Fresher</h6>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-box">
                                <h6>Previous Job Title</h6>
                                <input type="text" name="previous_job_title" placeholder="Job Title"
                                  value={data.employment_details.previous_job_title}
                                  onChange={handleInputChange('employment_details')}
                                  disabled={data.employment_details.is_fresher} />
                                {errors.previous_job_title && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.previous_job_title}</div>}
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="input-box">
                                <h6>Employer Details</h6>
                                <textarea name="previous_employer_details" rows="4" placeholder="Enter Details"
                                  value={data.employment_details.previous_employer_details}
                                  onChange={handleInputChange('employment_details')}
                                  disabled={data.employment_details.is_fresher} ></textarea>
                                {errors.previous_employer_details && <div className="invalid-feedback m-0 mb-1  position-relative">{errors.previous_employer_details}</div>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button onClick={() => handleSaveEmployment('employment_details')} className="btn register-button mt-1">Save and Proceed</button>
                        </div>
                      </div>
                      
                      <div className="tab-pane fade show" id="Address" role="tabpanel"
                        aria-labelledby="Address-tab">
                        <div className="input-box">
                          <Addresses />
                        </div>



                      </div>
                      <div className="tab-pane fade" id="Legal" role="tabpanel"
                        aria-labelledby="Legal-tab">
                        <div className="input-items">
                          <div className="row gy-3">
                            <div className="col-12">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">Document Name</th>
                                    <th scope="col">Document Number</th>
                                    <th scope="col">Document Type</th>
                                    <th scope="col">File</th>
                                    <th scope="col"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.documents && data.documents.map((doc, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input type="text" name="doc_name" placeholder={doc.name} disabled />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name="document_no"
                                          placeholder=""
                                          value={doc.document_no}
                                          disabled={doc.file_url ? true : false}
                                          onChange={(e) => handleDocumentInputChange(index, 'document_no', e.target.value)}
                                        />
                                        {errors[index] && errors[index].document_no && (
                                          <div className="invalid-feedback m-0 mb-1  position-relative">{errors[index].document_no}</div>
                                        )}
                                      </td>
                                      <td>
                                        {documentTypes[doc.name].length > 0 && (
                                          <select
                                            className="form-select"
                                            name="type"
                                            value={doc.type}
                                            disabled={doc.file_url ? true : false}
                                            onChange={(e) => handleDocumentInputChange(index, 'type', e.target.value)}
                                          >
                                            <option value={null} disabled selected>Select</option>
                                            {documentTypes[doc.name].map((type, i) => (
                                              <option key={i} value={type}>{type}</option>
                                            ))}
                                          </select>

                                        )}
                                        {errors[index] && errors[index].type && (
                                          <div className="invalid-feedback m-0 mb-1  position-relative">{errors[index].type}</div>
                                        )}
                                      </td>
                                      <td>
                                        {doc.file_url ? (
                                          <input
                                            type="text"
                                            name="file"
                                            value={doc.file}
                                            disabled={true}
                                          />
                                        ) : (
                                          <input
                                            type="file"
                                            name="file"
                                            value={doc.filename}
                                            onChange={(e) => handleDocumentFileChange(index, e.target.files[0])}
                                          />
                                        )}
                                        {errors[index] && errors[index].file && (
                                          <div className="invalid-feedback m-0 mb-1  position-relative">{errors[index].file}</div>
                                        )}
                                      </td>
                                      <td>
                                        <button disabled={doc.file_url ? true : false} className='btn register-button' onClick={() => handleSaveDocument(index)} >Save</button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button className="btn register-button mt-1" onClick={handlCompleteRegButton}>Complete Registration</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => setIsErrorModalOpen(false)} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => setIsMessageModalOpen(false)} />}

    </div>

  );


}

export default DriverAdd