// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-dropzone-container .drag-and-drop {
  background: #F7F7F9;
  height: 220px;
  border: 4px dotted #E7E7E7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #648197;
  flex-direction: column;
  gap: 5px;
  overflow: hidden;
}
.file-dropzone-container .drag-and-drop .drag-text {
  font-size: calc(15px + 2 * (100vw - 320px) / 1600);
  font-weight: 300;
  line-height: 100%;
  letter-spacing: -0.03em;
  text-align: center;
}
.file-dropzone-container .drag-and-drop svg {
  width: 40px;
  height: 40px;
}
.file-dropzone-container .drag-and-drop img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.file-dropzone-container .remove-btn {
  font-weight: 500;
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
  color: rgb(var(--theme-color));
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/ImgFileDropZone/ImgFileDropZone.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;EACA,aAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,QAAA;EACA,gBAAA;AADR;AAGQ;EACI,kDAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AADZ;AAIQ;EACI,WAAA;EACA,YAAA;AAFZ;AAKQ;EACI,mBAAA;EACA,WAAA;EACA,YAAA;AAHZ;AAOI;EACI,gBAAA;EACA,kDAAA;EACA,8BAAA;AALR","sourcesContent":[".file-dropzone-container {\r\n\r\n    .drag-and-drop {\r\n        background: #F7F7F9;\r\n        height: 220px;\r\n        border: 4px dotted #E7E7E7;\r\n        border-radius: 5px;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        color: #648197;\r\n        flex-direction: column;\r\n        gap: 5px;\r\n        overflow: hidden;\r\n\r\n        .drag-text {\r\n            font-size: calc(15px + 2 * (100vw - 320px) / 1600);\r\n            font-weight: 300;\r\n            line-height: 100%;\r\n            letter-spacing: -0.03em;\r\n            text-align: center;\r\n        }\r\n\r\n        svg {\r\n            width: 40px;\r\n            height: 40px;\r\n        }\r\n\r\n        img {\r\n            object-fit: contain;\r\n            width: 100%;\r\n            height: 100%;\r\n        }\r\n\r\n    }\r\n    .remove-btn{\r\n        font-weight: 500;\r\n        font-size: calc(12px + 2 * (100vw - 320px) / 1600);\r\n        color: rgb(var(--theme-color));\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
