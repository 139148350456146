import React from 'react'
import { useNavigate } from 'react-router-dom';
import { convertRichTextJsonToText, formatDateTimeToMonthYear } from '../../../GeneralFunctions'
import './BlogList.scss'
import EmptyTableMessage from '../../../Empty';

const BlogList = ({ data }) => {
  const navigate = useNavigate();
  return (
    <div className='blog-list-section'>
      <div className="row g-4 ratio2_3">
        {data && data.results && data.results.length > 0 ? data.results.map((item, index) => (
          <div className="col-12" key={index}>
            <div className="blog-box list-box"
              onClick={() => { navigate('/blog-details'); localStorage.setItem('itemSelectedId', item.id) }}>
              <div className="blog-image">
                <div className="">
                  <img className="bg-img img" src={item.image} alt="blog1"></img>
                </div>
              </div>
              <div className="blog-details border-0">
                <h6 className="mt-0">By {item.author} in {item.category_name}</h6>
                <a >
                  <h5>{item.title}</h5>
                </a>
                <p>
                  {convertRichTextJsonToText(item.description)}
                </p>
                <h6>on {formatDateTimeToMonthYear(item.published_on)} By {item.author}</h6>
              </div>
            </div>
          </div>
        )) :

          (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
              <div className="text-center">
                <img style={{ height: "180px", marginBottom: "10px" }} src="/images/icons/no-blog.png"></img>
                <p style={{ fontSize: "11px", color: "grey", textDecoration: "italic", width: "300px" }}>
                Sorry, we couldn't find any matching blogs. Please refine your search and try again.
                </p>
              </div>
            </div>
          )}


      </div>
    </div>
  )
}

export default BlogList